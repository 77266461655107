import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { uom } from '../queries';

export const useUom = () => {
  const { entityRef } = useParams();

  const List = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['items', entityRef, queryString.stringify(queryParams)],
      queryFn: () => uom.listUom({ entityRef, queryParams }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  return { List };
};
