import React from 'react';
import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { bool, shape, array, func } from 'prop-types';

import { TextInputWithFormikField, SelectWithFormikField, AutocompleteWithFormikField } from '../../../../commons';
import { ProductProfileContainer } from './styles';

const ProductProfileTab = ({
  isLoadingProductTypes,
  productTypesOptions,
  productType,
  isLoadingMixes,
  mixesOptions,
  isLoadingUom,
  uomOptions,
  productStatusOptions,
  isLoadingLocations,
  locationOptions,
  setFieldValue,
  onLocationsChange,
}) => {
  const handleLocationsChange = setFieldValue => (name, value) => {
    setFieldValue(name, value);
    if (onLocationsChange) {
      onLocationsChange(setFieldValue, value);
    }
  };

  return (
    <ProductProfileContainer>
      <h3 className="product-porfile-title">
        <Localization.Translate stringId="products_addProduct_productProfile" />
      </h3>
      <Form className="product-porfile-form">
        <div className="first-column">
          <Field
            testId="select-product-types"
            labelStringId="products_addProduct_productTypes"
            component={SelectWithFormikField}
            name="productType"
            disabled={isLoadingProductTypes}
            options={productTypesOptions}
          />
          {productType && productType.label === 'CONCRETE' && (
            <Field
              testId="select-mixes"
              labelStringId="products_addProduct_mixDesign"
              component={SelectWithFormikField}
              name="mix"
              disabled={isLoadingMixes || !mixesOptions.length}
              options={mixesOptions}
            />
          )}
          <Field
            labelStringId="products_addProduct_productId"
            component={TextInputWithFormikField}
            variant="outlined"
            name="productId"
          />
          <Field
            labelStringId="products_addProduct_productName"
            component={TextInputWithFormikField}
            variant="outlined"
            name="productName"
          />
          <Field
            testId="select-uom"
            labelStringId="products_addProduct_uom"
            component={SelectWithFormikField}
            name="uom"
            disabled={isLoadingUom}
            options={uomOptions}
          />
        </div>
        <div className="second-column">
          <Field
            testId="select-product-status"
            labelStringId="products_addProduct_productStatus"
            component={SelectWithFormikField}
            name="productStatus"
            options={productStatusOptions}
            infoTooltipStringId="products_addProduct_productStatus_tooltip"
          />
          <Field
            testId="autocomplete-locations"
            labelStringId="products_addProduct_locations"
            component={AutocompleteWithFormikField}
            name="locations"
            disabled={isLoadingLocations}
            options={locationOptions}
            customOnChange={handleLocationsChange(setFieldValue)}
          />
        </div>
      </Form>
    </ProductProfileContainer>
  );
};

ProductProfileTab.propTypes = {
  isLoadingProductTypes: bool.isRequired,
  productTypesOptions: array.isRequired,
  productType: shape({}),
  isLoadingMixes: bool.isRequired,
  mixesOptions: array.isRequired,
  isLoadingUom: bool.isRequired,
  uomOptions: array.isRequired,
  productStatusOptions: array.isRequired,
  isLoadingLocations: bool.isRequired,
  locationOptions: array.isRequired,
  setFieldValue: func.isRequired,
  onLocationsChange: func,
};

ProductProfileTab.defaultProps = {
  productType: {},
  onLocationsChange: null,
};

export default ProductProfileTab;
