import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, shape, string } from 'prop-types';

import MixComponentFormView from '../mix-component-form-view';
import { useComponents, useLocations, useProducts, useUom } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { parseUomOptions, parseProductOptions } from '../../../../api/adapters';

const EditMixComponentDialog = ({ onClose, mixData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Update: useEditComponent } = useComponents();
  const { mutateAsync: updateComponent } = useEditComponent();
  const translateMessage = Localization.useTranslateMessage();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const { List: useListProducts } = useProducts();
  const { data: productOptions, isLoading: isLoadingProduct } = useListProducts({ adapter: parseProductOptions });
  const { List: useListLocations } = useLocations();
  const { data: locations } = useListLocations();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      mixComponentInventoryUOM,
      mixComponentName,
      mixComponentStatus,
      mixComponentBatchUOM,
      mixComponentProduct,
      mixComponentCosts,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const costs = mixComponentCosts?.map(({ locationName, batchCode, ...cost }) => ({
        ...cost,
        batchCode: batchCode || null,
      }));
      const mixComponent = {
        name: mixComponentName,
        batchUomCode: mixComponentBatchUOM.value,
        uomCode: mixComponentInventoryUOM.value,
        productRef: mixComponentProduct?.value || '',
        costs,
        status: mixComponentStatus.value,
      };

      await updateComponent({ data: mixComponent, componentRef: mixData.crn });
      notification.success(translateMessage('notification_changesSaved'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!mixData) return null;

    const { id, name, costs } = mixData;

    const mixComponentCosts = costs?.map(cost => {
      const locationName = locations?.find(x => x.crn === cost.locationRef)?.name;
      return {
        ...cost,
        locationName,
        batchCode: cost.batchCode ?? '',
      };
    });

    const values = {
      mixComponentId: id,
      mixComponentName: name,
      mixComponentBatchUOM: uomOptions.find(uomItem => uomItem.value === mixData?.batchUomCode) || '',
      mixComponentInventoryUOM: uomOptions.find(uomItem => uomItem.value === mixData?.uomCode) || '',
      mixComponentStatus: STATUS_OPTIONS.find(status => status.value === mixData.status),
      mixComponentProduct: productOptions.find(productItem => productItem.value === mixData.productRef) || '',
      mixComponentCosts,
    };

    return values;
  }, [mixData, uomOptions, productOptions, locations]);

  return (
    <MixComponentFormView
      handleOnDelete={handleOnDelete}
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      mixComponentStatusOptions={STATUS_OPTIONS}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      productOptions={productOptions}
      isLoadingProduct={isLoadingProduct}
    />
  );
};

EditMixComponentDialog.propTypes = {
  onClose: func.isRequired,
  handleOnDelete: func,
  mixData: shape({
    crn: string.isRequired,
    id: string.isRequired,
    name: string.isRequired,
    batchUomCode: string,
    uomCode: string,
    status: string.isRequired,
  }),
};

EditMixComponentDialog.defaultProps = {
  onClose: () => {},
  mixData: {},
};

export default EditMixComponentDialog;
