import React, { useState, useMemo, useCallback } from 'react';
import { Localization } from 'connex-cds';

import { DIALOGS, getProductListColumns } from './tableConfig';
import AddProductDialog from './components/add-product-dialog';
import DeleteProductDialog from './components/delete-product-dialog';
import { EntityTable, TableLayout, ActionButton, SearchFilter } from '../../commons';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { useProducts } from '../../api/hooks';
import EditProductDialog from './components/edit-product-dialog';
import { DialogManager } from '../../commons';

const Products = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [dialog, setDialog] = useState(DIALOGS.NONE);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentProduct, setCurrentProduct] = useState(null);
  const { List: useProductsList } = useProducts();
  const { data, isLoading } = useProductsList();

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data, query: searchTerm, properties: ['name', 'id', 'type'] }),
    [data, searchTerm]
  );

  const handleSetProductRow = useCallback(
    productData => () => {
      setDialog(DIALOGS.DELETE);
      setCurrentProduct(productData);
    },
    []
  );

  const productListColumns = useMemo(
    () => getProductListColumns({ handleSetProductRow, translateMessage }),
    [handleSetProductRow, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOGS.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setCurrentProduct(null);
    setDialog(DIALOGS.NONE);
  };

  const handleOnRowClick = params => {
    setCurrentProduct(params.row);
    setDialog(DIALOGS.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setSearchTerm} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="products_addProduct_title_button" />}
          variant="outlined"
          onClick={() => setDialog(DIALOGS.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          getRowId={row => row.crn}
          rows={filteredData}
          columns={productListColumns}
          onRowClick={handleOnRowClick}
          isLoading={isLoading}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddProductDialog identifier={DIALOGS.ADD} onClose={resetDialogs} />
        <EditProductDialog
          identifier={DIALOGS.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOGS.DELETE);
          }}
          productData={currentProduct}
          onClose={resetDialogs}
        />
        ;
        <DeleteProductDialog identifier={DIALOGS.DELETE} productData={currentProduct} onClose={resetDialogs} />;
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default Products;
