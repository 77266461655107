export const getMappedInventoryLocations = (locations, productLocations, partnerOptions) =>
  locations
    ?.filter(x => !!x.trackInventory)
    ?.map(location => {
      const locationInventoryData = productLocations.find(x => x.locationRef === location.value);

      let productMappingList;
      if (locationInventoryData?.productMapping?.length > 0) {
        productMappingList = locationInventoryData.productMapping.map(p => ({
          partner: partnerOptions?.find(partner => partner.value === p.supplierRef),
          projects: p.projects?.map(project => ({
            value: project.projectRef,
            label: project.projectName,
            id: project.projectId,
          })),
          products: p.products?.map(product => ({
            value: product.productRef,
            label: product.productName,
            id: product.productId,
          })),
          purchaseOrders: p.purchaseOrders?.map(po => ({ value: po, label: po })) ?? [],
        }));
      }

      return {
        ...location,
        safetyStock: locationInventoryData?.safetyStock?.toString() ?? '',
        reorderStock: locationInventoryData?.reorderStock?.toString() ?? '',
        maxStock: locationInventoryData?.maxStock?.toString() ?? '',
        productMappingList,
      };
    }) ?? [];

export const getEmptyProductMappingEntry = () => ({
  partner: '',
  projects: [],
  products: [],
  purchaseOrders: [],
});

const getStockValue = (inventoryLocation, stockPropKey) =>
  inventoryLocation?.[stockPropKey] || inventoryLocation?.[stockPropKey] === 0
    ? +inventoryLocation[stockPropKey]
    : undefined;

export const getMappedProductLocations = (inventoryLocations, productLocations = undefined, status = undefined) => {
  if (!inventoryLocations?.length) {
    return [];
  }

  return inventoryLocations.map(inventoryLocation => {
    const locationInventoryData = productLocations?.find(x => x.locationRef === inventoryLocation.value);

    let productMapping = undefined;
    if (inventoryLocation?.productMappingList?.length > 0) {
      productMapping = inventoryLocation.productMappingList.map(p => ({
        supplierRef: p.partner.value,
        supplierName: p.partner.label,
        projects: p.projects?.length
          ? p.projects.map(p => ({ projectRef: p.value, projectId: p.id, projectName: p.label }))
          : undefined,
        products: p.products?.length
          ? p.products.map(p => ({ productRef: p.value, productId: p.id, productName: p.label }))
          : undefined,
        purchaseOrders: p.purchaseOrders?.length ? p.purchaseOrders.map(p => p.value) : undefined,
      }));
    } else if (locationInventoryData?.productMapping?.length > 0) {
      productMapping = [];
    }

    const productLocation = {
      ...locationInventoryData,
      locationRef: inventoryLocation.value,
      safetyStock: getStockValue(inventoryLocation, 'safetyStock'),
      reorderStock: getStockValue(inventoryLocation, 'reorderStock'),
      maxStock: getStockValue(inventoryLocation, 'maxStock'),
      productMapping,
      status,
    };
    return productLocation;
  });
};
