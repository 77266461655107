import { http } from 'connex-cds';

export default {
  listPriceBooks: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/price-books`, queryParams }),
  createPriceBook: ({ entityRef, priceBook }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/price-books`,
      data: priceBook,
    }),
  updatePriceBook: ({ entityRef, priceBookRef, priceBook }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/price-books/${priceBookRef}`, data: priceBook }),
};
