import { http } from 'connex-cds';

import { isDevEnvironment, isLocalEnvironment } from '../../util/environment';

export default {
  listProductLocations: ({ entityRef, productRef }) => {
    if (!entityRef || !productRef || !(isDevEnvironment || isLocalEnvironment)) {
      return null;
    }
    return http.get({ apiName: 'company-admin', path: `/${entityRef}/products/${productRef}/product-locations` });
  },
};
