import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { useContactReasons } from '../../../../api/hooks';
import ContactReasonFormView from '../contact-reason-form-view';
import { initialValues } from '../contact-reason-form-view/form-config';

const AddContactReasonDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCreateContactReason } = useContactReasons();
  const { mutateAsync: createContactReason } = useCreateContactReason();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { contactReasonId, contactReasonName, contactReasonStatus } = values;

    formikBag.setSubmitting(true);

    try {
      const contactReason = {
        id: contactReasonId,
        name: contactReasonName,
        status: contactReasonStatus.value,
      };

      await createContactReason({ data: contactReason });
      notification.success(translateMessage('contactReasons_notification_contactReasonAdded'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContactReasonFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
    />
  );
};

AddContactReasonDialog.propTypes = {
  onClose: func.isRequired,
};

AddContactReasonDialog.defaultProps = {
  onClose: () => {},
};

export default AddContactReasonDialog;
