import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { productLocations } from '../queries';

export const useProductLocations = () => {
  const { entityRef } = useParams();

  const List = productRef => {
    const query = useQuery({
      queryKey: ['product-locations', entityRef, productRef],
      queryFn: () => productLocations.listProductLocations({ entityRef, productRef }),
      staleTime: Infinity,
    });

    return query;
  };

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return { List };
};
