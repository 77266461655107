import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const styles = ({ theme }) => css`
  width: 100%;

  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 20px;
  }

  .product-type-form {
    .form-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 40px;
      column-gap: 50px;
      margin-bottom: 40px;
    }

    .category-field-description {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
`;

export const Styled = styled.div`
  ${styles}
`;
