import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const PriceBookProfileContainer = styled.div`
  ${css`
    .price-book-profile-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 20px;
    }

    .price-book-profile-form {
      display: flex;
      flex-direction: row;

      .MuiTextField-root {
        flex: 1;
        width: 100%;
      }

      .MuiFormControl-root {
        margin-bottom: 40px;
      }

      .MuiFormControl-root:last-child {
        margin-bottom: 0;
      }

      .MuiBackdrop-root {
        background-color: transparent !important;
      }

      .MuiModal-backdrop {
        background-color: transparent !important;
      }

      .MuiInputLabel-shrink {
        top: 0;
      }

      .first-column {
        width: 50%;
        padding-right: 20px;
      }

      .second-column {
        width: 50%;
        padding-left: 20px;
      }
    }
  `}
`;
