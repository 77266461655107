import cn from 'classnames';
import { API, Drawer, Form, Layout, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useListAndDetailContext } from './ListAndDetailProvider';
import { useClickHandlers } from './useClickHandlers';

const Styled = styled(Layout.Column)`
  .header {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    .left {
      display: flex;
      //gap: 15px;
      align-items: center;
      flex: 1;

      h3 {
        margin-bottom: 0;
      }
    }
    .right {
      display: flex;
      //gap: 15px;
    }
  }
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
`;

export const List = () => {
  const { config, pickerMode, data, showHeader = true } = useListAndDetailContext();

  const { handleRowClick, handleAddClick } = useClickHandlers();

  const { Components } = Form.useFormContext();

  return (
    <Styled className={cn('service-list')}>
      <div className="header">
        <div className="left">
          {showHeader ? (
            <Typography.H3>
              <Localization.Translate
                stringId={`${config.name || config.path}ListHeader`}
                data-testid={`${config.name || config.path}-heading`}
              />
            </Typography.H3>
          ) : null}
        </div>
        <div className="right">
          {!pickerMode && (
            <Drawer.DrawerProvider>
              <Components.AddButton
                onClick={handleAddClick}
                size="small"
                stringId={`add-${config.name || config.path}`}
              />
            </Drawer.DrawerProvider>
          )}
        </div>
      </div>
      <Layout.Column className="list-section">
        <Components.ListComponent
          onClick={handleRowClick}
          gridName={`${API.getSource()}-${config.name || config.path}-grid`}
          queryData={data}
        />
      </Layout.Column>
    </Styled>
  );
};
