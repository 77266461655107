import { Localization } from 'connex-cds';
import { Field, FieldArray, Form } from 'formik';
import { array } from 'prop-types';

import { TextInputWithFormikField } from '../../../../commons';
import { ReactComponent as NoDataImage } from '../../../../assets/no-data-image.svg';
import {
  MixComponentBatchCodeContainer,
  LocationsContainer,
  LocationRow,
  LocationLabel,
  NoDataContainer,
  NoDataHeader,
} from './styles';

const MixComponentBatchCodeTab = ({ mixComponentCosts }) => {
  return (
    <MixComponentBatchCodeContainer>
      <h3 className="mix-component-batch-code-title">
        <Localization.Translate stringId="mixComponents_batchCodeTab_title" />
      </h3>
      <Form>
        <FieldArray
          name="mixComponentCosts"
          render={() => (
            <>
              <LocationsContainer>
                {mixComponentCosts && mixComponentCosts.length > 0 ? (
                  mixComponentCosts.map((mixComponentCost, index) => (
                    <LocationRow key={mixComponentCost.id}>
                      <LocationLabel>{mixComponentCost.locationName}</LocationLabel>
                      <Field
                        labelStringId="mixComponents_batchCodeTab_batchCode"
                        component={TextInputWithFormikField}
                        variant="outlined"
                        name={`mixComponentCosts.${index}.batchCode`}
                      />
                    </LocationRow>
                  ))
                ) : (
                  <NoDataContainer>
                    <NoDataImage />
                    <NoDataHeader>
                      <Localization.Translate stringId="mixComponents_batchCodeTab_noData" />
                    </NoDataHeader>
                  </NoDataContainer>
                )}
              </LocationsContainer>
            </>
          )}
        />
      </Form>
    </MixComponentBatchCodeContainer>
  );
};

MixComponentBatchCodeTab.propTypes = {
  mixComponentCosts: array,
};

export default MixComponentBatchCodeTab;
