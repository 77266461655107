import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Dialog from '@mui/material/Dialog';

export const DialogStyled = styled(Dialog)`
  ${({ theme }) => css`
    font-family: 'Work Sans';

    .MuiDialog-paper {
      height: 860px;
      max-height: 80%;
      max-width: 960px;
    }
  `}
`;
