import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const TaxFormStyled = styled(Stack)(
  ({ theme }) => css`
    background: ${theme.validateMode({
      light: theme.colorsCA.neutral[100],
      dark: theme.backgroundCA(5),
    })};
    border-radius: 20px;
    border: 1px solid
      ${theme.validateMode({
        light: '#CAC4D0',
        dark: '#938F99',
      })};
    box-shadow: ${theme.validateMode({
      light: '0px 4px 20px 0px rgba(101, 119, 136, 0.20)',
      dark: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
    })};
    margin: 26px;

    .tax-vat-input {
      width: 320px;
    }

    .tax-vat-container {
      height: 48px;
      margin-bottom: 28px;
      padding: 0 20px;
    }

    .tax-settings {
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;
    }

    .MuiFormControlLabel-root {
      gap: 10px;
      margin: 0;
    }

    .buttons-container {
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;
    }

    .MuiFormHelperText-root {
      margin: 0;
      text-wrap: nowrap;
    }

    .back-button {
      color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[50], dark: theme.colorsCA.blue[80] })};
      gap: 8px;
      width: 90px;

      .icon {
        color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[50], dark: theme.colorsCA.blue[80] })};
        font-size: 18px;
      }

      .MuiTypography-root {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  `
);
