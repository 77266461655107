import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import ConcreteClassesComponentFormView from '../concrete-class-form-view';
import { useConcreteClasses, useUom } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import {
  initialValues,
  CONCRETE_CLASS_TYPE_OPTIONS,
  CONCRETE_CLASS_SUBTYPE_OPTIONS,
} from '../concrete-class-form-view/form-config';
import { parseUomOptions } from '../../../../api/adapters';

const AddConcreteClassesComponentDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCreateConcreteClasses } = useConcreteClasses();
  const { mutateAsync: createConcreteClass } = useCreateConcreteClasses();
  const translateMessage = Localization.useTranslateMessage();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const getConcreteClass = values => {
    const {
      concreteClassId,
      concreteClassName,
      concreteClassStatus,
      concreteClassType,
      concreteClassSubtype,
      concreteClassesDefaultSettings,
      concreteClassNominalValue,
      concreteClassMinimumValue,
      concreteClassUOM,
      concreteClassMaximumValue,
    } = values;

    let concreteClass = {
      id: concreteClassId,
      name: concreteClassName,
      status: concreteClassStatus.value,
      classType: concreteClassType.value,
      isDefault: concreteClassesDefaultSettings ? true : false,
    };

    switch (concreteClassType.value) {
      case 'air':
        concreteClass['nominalValue'] = concreteClassNominalValue;
        concreteClass['minimumValue'] = concreteClassMinimumValue;
        concreteClass['maximumValue'] = concreteClassMaximumValue;
        break;
      case 'consistence':
        concreteClass['classSubType'] = concreteClassSubtype?.value;
        concreteClass['uomCode'] = concreteClassUOM?.value;
        concreteClass['nominalValue'] = concreteClassNominalValue;
        concreteClass['minimumValue'] = concreteClassMinimumValue;
        concreteClass['maximumValue'] = concreteClassMaximumValue;
        break;
      case 'slumpLossRate':
        concreteClass['uomCode'] = concreteClassUOM?.value;
        concreteClass['nominalValue'] = concreteClassNominalValue;
        break;
      case 'temperature':
        concreteClass['uomCode'] = concreteClassUOM?.value;
        concreteClass['minimumValue'] = concreteClassMinimumValue;
        concreteClass['maximumValue'] = concreteClassMaximumValue;
        break;
      default:
        break;
    }
    return concreteClass;
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);

    try {
      const concreteClass = getConcreteClass(values);
      await createConcreteClass({ concreteClass });
      notification.success(translateMessage('concreteClasses_notification_concreteClassAdded'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ConcreteClassesComponentFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      concreteClassesStatusOptions={STATUS_OPTIONS}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      concreteClassesTypeOptions={CONCRETE_CLASS_TYPE_OPTIONS}
      concreteClassesSubtypeOptions={CONCRETE_CLASS_SUBTYPE_OPTIONS}
    />
  );
};

AddConcreteClassesComponentDialog.propTypes = {
  onClose: func.isRequired,
};

AddConcreteClassesComponentDialog.defaultProps = {
  onClose: () => {},
};

export default AddConcreteClassesComponentDialog;
