import React from 'react';
import { FormTooltip } from '../../../../commons';
import { Localization } from 'connex-cds';

import { LocationsAvailableCellStyled } from './styles';

function ProductsLocationsCell({ product }) {
  const tooltipTargetStringId = product.locationsAvailableIsAll
    ? 'all'
    : product.locationsAvailableLength > 1
    ? 'locations'
    : '';

  return (
    <LocationsAvailableCellStyled>
      {product.locationsAvailableIsAll || product.locationsAvailableLength > 1 ? (
        <FormTooltip
          arrow
          title={product.locationsAvailable}
          placement="top-start"
          PopperProps={{ disablePortal: false }}
        >
          <span className="highlighted-text">
            {!product.locationsAvailableIsAll ? `${product.locationsAvailableLength} ` : ''}
            <Localization.Translate stringId={tooltipTargetStringId} />
          </span>
        </FormTooltip>
      ) : (
        <span title={product.locationsAvailable}>{product.locationsAvailable}</span>
      )}
    </LocationsAvailableCellStyled>
  );
}

export default ProductsLocationsCell;
