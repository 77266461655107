import { useMemo, useState, useCallback } from 'react';
import { Localization } from 'connex-cds';

import { useVehicles } from '../../api/hooks';
import { TableLayout, EntityTable, SearchFilter, ActionButton, DialogManager } from '../../commons';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { DIALOG_TYPES } from '../../constants';
import AddVehicleDialog from './components/add-vehicle-dialog';
import EditVehicleDialog from './components/edit-vehicle-dialog';
import DeleteVehicleDialog from './components/delete-vehicle-dialog';
import { getVehiclesListColumns } from './tableConfig';

const Vehicles = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [query, setQuery] = useState('');
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const { List: useVehiclesList } = useVehicles();
  const { data: vehicles, isLoading } = useVehiclesList({ queryParams: { activeOnly: false } });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data: vehicles, query, properties: ['id', 'name'] }),
    [vehicles, query]
  );

  const setDelete = useCallback(
    vehicle => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentVehicle(vehicle);
    },
    []
  );

  const vehiclesColumns = useMemo(
    () => getVehiclesListColumns({ setDeleteVehicle: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setCurrentVehicle(null);
    setDialog(DIALOG_TYPES.NONE);
  };

  const handleOnRowClick = params => {
    setCurrentVehicle(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="vehicles_addVehicle_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={vehiclesColumns}
          rows={filteredData}
          getRowId={row => row.crn}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddVehicleDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditVehicleDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          onClose={resetDialogs}
          vehicleData={currentVehicle}
        />
        ;
        <DeleteVehicleDialog identifier={DIALOG_TYPES.DELETE} onClose={resetDialogs} vehicleData={currentVehicle} />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default Vehicles;
