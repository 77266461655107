import React, { forwardRef } from 'react';
import { Localization } from 'connex-cds';
import { any, func, string, bool, shape } from 'prop-types';
import { omit } from 'lodash';
import Checkbox from '@mui/material/Checkbox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import FormTooltip from '../form-tooltip/FormTooltip';
import { withFormikField } from '../../hoc';
import { FormControlLabelStyled } from './styles';

const TooltipIcon = forwardRef((props, ref) => <InfoOutlinedIcon {...props} ref={ref} className="info-icon" />);

const CheckboxBase = ({
  name,
  value,
  disabled,
  testId,
  onChange,
  isSubmitting,
  labelStringId,
  errors,
  touched,
  onBlur,
  infoTooltipStringId,
  ...otherProps
}) => {
  const translateMessage = Localization.useTranslateMessage();

  const handleChange = e => {
    onChange(name, e.target.checked);
  };

  return (
    <FormControlLabelStyled
      control={
        <Checkbox
          name={name}
          checked={value}
          onChange={handleChange}
          onBlur={onBlur}
          data-testid={testId}
          disabled={disabled || isSubmitting}
          {...omit(otherProps, ['setFieldTouched', 'setFieldValue', 'fullWidth'])}
        />
      }
      label={
        <>
          {translateMessage(labelStringId)}
          {infoTooltipStringId && (
            <FormTooltip
              title={translateMessage(infoTooltipStringId)}
              PopperProps={{ disablePortal: true }}
              arrow
              placement="right"
            >
              <TooltipIcon />
            </FormTooltip>
          )}
        </>
      }
    />
  );
};

CheckboxBase.propTypes = {
  name: string.isRequired,
  value: any.isRequired,
  disabled: bool,
  testId: string,
  onChange: func.isRequired,
  isSubmitting: bool,
  labelStringId: string.isRequired,
  errors: shape({}).isRequired,
  touched: shape({}).isRequired,
  onBlur: func,
  infoTooltipStringId: string,
};

CheckboxBase.defaultProps = {
  disabled: false,
  infoTooltipStringId: '',
  isSubmitting: false,
};

export default withFormikField(CheckboxBase);
