import { API, ConnexDesignSystem, getCachedItem } from 'connex-cds';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { InitializeApi } from './api/InitializeApi';
import { AppRoutes } from './AppRoutes';
import messages from './i18n';
import menuConfig from './menu-config';
import { TanStackProvider, ThemeProvider } from './providers';

API.setSource('company-admin');
API.setApiKey(getCachedItem('apiKey'), '');

const App = () => {
  return (
    <InitializeApi>
      <BrowserRouter>
        <TanStackProvider>
          <ConnexDesignSystem
            messages={messages}
            initialTimeZone="America/Chicago"
            appId="company-admin"
            menuConfig={menuConfig}
            contentOnly={true}
          >
            <ThemeProvider>
              <AppRoutes />
            </ThemeProvider>
          </ConnexDesignSystem>
        </TanStackProvider>
      </BrowserRouter>
    </InitializeApi>
  );
};

export default App;
