import React, { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import PropTypes, { string } from 'prop-types';

import { useMixes } from '../../../../api/hooks';
import { ConfirmationDialog } from '../../../../commons';
import { DELETED_STATUS } from '../../../../constants';
import { trimWithDots } from '../../../../util/strings';

const DeleteMixDialog = ({ mixData, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Update: useEditMix } = useMixes();
  const { mutateAsync: updateMix } = useEditMix();
  const translateMessage = Localization.useTranslateMessage();

  const onConfirmDialog = async () => {
    try {
      if (mixData?.crn) {
        const data = {
          status: DELETED_STATUS,
        };

        await updateMix({ data, mixRef: mixData?.crn });

        notification.success(translateMessage('mixDesigns_deleteMixDesignNotification_success_msg'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onClose={handleOnClose}
      onConfirm={onConfirmDialog}
      confirmText="delete"
      color="delete"
    >
      <h3 className="delete-title">
        <Localization.Translate stringId="mixDesigns_deleteMixDesignTitle" />
      </h3>
      <p className="delete-description">
        <Localization.Translate
          stringId="mixDesigns_deleteMixDesign_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: mixData?.name || '',
          }}
        />
      </p>
    </ConfirmationDialog>
  );
};

DeleteMixDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  mixData: PropTypes.shape({
    crn: string.isRequired,
    id: string.isRequired,
    name: string.isRequired,
  }),
};

export default DeleteMixDialog;
