import React from 'react';
import { IconCellStyled } from './styles';
import PropTypes from 'prop-types';
import Icon from '../../../custom-icon';

const IconCell = ({ icon, value }) => {
  return (
    <IconCellStyled>
      <Icon iconName={icon} />
      <span className="value" title={value}>
        {value}
      </span>
    </IconCellStyled>
  );
};

IconCell.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default IconCell;
