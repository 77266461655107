import { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { Localization, useNotification } from 'connex-cds';

import { ConfirmationDialog } from '../../../../commons';
import { useContactTypes } from '../../../../api/hooks';
import { DELETED_STATUS } from '../../../../constants';
import { trimWithDots } from '../../../../util/strings';

const DeleteContactTypeDialog = ({ onClose, contactTypeData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Update: useUpdateContactType } = useContactTypes();
  const { mutateAsync: updateContactType } = useUpdateContactType();

  const handleDelete = async () => {
    try {
      if (contactTypeData?.crn) {
        await updateContactType({ contactTypeRef: contactTypeData.crn, contactType: { status: DELETED_STATUS } });
        notification.success(translateMessage('contactTypes_notification_contactTypeDeleted'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmText="delete"
      color="delete"
    >
      <h3 className="delete-title">
        <Localization.Translate stringId="contactTypes_deleteDialog_title" />
      </h3>
      <p className="delete-description">
        <Localization.Translate
          stringId="contactTypes_deleteDialog_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: contactTypeData?.name,
          }}
        />
      </p>
    </ConfirmationDialog>
  );
};

DeleteContactTypeDialog.propTypes = {
  onClose: func.isRequired,
  contactTypeData: shape({
    crn: string.isRequired,
    name: string.isRequired,
  }),
};

export default DeleteContactTypeDialog;
