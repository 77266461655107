import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const MixComponentBatchCodeContainer = styled.div`
  ${css`
    .mix-component-batch-code-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 25px;
    }
  `}
`;

export const LocationsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    margin: 0 8px 8px 8px;
    border-radius: 12px;
    box-shadow: ${theme.shadowCA(2)};
  `}
`;

export const LocationRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 16px 0;
    border-bottom: 1px solid ${theme.colorsCA.neutral[80]};

    &:last-of-type {
      border: 0;
    }

    & > div {
      flex: 1;
    }

    & > .MuiTextField-root > .MuiInputBase-root {
      max-width: 120px;
    }
  `}
`;

export const LocationLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

export const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 68px;
  align-items: center;
`;

export const NoDataHeader = styled.div`
  font-size: 22px;
  font-weight: 600;
`;
