import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const MixComponentProfileContainer = styled.div`
  ${css`
    .mix-component-profile-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 20px;
    }
  `}
`;
