import { css } from 'styled-components';

export default css`
  .app-permissions-section {
    margin-bottom: 20px;
    padding: 10px 15px;
  }
  .permission {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;
