import { useMemo } from 'react';

import { maskPhoneNumber, sanitizePhoneNumber } from '../../../../util/forms';

const useCompanyForm = (entitySetupData, usersData, updateEntitySetup, notification, translateMessage) => {
  const initialValues = useMemo(() => {
    const {
      name,
      displayName,
      primaryContact,
      address: { address1, address2, city, countrySubDivision, postalCode } = {},
      phone,
    } = entitySetupData;

    const values = {
      companyName: name || '',
      companyDisplayName: displayName || '',
      companyPrimaryContact: usersData.find(user => user.value === primaryContact?.profileRef) || '',
      companyPhone: phone ? maskPhoneNumber(sanitizePhoneNumber(phone)) : '',
      companyAddress: address1 || '',
      companyAddress2: address2 || '',
      companyCity: city || '',
      companyState: countrySubDivision || '',
      companyPostalCode: postalCode || '',
    };

    return values;
  }, [entitySetupData, usersData]);

  const handleOnSubmit = async (values, formikBag) => {
    const {
      companyDisplayName = '',
      companyPrimaryContact,
      companyPhone = '',
      companyAddress = '',
      companyAddress2 = '',
      companyCity = '',
      companyState = '',
      companyPostalCode = '',
    } = values;

    formikBag.setSubmitting(true);

    try {
      const entitySetup = {
        displayName: companyDisplayName,
        primaryContact: { profileRef: companyPrimaryContact.value },
        phone: sanitizePhoneNumber(companyPhone),
        address: {
          address1: companyAddress,
          address2: companyAddress2,
          city: companyCity,
          countrySubDivision: companyState,
          postalCode: companyPostalCode,
        },
      };

      await updateEntitySetup({ data: entitySetup });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
    } catch (error) {
      console.error(error);
    }
  };

  return { initialValues, handleOnSubmit };
};

export default useCompanyForm;
