import { useCallback, useMemo, useState } from 'react';
import { Localization } from 'connex-cds';

import { getProductTypesListColumns } from './tableConfig';
import AddProductTypeDialog from './components/add-product-type-dialog';
import EditProductTypeDialog from './components/edit-product-type-dialog';
import DeleteProductTypeDialog from './components/delete-product-type-dialog';
import { TableLayout, EntityTable, ActionButton, SearchFilter, DialogManager } from '../../commons';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { useProductTypes } from '../../api/hooks';
import { DIALOG_TYPES } from '../../constants/common';

const ProductTypes = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const [query, setQuery] = useState('');
  const [currentProductType, setCurrentProductType] = useState(null);
  const { List: useListProductTypes } = useProductTypes();
  const { data: productTypes, isLoading } = useListProductTypes({ queryParams: { activeOnly: false } });

  const setDelete = useCallback(
    productType => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentProductType(productType);
    },
    []
  );

  const productTypesColumns = useMemo(
    () => getProductTypesListColumns({ setDeleteProductType: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );
  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data: productTypes, query, properties: ['id', 'name'] }),
    [productTypes, query]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setCurrentProductType(null);
    setDialog(DIALOG_TYPES.NONE);
  };

  const handleOnRowClick = params => {
    setCurrentProductType(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="productTypes_addProductType_button" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={productTypesColumns}
          rows={filteredData}
          onRowClick={handleOnRowClick}
          getRowId={row => row.crn}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddProductTypeDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditProductTypeDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(false);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          productType={currentProductType}
          onClose={resetDialogs}
        />
        <DeleteProductTypeDialog
          identifier={DIALOG_TYPES.DELETE}
          productType={currentProductType}
          onClose={resetDialogs}
        />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default ProductTypes;
