import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import CarrierFormView from '../carrier-form-view';
import { useCarriers } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { initialValues } from '../carrier-form-view/form-config';

const AddCarrierDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCreateCarrier } = useCarriers();
  const { mutateAsync: createCarrier } = useCreateCarrier();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { carrierId, carrierName, carrierStatus, carrierCompanyFleet } = values;
    formikBag.setSubmitting(true);

    try {
      const carrier = {
        id: carrierId,
        name: carrierName,
        status: carrierStatus.value,
        companyFleet: carrierCompanyFleet,
      };
      await createCarrier({ carrier });
      notification.success(translateMessage('carriers_notification_carrierAdded'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CarrierFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      carrierStatusOptions={STATUS_OPTIONS}
    />
  );
};

AddCarrierDialog.propTypes = {
  onClose: func.isRequired,
};

AddCarrierDialog.defaultProps = {
  onClose: () => {},
};

export default AddCarrierDialog;
