import React from 'react';
import { FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';

import { withFormikField } from '../../hoc';
import { SwitchStyled } from './styles';

const SwitchBase = ({ name, value = '', onChange, customLabel, labelPlacement }) => {
  const handleChange = e => {
    onChange(name, e.target.checked);
  };

  return (
    <FormControlLabel
      control={<SwitchStyled name={name} checked={!!value} onChange={handleChange} />}
      label={customLabel}
      labelPlacement={labelPlacement}
    />
  );
};

SwitchBase.propTypes = {
  customLabel: PropTypes.element,
  labelPlacement: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default withFormikField(SwitchBase);
