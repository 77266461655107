import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { useContactTypes } from '../../../../api/hooks';
import ContactTypeFormView from '../contact-type-form-view';
import { initialValues } from '../contact-type-form-view/form-config';

const AddContactTypeDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCreateContactTypes } = useContactTypes();
  const { mutateAsync: createContactType } = useCreateContactTypes();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { contactTypeId, contactTypeName, contactTypeStatus } = values;

    formikBag.setSubmitting(true);

    try {
      const contactType = {
        id: contactTypeId,
        name: contactTypeName,
        status: contactTypeStatus.value,
      };

      await createContactType({ contactType });
      notification.success(translateMessage('contactTypes_notification_contactTypesAdded'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContactTypeFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
    />
  );
};

AddContactTypeDialog.propTypes = {
  onClose: func.isRequired,
};

AddContactTypeDialog.defaultProps = {
  onClose: () => {},
};

export default AddContactTypeDialog;
