import { css } from '@emotion/react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export const TextFieldStyled = styled(TextField)`
  ${({ theme }) => css`
    .MuiInputAdornment-root {
      position: absolute;
      right: 34px;
    }
  `}
`;

export const MenuItemStyled = styled(MenuItem)`
  ${({ theme }) => css`
    gap: 16px;
  `}
`;
