import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { entitySetups } from '../queries';

export const useEntitySetups = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['entity-setup', entityRef];

  const GetEntitySetup = () => {
    const queryResult = useQuery({
      queryKey,
      queryFn: () => entitySetups.getEntitySetup({ entityRef }),
      staleTime: Infinity,
    });

    return queryResult;
  };

  const Update = () =>
    useMutation({
      mutationFn: ({ data }) => entitySetups.updateEntitySetup({ entityRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  return { GetEntitySetup, Update };
};
