import { useState, useMemo, useCallback } from 'react';
import { Localization } from 'connex-cds';

import { useCustomers } from '../../api/hooks';
import { getCustomersListColumns } from './tableConfig';
import { TableLayout, EntityTable, SearchFilter, DialogManager, ActionButton } from '../../commons';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { DIALOG_TYPES } from '../../constants';
import AddCustomerDialog from './components/add-customer-dialog';
import DeleteCustomerDialog from './components/delete-customer-dialog';
import EditCustomerDialog from './components/edit-customer-dialog';

const Customers = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const { List: useCustomersList } = useCustomers();
  const { data, isLoading } = useCustomersList({ queryParams: { activeOnly: false } });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data, query: searchTerm, properties: ['id', 'name'] }),
    [data, searchTerm]
  );

  const setDelete = useCallback(
    customer => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentCustomer(customer);
    },
    []
  );

  const customersColumns = useMemo(
    () => getCustomersListColumns({ setDeleteCustomer: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setDialog(DIALOG_TYPES.NONE);
    setCurrentCustomer(null);
  };

  const handleOnRowClick = params => {
    setCurrentCustomer(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setSearchTerm} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="customers_addCustomer_btn" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={customersColumns}
          rows={filteredData}
          getRowId={row => row.crn}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddCustomerDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditCustomerDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(false);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          onClose={resetDialogs}
          customerData={currentCustomer}
        />
        <DeleteCustomerDialog identifier={DIALOG_TYPES.DELETE} onClose={resetDialogs} customerData={currentCustomer} />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default Customers;
