import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { concreteClasses } from '../queries';

export const useConcreteClasses = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['concrete-classes', entityRef];

  const List = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['concrete-classes', entityRef, queryString.stringify(queryParams)],
      queryFn: () => concreteClasses.listConcreteClasses({ entityRef, queryParams }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const Update = () =>
    useMutation({
      mutationFn: ({ data, concreteClassRef }) =>
        concreteClasses.updateConcreteClass({ entityRef, concreteClassRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const Create = () =>
    useMutation({
      mutationFn: ({ concreteClass }) => concreteClasses.createConcreteClass({ entityRef, concreteClass }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });
  return { List, Update, Create };
};
