import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, string, bool } from 'prop-types';
import { Grid } from '@mui/material';

import { TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { VehicleProfileContainer } from './styles';

const VehicleProfileTab = ({
  mode,
  statusOptions,
  vehicleTypesOptions,
  isLoadingVehicleTypesOptions,
  locationsOptions,
  isLoadingLocationsOptions,
  carriersOptions,
  isLoadingCarriersOptions,
}) => {
  return (
    <VehicleProfileContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="vehicles_profileTab_title" />
      </h3>
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicles_profileTab_vehicleId"
              component={TextInputWithFormikField}
              variant="outlined"
              name="vehicleId"
              tooltipInfoStringId="vehicles_profileTab_vehicleId_tooltip"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicles_profileTab_vehicleType"
              component={SelectWithFormikField}
              name="vehicleType"
              options={vehicleTypesOptions}
              disabled={isLoadingVehicleTypesOptions}
              testId="select-vehicle-type"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicles_profileTab_vehicleName"
              component={TextInputWithFormikField}
              variant="outlined"
              name="vehicleName"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicles_profileTab_vehicleStatus"
              component={SelectWithFormikField}
              name="vehicleStatus"
              options={statusOptions}
              infoTooltipStringId="vehicles_profileTab_vehicleStatus_tooltip"
              testId="select-vehicle-status"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicles_profileTab_carrier"
              component={SelectWithFormikField}
              name="carrier"
              options={carriersOptions}
              disabled={isLoadingCarriersOptions}
              testId="select-carrier"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicles_profileTab_vehicleHomeLocation"
              component={SelectWithFormikField}
              name="vehicleHomeLocation"
              options={locationsOptions}
              disabled={isLoadingLocationsOptions}
              infoTooltipStringId="vehicles_profileTab_vehicleHomeLocation_tooltip"
              testId="select-vehicle-home-location"
              required
            />
          </Grid>
        </Grid>
      </Form>
    </VehicleProfileContainer>
  );
};

VehicleProfileTab.propTypes = {
  statusOptions: array.isRequired,
  mode: string.isRequired,
  vehicleTypesOptions: array.isRequired,
  isLoadingVehicleTypesOptions: bool.isRequired,
  locationsOptions: array.isRequired,
  isLoadingLocationsOptions: bool.isRequired,
  carriersOptions: array.isRequired,
  isLoadingCarriersOptions: bool.isRequired,
};

export default VehicleProfileTab;
