import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { useVehicleTypes, useUom } from '../../../../api/hooks';
import { parseUomOptions } from '../../../../api/adapters';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { initialValues } from '../vehicle-type-form-view/form-config';
import VehicleTypeFormView from '../vehicle-type-form-view';

const AddVehicleTypeDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Create: useCreateVehicleType } = useVehicleTypes();
  const { mutateAsync: createVehicleType } = useCreateVehicleType();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUomOptions } = useListUom({
    adapter: parseUomOptions,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      vehicleTypeId,
      vehicleTypeName,
      vehicleTypeStatus,
      defaultValue,
      defaultLoadSize,
      defaultLoadSizeUOM,
      maxLoadSize,
      maxLoadSizeUOM,
      trackingColor,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const vehicleType = {
        id: vehicleTypeId,
        name: vehicleTypeName,
        status: vehicleTypeStatus.value,
        defaultValue,
        defaultLoadSize: parseInt(defaultLoadSize),
        defaultLoadSizeUOM: defaultLoadSizeUOM.value,
        maxLoadSize: parseInt(maxLoadSize),
        maxLoadSizeUOM: maxLoadSizeUOM.value,
        trackingColor,
      };

      await createVehicleType({ vehicleType });
      notification.success(translateMessage('vehicleTypes_notification_vehicleTypeAdded'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <VehicleTypeFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      uomOptions={uomOptions}
      isLoadingUomOptions={isLoadingUomOptions}
    />
  );
};

AddVehicleTypeDialog.propTypes = {
  onClose: func.isRequired,
};

AddVehicleTypeDialog.defaultProps = {
  onClose: () => {},
};

export default AddVehicleTypeDialog;
