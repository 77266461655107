import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export const StyledDialog = styled(Dialog)`
  ${({ theme }) => css`
    .MuiDialog-paper {
      max-height: 663px;
      width: 1071px;
      height: 100%;
      border-radius: 20px;
      box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3);
    }

    .welcome-dialog__title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      font-size: 30px;
      font-weight: 700;

      p {
        margin: 0;
      }
    }

    .welcome-dialog__content {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      img {
        width: 40%;
      }

      &-message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 40%;

        h3 {
          font-size: 25px;
          font-weight: 600;
        }

        p,
        li {
          font-size: 16px;
          text-align: start;
        }

        h4 {
          font-size: 20px;
          font-weight: 600;
        }

        ul {
          padding-left: 32px;
        }
      }
    }

    .welcome-dialog__actions {
      height: 80px;
      padding: 0 24px;

      .MuiButtonBase-root {
        color: #fff;
        border-radius: 100px;
        background: ${theme.colorsCA.darkBlue[10]};

        :hover {
          background: ${theme.colorsCA.darkBlue[20]};
        }
      }
    }

    .forget-form {
      display: flex;
      flex-direction: column;
      height: inherit;
    }

    .submit-button {
      &.MuiButton-root {
        background-color: blue;
      }

      &.Mui-disabled {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  `}
`;

export const StyledDialogContent = styled(DialogContent)`
  ${css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h3 {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .description {
      width: 388px;
      font-size: 24px;
      margin-bottom: 45px;
      font-weight: 400;
    }
  `}
`;
