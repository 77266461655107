import { Form, Layout, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Header } from './Header';
import { Instructions } from './Instructions';
import { PasswordRequirements } from './PasswordRequirements';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const View = props => {
  const { Components } = Form.useFormContext();

  return (
    <Styled className={cn('view')}>
      <div className="wrapper">
        <Header />
        <Instructions />
        <Layout.Container>
          <Components.Code />
          <Components.Username />
          <Components.LastName />
          <Components.FirstName />
          <Components.PhoneNumber />
          <Layout.Container className="password">
            <Typography.H3>Choose a Password</Typography.H3>
            <PasswordRequirements />
            <hr />
            <Components.Password password={true} />
            <Components.PasswordVerify />
          </Layout.Container>
          <div className="button-section">
            <Components.SubmitButton stringId="register" data-testid="register-button" disabledWhenNotDirty={false} />
          </div>
        </Layout.Container>
      </div>
    </Styled>
  );
};
