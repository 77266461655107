import { http } from 'connex-cds';

export default {
  listConcreteClasses: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/concrete-classes`, queryParams }),
  updateConcreteClass: ({ entityRef, concreteClassRef, data }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/concrete-classes/${concreteClassRef}`, data }),
  createConcreteClass: ({ entityRef, concreteClass }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/concrete-classes`,
      data: concreteClass,
    }),
};
