import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import MixDesignFormView from '../mix-form-view';
import { initialValues } from '../mix-form-view/form-config';
import { useUom, useMixes } from '../../../../api/hooks';
import { parseUomOptions } from '../../../../api/adapters';

const AddMixDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { Create: useCreateMix } = useMixes();
  const { mutateAsync: createMix } = useCreateMix();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { mixDesignId, mixDesignName, mixDesignStatus, maxBatchSize, maxBatchUom, mixFormulaId } = values;

    formikBag.setSubmitting(true);

    try {
      const data = {
        id: mixDesignId,
        name: mixDesignName,
        status: mixDesignStatus.value,
        maxBatchSize: parseInt(maxBatchSize),
        maxBatchSizeUOM: maxBatchUom.value,
        jobMixFormulaId: mixFormulaId,
      };

      await createMix({ data });
      notification.success(translateMessage('mixDesigns_addMixDesignNotification_success_msg'));

      handleClose();
      formikBag.setSubmitting(false);
      formikBag.resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MixDesignFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      mixDesignStatusOptions={STATUS_OPTIONS}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
    />
  );
};

AddMixDialog.propTypes = {
  onClose: func.isRequired,
};

AddMixDialog.defaultProps = {
  onClose: () => {},
};

export default AddMixDialog;
