import { TranslatedCell, CustomIcon } from '../../commons';
import { KebabOption } from '../../commons/entity-table';

export const getTrailersListColumns = ({ setDeleteTrailer, translateMessage }) => [
  {
    field: 'id',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_id'} />,
  },
  {
    field: 'name',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_name'} />,
  },
  {
    field: 'maximumGrossVehicleWeight',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'trailers_tableHeader_maxGross'} />,
  },
  {
    field: 'maximumNetVehicleWeight',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'trailers_tableHeader_maxNet'} />,
  },
  {
    field: 'targetGrossVehicleWeight',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'trailers_tableHeader_targetGross'} />,
  },
  {
    field: 'targetNetVehicleWeight',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'trailers_tableHeader_targetNet'} />,
  },
  {
    field: 'status',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_status'} />,
    valueGetter: ({ row }) => translateMessage(row.status.toLowerCase()),
  },
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    width: 80,
    cellClassName: 'table-actions',
    getActions: params => [
      <KebabOption
        icon={<CustomIcon iconName="deleteIcon" />}
        label={translateMessage('delete')}
        onClick={setDeleteTrailer(params.row)}
        showInMenu
      />,
    ],
  },
];
