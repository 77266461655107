import React, { useState } from 'react';
import { Localization } from 'connex-cds';
import { Formik } from 'formik';
import { Button } from '@mui/material';

import { DIALOG_TYPES } from '../../../../constants';
import {
  CustomIcon,
  CustomTabsControl,
  CustomTabsView,
  DialogActions,
  DialogContainer,
  DialogContent,
  DialogTitle,
} from '../../../../commons';
import { TAB_IDS, validationSchema } from './form-config';
import CustomerProfileTab from '../customer-profile-tab';

const CustomerFormView = ({ open, initialValues, onClose, onSubmit, mode, customerStatusOptions, handleOnDelete }) => {
  const [currentTab, setCurrentTab] = useState(TAB_IDS.AddCustomerProfile);

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
  };

  const handleClose = () => {
    onClose();
    setCurrentTab(TAB_IDS.AddCustomerProfile);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case DIALOG_TYPES.ADD:
        icon = 'addOutlinedIcon';
        stringId = 'customers_addCustomer_title';
        break;

      case DIALOG_TYPES.EDIT:
        icon = 'editOutlinedIcon';
        stringId = 'customers_editCustomer_title';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };

  const TABS = [
    {
      tabIdentifier: TAB_IDS.AddCustomerProfile,
      label: <Localization.Translate stringId={'customers_addCustomerProfile_tab'} />,
      icon: <CustomIcon iconName={'driveFileRenameOutlineRounded'} />,
    },
  ];
  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onDelete={handleOnDelete} onClose={handleClose}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ isSubmitting, dirty, isValid, handleSubmit }) => {
          return (
            <>
              <CustomTabsControl tabs={TABS} currentTab={currentTab} handleTabChange={handleTabChange} />
              <DialogContent>
                <CustomTabsView tab={currentTab}>
                  <CustomerProfileTab
                    mode={mode}
                    tabIdentifier={TAB_IDS.AddCustomerProfile}
                    customerStatusOptions={customerStatusOptions}
                  />
                </CustomTabsView>
              </DialogContent>
              <DialogActions
                rightSide={
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !dirty || !isValid}
                    onClick={handleSubmit}
                  >
                    <Localization.Translate stringId="general_dialogAction_save" />
                  </Button>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

export default CustomerFormView;
