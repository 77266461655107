import React, { useMemo, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';

import CustomerFormView from '../customer-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { useCustomers } from '../../../../api/hooks';

const EditCustomerDialog = ({ onClose, customerData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { Update: useUpdateCustomer } = useCustomers();
  const { mutateAsync: updateCustomer } = useUpdateCustomer();

  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { name, status, streetAddress, complement, city, state, postalCode } = values;

    formikBag.setSubmitting(true);

    try {
      const data = {
        name,
        status: status.value,
        address: {
          address1: streetAddress,
          address2: complement,
          city,
          countrySubDivision: state,
          postalCode,
        },
      };

      await updateCustomer({ data, customerRef: customerData.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!customerData) return null;

    const { id, name, status, address } = customerData;

    const values = {
      id: id,
      name: name || '',
      status: STATUS_OPTIONS.find(item => item.value === status) || '',
      streetAddress: address?.address1 || '',
      complement: address?.address2 || '',
      city: address?.city || '',
      state: address?.countrySubDivision || '',
      postalCode: address?.postalCode || '',
    };

    return values;
  }, [customerData]);

  return (
    <CustomerFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      customerStatusOptions={STATUS_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

export default EditCustomerDialog;
