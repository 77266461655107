import React, { useMemo, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, shape, bool } from 'prop-types';
import { omit } from 'lodash';

import {
  useLocations,
  useMixes,
  usePartners,
  useProductLocations,
  useProductTypes,
  useProducts,
  useUom,
} from '../../../../api/hooks';
import ProductFormView from '../product-form-view';
import { BOOLEAN_VALUES, STATUS_OPTIONS } from '../../../../constants';
import { isDevEnvironment, isLocalEnvironment } from '../../../../util/environment';
import { getMappedInventoryLocations, getMappedProductLocations } from '../../../../util/productLocationHelper';
import { INVENTORY_SOURCE_OPTIONS, PRODUCT_LOCATION_STATUSES } from '../product-form-view/form-config';
import {
  parseMixOptions,
  parseUomOptions,
  parsePartnerOptions,
  parseProductTypesOptions,
  parseLocations,
} from '../../../../api/adapters';

const EditProductDialog = ({ onClose, productData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { List: useListProductTypes } = useProductTypes();
  const { data: productTypesOptions, isLoading: isLoadingProductTypes } = useListProductTypes({
    adapter: parseProductTypesOptions,
  });
  const { List: listMixes } = useMixes();
  const { List: listProductLocations } = useProductLocations();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const { List: useListLocations } = useLocations();
  const { data: locationOptions, isLoading: isLoadingLocations } = useListLocations({ adapter: parseLocations });
  const { List: listPartners } = usePartners();
  const { data: partnerOptions, isLoading: isLoadingPartners } = listPartners({ adapter: parsePartnerOptions });
  const { Update: useUpdateProduct } = useProducts();
  const { mutateAsync: updateProduct } = useUpdateProduct();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { data: mixesOptions, isLoading: isLoadingMixes } = listMixes({
    queryParams: { activeOnly: true },
    adapter: parseMixOptions,
  });
  const { data: productLocationsData, isLoading: isProductLocationsDataLoading } = listProductLocations(
    productData?.crn
  );

  const productLocations = useMemo(() => {
    if (!isProductLocationsDataLoading && productLocationsData && productLocationsData.length) {
      return productLocationsData;
    }
    return [];
  }, [productLocationsData, isProductLocationsDataLoading]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleLocationsChange = (setFieldValue, value) => {
    const inventoryLocations = getMappedInventoryLocations(value, productLocations);
    setFieldValue('inventoryLocations', inventoryLocations);
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      productId,
      productName,
      productStatus,
      productType,
      uom,
      locations,
      keepInInventory,
      inventorySource,
      inventoryLocations,
    } = values;

    const locationsObject = locations.reduce((accumulator, location) => {
      return { ...accumulator, [location.value]: true };
    }, {});

    formikBag.setSubmitting(true);

    try {
      const msg = 'notification_changesSaved';
      const inventory = {
        ...omit(productData.inventory, 'trackInventory'),
        source: keepInInventory ? inventorySource?.value : null,
      };

      let productLocationsList = undefined;
      if (isDevEnvironment || isLocalEnvironment) {
        const availableProductLocationStatus = keepInInventory
          ? PRODUCT_LOCATION_STATUSES.Active
          : PRODUCT_LOCATION_STATUSES.Deleted;
        const availableProductLocations = getMappedProductLocations(
          inventoryLocations,
          productLocations,
          availableProductLocationStatus
        );
        const removedProductLocations = productLocations
          .filter(x => !inventoryLocations?.some(inventoryLocation => x.locationRef === inventoryLocation.value))
          .map(x => ({ ...x, status: PRODUCT_LOCATION_STATUSES.Deleted }));
        productLocationsList = [...availableProductLocations, ...removedProductLocations];
      }

      const product = {
        id: productId,
        name: productName,
        type: productType.value,
        uomCode: uom.value,
        locations: locations.length === locationOptions.length ? { all: true } : locationsObject,
        status: productStatus.value,
        inventory,
        productLocations: productLocationsList,
        trackInventory: keepInInventory ? BOOLEAN_VALUES.Yes : BOOLEAN_VALUES.No,
      };

      await updateProduct({ product, productRef: productData?.crn });
      notification.success(translateMessage(msg));
    } catch (error) {
      console.error(error);
    }

    formikBag.setSubmitting(false);
    formikBag.resetForm();
    handleClose();
  };

  const getInitialValues = useMemo(() => {
    if (!productData) return null;
    const { id, name, locations: editLocations } = productData;

    const productType = productTypesOptions.find(type => type.value === productData.type);
    const uom = uomOptions.find(uomItem => uomItem.value === productData.uomCode);
    const productStatus = STATUS_OPTIONS.find(status => status.value === productData.status);
    const areAllLocations = Object.keys(editLocations).length === 1 && Object.keys(editLocations)[0] === 'all';
    const newLocations = locationOptions.reduce((accumulator, current) => {
      const location = Object.keys(editLocations).filter(loc => loc === current.value);
      return location.length ? [...accumulator, current] : accumulator;
    }, []);
    const keepInInventory = productData.trackInventory === BOOLEAN_VALUES.Yes;
    const locations = areAllLocations ? locationOptions : newLocations;
    const inventorySource =
      INVENTORY_SOURCE_OPTIONS.find(option => option.value === productData.inventory?.source) ?? '';
    const inventoryLocations = getMappedInventoryLocations(locations, productLocations, partnerOptions);

    const editValues = {
      productId: id,
      productName: name,
      productType,
      uom,
      locations,
      productStatus,
      mix: '',
      keepInInventory,
      inventorySource,
      inventoryLocations,
    };

    return editValues;
  }, [productData, productTypesOptions, uomOptions, locationOptions, productLocations, partnerOptions]);

  return (
    <ProductFormView
      handleOnDelete={handleOnDelete}
      open={isOpen}
      onClose={handleClose}
      mode="EDIT"
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      productTypesOptions={productTypesOptions}
      isLoadingProductTypes={isLoadingProductTypes}
      mixesOptions={mixesOptions}
      isLoadingMixes={isLoadingMixes}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      locationOptions={locationOptions}
      isLoadingLocations={isLoadingLocations}
      productStatusOptions={STATUS_OPTIONS}
      inventorySourceOptions={INVENTORY_SOURCE_OPTIONS}
      onLocationsChange={handleLocationsChange}
      isProductLocationsDataLoading={isProductLocationsDataLoading}
      partnerOptions={partnerOptions}
      isLoadingPartners={isLoadingPartners}
    />
  );
};

EditProductDialog.propTypes = {
  productData: shape({}),
  onClose: func.isRequired,
  handleOnDelete: func,
  open: bool,
};

EditProductDialog.defaultProps = {
  editProduct: null,
  open: false,
  onClose: () => {},
};

export default EditProductDialog;
