import { http } from 'connex-cds';

export default {
  listPartners: ({ entityRef }) => http.get({ apiName: 'company-admin', path: `/${entityRef}/partners` }),
  listPartnerProjects: ({ entityRef, partnerRef }) => {
    if (!partnerRef) {
      return [];
    }
    return http.get({ apiName: 'company-admin', path: `/${entityRef}/partners/${partnerRef}/projects` });
  },
  listPartnerProjectProducts: ({ entityRef, partnerRef, projectRef }) => {
    if (!partnerRef || !projectRef) {
      return [];
    }
    return http.get({
      apiName: 'company-admin',
      path: `/${entityRef}/partners/${partnerRef}/projects/${projectRef}/products`,
    });
  },
  listPartnerPurchaseOrders: ({ entityRef, partnerRef }) => {
    if (!partnerRef) {
      return [];
    }
    return http.get({ apiName: 'company-admin', path: `/${entityRef}/partners/${partnerRef}/purchase-orders` });
  },
};
