import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, bool, string } from 'prop-types';
import Grid from '@mui/material/Grid';

import { CheckboxWithFormikField, TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { LocationProfileFormContainer } from './styles';
import { DIALOG_TYPES } from '../../../../constants';

const LocationProfileTab = ({ mode, locationStatusOptions, productTypesOptions, isLoadingProductTypesOptions }) => {
  return (
    <LocationProfileFormContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="locations_addLocations_profileTab_title" />
      </h3>

      <Form>
        <Grid container spacing={5} alignItems="baseline">
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="locations_profileTab_locationId_field_label"
              component={TextInputWithFormikField}
              variant="outlined"
              name="locationId"
              tooltipInfoStringId="locations_profileTab_locationId_field_tooltip"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="locations_profileTab_locationDescription_field_label"
              component={TextInputWithFormikField}
              variant="outlined"
              name="description"
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              labelStringId="locations_profileTab_locationName_field_label"
              component={TextInputWithFormikField}
              name="locationName"
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              labelStringId="locations_profileTab_primaryProductType_field_label"
              component={SelectWithFormikField}
              name="primaryProductType"
              disabled={isLoadingProductTypesOptions}
              options={productTypesOptions}
              required
            />
          </Grid>
          <Grid item container md={6} xs={12}>
            <Grid item container spacing={5}>
              <Grid item md={12}>
                <Field
                  fullWidth
                  labelStringId="locations_profileTab_streetAddress_field_label"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="streetAddress"
                  required
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  fullWidth
                  labelStringId="locations_profileTab_complement_field_label"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="complement"
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  fullWidth
                  labelStringId="locations_profileTab_city_field_label"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="city"
                  required
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  fullWidth
                  labelStringId="locations_profileTab_state_field_label"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="state"
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  fullWidth
                  labelStringId="locations_profileTab_postalCode_field_label"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="postalCode"
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={5} md={6} xs={12}>
            <Grid item md={12}>
              <Field
                fullWidth
                labelStringId="locations_profileTab_status_field_label"
                infoTooltipStringId="locations_profileTab_status_field_tooltip"
                component={SelectWithFormikField}
                name="status"
                options={locationStatusOptions}
                required
              />
            </Grid>
            <Grid item md={12} className="custom-form-control-checkbox">
              <Field
                fullWidth
                labelStringId="locations_profileTab_track_inventory_label"
                component={CheckboxWithFormikField}
                name="trackInventory"
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </LocationProfileFormContainer>
  );
};

LocationProfileTab.propTypes = {
  mode: string.isRequired,
  locationStatusOptions: array.isRequired,
  productTypesOptions: array.isRequired,
  isLoadingProductTypesOptions: bool.isRequired,
};

export default LocationProfileTab;
