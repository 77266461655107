import * as yup from 'yup';
import { PHONE_REGEX } from '../../../../util/forms';

export const validationSchema = yup.object().shape({
  companyDisplayName: yup.string(),
  companyPrimaryContact: yup.object().required('required'),
  companyPhone: yup.string().matches(PHONE_REGEX, 'error_invalidPhone'),
  companyAddress: yup.string(),
  companyAddress2: yup.string(),
  companyCity: yup.string(),
  companyState: yup.string(),
  companyPostalCode: yup.string(),
});
