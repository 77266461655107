import React from 'react';
import { CustomerProfileFormContainer } from './styles';
import { SelectWithFormikField, TextInputWithFormikField } from '../../../../commons';
import { Field, Form } from 'formik';
import { Localization } from 'connex-cds';
import { Grid } from '@mui/material';
import { DIALOG_TYPES } from '../../../../constants';

const CustomerProfileTab = ({ customerStatusOptions, mode }) => {
  return (
    <CustomerProfileFormContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="customers_addCustomerProfile_tab" />
      </h3>

      <Form>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="customers_id_field"
              component={TextInputWithFormikField}
              variant="outlined"
              name="id"
              tooltipInfoStringId="customers_id_field_tooltip"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="customers_name_field"
              component={TextInputWithFormikField}
              variant="outlined"
              name="name"
              required
            />
          </Grid>
          <Grid item container md={6} xs={12}>
            <Grid item container spacing={5}>
              <Grid item md={12}>
                <Field
                  fullWidth
                  labelStringId="customers_addresss_field"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="streetAddress"
                  required
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  fullWidth
                  labelStringId="customers_address_complement_field"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="complement"
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  fullWidth
                  labelStringId="customers_address_city_field"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="city"
                  required
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  fullWidth
                  labelStringId="customers_address_state_field"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="state"
                  required
                />
              </Grid>
              <Grid item md={6}>
                <Field
                  fullWidth
                  labelStringId="customers_address_postalCode_field"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="postalCode"
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              labelStringId="customers_status_field"
              infoTooltipStringId="customers_status_field_tooltip"
              component={SelectWithFormikField}
              name="status"
              options={customerStatusOptions}
              required
            />
          </Grid>
        </Grid>
      </Form>
    </CustomerProfileFormContainer>
  );
};

export default CustomerProfileTab;
