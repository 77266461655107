import { http } from 'connex-cds';

export default {
  listVehicles: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/vehicles`, queryParams }),
  createVehicle: ({ entityRef, vehicle }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/vehicles`,
      data: vehicle,
    }),
  updateVehicle: ({ entityRef, vehicleRef, vehicle }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/vehicles/${vehicleRef}`, data: vehicle }),
};
