import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { mixes } from '../queries';

export const useMixes = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['mixes', entityRef];

  const List = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['mixes', entityRef, queryString.stringify(queryParams)],
      queryFn: () => mixes.listMixes({ entityRef, queryParams }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const Create = () =>
    useMutation({
      mutationFn: ({ data }) => mixes.createMix({ entityRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const Update = () =>
    useMutation({
      mutationFn: ({ data, mixRef }) => mixes.updateMix({ entityRef, mixRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  return { List, Create, Update };
};
