import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Switch } from '@mui/material';

export const SwitchStyled = styled(Switch)(
  ({ theme, ...props }) => css`
    width: auto;
    height: 40px;
    padding: 0px;
    .MuiButtonBase-root {
      padding: 5.3px;
      &:hover {
        background: none;
      }
      &.Mui-checked {
        transform: translateX(-41px);
        left: 100%;
        + .MuiSwitch-track {
          padding: 0 45px 0 20px;
          opacity: 1;
          background: ${theme.validateMode({
            light: theme.colorsCA.blue[10],
            dark: theme.colorsCA.darkBlue[60],
          })};
          &::before {
            color: ${theme.validateMode(theme.colorsCA.neutral[100])};
          }
        }
      }
    }
    .MuiSwitch-track {
      opacity: 1;
      border-radius: 57px;
      background: ${theme.validateMode({
        light: 'linear-gradient(180deg, rgba(217, 217, 217, 0.75) 0%, rgba(217, 217, 217, 0.3) 100%)',
        dark: `linear-gradient(0deg, rgba(85, 187, 233, 0.05) 0%, rgba(85, 187, 233, 0.05) 100%), ${theme.colorsCA.neutral[10]}`,
      })};
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5) inset;
      min-width: 78px;
      padding: 0 20px 0 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        content: '${props['aria-label']}';
        font-size: 17px;
        font-weight: 500;
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[100] })};
      }
    }
    .MuiSwitch-thumb {
      width: 30px;
      box-shadow: none;
      height: 30px;
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
      background: ${theme.validateMode({
        light: 'radial-gradient(50% 50% at 50% 50%, #fff 0%, #f9f9f9 100%)',
        dark: theme.backgroundCA(4),
      })};
    }
  `
);
