import { Tab } from '@mui/material';
import { TabsControlStyled } from './styles';

const CustomTabsControl = ({ tabs, currentTab, handleTabChange }) => {
  return (
    <TabsControlStyled value={currentTab} onChange={handleTabChange} variant="fullWidth">
      {tabs.map(({ label, icon, tabIdentifier }) => (
        <Tab key={tabIdentifier} icon={icon} label={label} />
      ))}
    </TabsControlStyled>
  );
};

export default CustomTabsControl;
