import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

const BillingAdminStyled = styled(Stack)(
  ({ theme }) => css`
    height: 100%;
    padding-left: 3px;
    * {
      scrollbar-color: ${theme.validateMode({ light: '#a6a7a9', dark: theme.colorsCA.darkBlue[40] })}
        ${theme.validateMode({ light: '#e7e7e7', dark: theme.colorsCA.darkBlue[70] })};
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: ${theme.validateMode({ light: '#e7e7e7', dark: theme.colorsCA.darkBlue[70] })};
        border-radius: 8px;
        border: none;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.validateMode({ light: '#a6a7a9', dark: theme.colorsCA.darkBlue[40] })};
        border-radius: 8px;
      }
    }
    header {
      display: flex;
      flex-direction: column;
      gap: 35px;
      margin-bottom: 59px;
      .text-content {
        gap: 10px;
        .MuiTypography-root {
          font-size: 17px;
          font-weight: 400;
          &.MuiTypography-h3 {
            font-size: 36px;
            font-weight: 500;
          }
          &.MuiTypography-body1 {
            max-width: 45%;
          }
        }
      }
      .MuiButtonBase-root {
        width: fit-content;
        padding: 10px 24px;
        background: ${theme.validateMode({ light: theme.colorsCA.blue[10], dark: theme.colorsCA.blue[90] })};
        color: ${theme.validateMode({ dark: theme.colorsCA.blue[10] })};
        &:hover {
          background: ${theme.validateMode({
            light: theme.colorsCA.blue[20],
            dark: theme.colorsCA.blue[80],
          })};
        }
      }
    }
    .config-options {
      flex-direction: row;
      gap: 48px;
    }
  `
);

export default BillingAdminStyled;
