import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { vehicles } from '../queries';

export const useVehicles = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['vehicles', entityRef];

  const List = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['vehicles', entityRef, queryString.stringify(queryParams)],
      queryFn: () => vehicles.listVehicles({ entityRef, queryParams }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const Create = () =>
    useMutation({
      mutationFn: ({ vehicle }) => vehicles.createVehicle({ entityRef, vehicle }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const Update = () =>
    useMutation({
      mutationFn: ({ vehicle, vehicleRef }) => vehicles.updateVehicle({ entityRef, vehicleRef, vehicle }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  return { List, Create, Update };
};
