import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const validationSchema = yup.object().shape({
  id: yup.string().required('required'),
  name: yup.string().required('required'),
  maximumGrossVehicleWeight: yup.string(),
  maximumNetVehicleWeight: yup.string(),
  targetGrossVehicleWeight: yup.string(),
  targetNetVehicleWeight: yup.string(),
  status: yup.object().required('required'),
});

export const initialValues = {
  id: '',
  name: '',
  maximumGrossVehicleWeight: '',
  maximumNetVehicleWeight: '',
  targetGrossVehicleWeight: '',
  targetNetVehicleWeight: '',
  status: STATUS_OPTIONS[0],
};
