import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const TaxDisclaimerStyled = styled(Stack)(
  ({ theme }) => css`
    align-items: center;
    backdrop-filter: blur(24px);
    background: ${theme.validateMode({
      light: 'rgba(252, 251, 255, 0.40)',
      dark: 'rgba(136, 136, 136, 0.23)',
    })};
    border: 1px solid
      ${theme.validateMode({
        light: theme.colorsCA.neutral[100],
        dark: '#938F99',
      })};
    border-radius: 20px;
    box-shadow: 0px 4px 20px 0px rgba(101, 119, 136, 0.25);
    flex-direction: row;
    gap: 42px;
    margin: 26px;
    padding: 20px;

    > img {
      width: 120px;
    }

    .title-wrapper {
      align-items: center;
      flex-direction: row;
      gap: 10px;
    }

    .title {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
    }

    .subtitle {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[60], dark: theme.colorsCA.neutral[80] })};
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }

    .text {
      font-size: 16px;
    }
  `
);
