import IconButton from '@mui/material/IconButton';
import { DialogTitleStyled } from './styles';
import { CustomIcon } from '../..';

const DialogTitle = ({ onDelete, children, onClose }) => {
  const renderButtons = () => (
    <div className="buttons-container">
      {onDelete && (
        <IconButton className="icon-button" variant="outlined" onClick={onDelete}>
          <CustomIcon iconName="deleteIcon" />
        </IconButton>
      )}
      {onClose && (
        <IconButton className="icon-button" variant="outlined" onClick={onClose}>
          <CustomIcon iconName="closeIcon" />
        </IconButton>
      )}
    </div>
  );
  return (
    <DialogTitleStyled>
      {children}
      {(onDelete || onClose) && renderButtons()}
    </DialogTitleStyled>
  );
};

export default DialogTitle;
