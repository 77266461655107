import { useEntitySetups } from '../../api/hooks';

import CompanySummary from './components/company-summary';
import CompanyForm from './components/company-form';
import { StackStyled } from './styles';

const Company = () => {
  const { GetEntitySetup: useEntitySetup } = useEntitySetups();
  const { data: entitySetup, isLoading: isLoadingEntitySetup } = useEntitySetup();

  return (
    <StackStyled>
      <CompanySummary
        companyName={entitySetup?.name}
        companyDescription={entitySetup?.description}
        isLoadingEntitySetup={isLoadingEntitySetup}
      />
      <CompanyForm entitySetupData={entitySetup} isLoadingEntitySetup={isLoadingEntitySetup} />
    </StackStyled>
  );
};

export default Company;
