import { css } from '@emotion/react';
import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel';

export const FormControlLabelStyled = styled(FormControlLabel)`
  ${({ theme }) => css`
    width: 100%;
    margin-right: 11px;

    .MuiCheckbox-root {
      svg {
        height: 18px;
        width: 18px;
      }
    }
    .MuiFormControlLabel-label {
      font-size: 16px;
      display: flex;
      align-items: center;

      svg {
        margin-left: 10px;
      }
    }
  `}
`;
