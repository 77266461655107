import { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { Localization, useNotification } from 'connex-cds';

import { ConfirmationDialog } from '../../../../commons';
import { useVehicleTypes } from '../../../../api/hooks';
import { DELETED_STATUS } from '../../../../constants';
import { trimWithDots } from '../../../../util/strings';

const DeleteVehicleTypeDialog = ({ onClose, vehicleTypeData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Update: useUpdateVehicleType } = useVehicleTypes();
  const { mutateAsync: updateVehicleType } = useUpdateVehicleType();

  const handleDelete = async () => {
    try {
      if (vehicleTypeData?.crn) {
        await updateVehicleType({ vehicleTypeRef: vehicleTypeData.crn, data: { status: DELETED_STATUS } });
        notification.success(translateMessage('vehicleTypes_notification_vehicleTypeDeleted'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmText="delete"
      color="delete"
    >
      <h3 className="delete-title">
        <Localization.Translate stringId="vehicleTypes_deleteDialog_title" />
      </h3>
      <p className="delete-description">
        <Localization.Translate
          stringId="vehicleTypes_deleteDialog_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: vehicleTypeData?.name,
          }}
        />
      </p>
    </ConfirmationDialog>
  );
};

DeleteVehicleTypeDialog.propTypes = {
  onClose: func.isRequired,
  vehicleTypeData: shape({
    crn: string.isRequired,
    name: string.isRequired,
  }),
};

export default DeleteVehicleTypeDialog;
