import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TextField, Chip } from '@mui/material';

export const TextFieldStyled = styled(TextField)`
  ${({ theme }) => css`
    .MuiFormLabel-root {
      margin-left: 35px;
    }

    .MuiInputLabel-shrink {
      margin-left: 0;
    }

    .Mui-disabled {
      .MuiChip-icon {
        color: ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.26)', dark: theme.colorsCA.neutral[50] })};
      }
    }
  `}
`;

export const ChipStyled = styled(Chip)`
  ${({ theme }) => css`
    display: flex;
    gap: 8px;
    height: 32px;
    width: 45px;
    background-color: transparent;

    .MuiChip-label {
      padding: 0;
    }
  `}
`;

export const CustomTagStyled = styled(Chip)`
  ${({ theme }) => css`
    display: flex;
    gap: 8px;
    padding: 8px 16px;
    height: 32px;
    border-radius: 8px;
    background-color: ${theme.validateMode({ light: theme.colorsCA.aqua[95], dark: theme.colorsCA.neutral[30] })};
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[10] })};

    .MuiChip-deleteIcon {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[10] })};
      font-size: 18px;
      margin: 0;
    }

    .MuiChip-label {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[10] })};
      font-family: Work Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding: 0;
    }
  `}
`;
