import React, { useState } from 'react';
import { Formik } from 'formik';
import { Localization } from 'connex-cds';
import { Button } from '@mui/material';
import { TAB_IDS, validationSchema } from './form-config';
import {
  DialogTitle,
  CustomTabsControl,
  CustomTabsView,
  CustomIcon,
  DialogContent,
  DialogActions,
  DialogContainer,
} from '../../../../commons';
import ProductInventoryTab from '../product-inventory-tab';
import ProductProfileTab from '../product-profile-tab';

const ProductFormView = ({
  open,
  onClose,
  mode,
  initialValues,
  onSubmit,
  productTypesOptions,
  isLoadingProductTypes,
  mixesOptions,
  isLoadingMixes,
  uomOptions,
  isLoadingUom,
  locationOptions,
  isLoadingLocations,
  productStatusOptions,
  inventorySourceOptions,
  onLocationsChange,
  isProductLocationsDataLoading,
  partnerOptions,
  isLoadingPartners,
  handleOnDelete,
}) => {
  const [currentTab, setCurrentTab] = useState(TAB_IDS.AddProductProfile);

  const handleTabChange = (_, value) => {
    setCurrentTab(value);
  };

  const goToStep = stepIndex => () => {
    setCurrentTab(stepIndex);
  };

  const handleClose = () => {
    onClose();
    setCurrentTab(0);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const TABS = [
    {
      tabIdentifier: TAB_IDS.AddProductProfile,
      label: <Localization.Translate stringId={'products_addProduct_productProfile'} />,
      icon: <CustomIcon iconName={'driveFileRenameOutlineRounded'} />,
    },
    {
      tabIdentifier: TAB_IDS.AddProductInventory,
      label: <Localization.Translate stringId={'products_addProduct_inventory'} />,
      icon: <CustomIcon iconName={'inventoryOutlined'} />,
    },
  ];

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case 'ADD':
        icon = 'addOutlinedIcon';
        stringId = 'products_addProduct_title';
        break;
      case 'EDIT':
        icon = 'editOutlinedIcon';
        stringId = 'products_editProduct_title';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onDelete={handleOnDelete} onClose={handleClose}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ values, isSubmitting, dirty, isValid, handleSubmit, setFieldValue }) => {
          const { productType, keepInInventory, inventoryLocations } = values;
          return (
            <>
              <CustomTabsControl tabs={TABS} currentTab={currentTab} handleTabChange={handleTabChange} />
              <DialogContent>
                <CustomTabsView tab={currentTab}>
                  <ProductProfileTab
                    tabIdentifier={TAB_IDS.AddProductProfile}
                    isLoadingProductTypes={isLoadingProductTypes}
                    productTypesOptions={productTypesOptions}
                    isLoadingMixes={isLoadingMixes}
                    mixesOptions={mixesOptions}
                    isLoadingUom={isLoadingUom}
                    uomOptions={uomOptions}
                    locationOptions={locationOptions}
                    isLoadingLocations={isLoadingLocations}
                    productType={productType}
                    productStatusOptions={productStatusOptions}
                    setFieldValue={setFieldValue}
                    onLocationsChange={onLocationsChange}
                  />
                  <ProductInventoryTab
                    tabIdentifier={TAB_IDS.AddProductInventory}
                    inventorySourceOptions={inventorySourceOptions}
                    keepInInventory={keepInInventory}
                    inventoryLocations={inventoryLocations}
                    isProductLocationsDataLoading={isProductLocationsDataLoading}
                    partnerOptions={partnerOptions}
                    isLoadingPartners={isLoadingPartners}
                    setFieldValue={setFieldValue}
                  />
                </CustomTabsView>
              </DialogContent>
              <DialogActions
                lefSide={
                  currentTab !== 0 && (
                    <Button onClick={goToStep(currentTab - 1)} className="step-control">
                      <Localization.Translate stringId="general_dialogAction_back" />
                    </Button>
                  )
                }
                rightSide={
                  <>
                    {currentTab < TABS.length - 1 && (
                      <Button onClick={goToStep(currentTab + 1)} className="step-control">
                        <Localization.Translate stringId="general_dialogAction_next" />
                      </Button>
                    )}
                    {currentTab === TABS.length - 1 && (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting || !dirty || !isValid}
                        onClick={handleSubmit}
                      >
                        <Localization.Translate stringId="general_dialogAction_save" />
                      </Button>
                    )}
                  </>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

export default ProductFormView;
