import { forwardRef } from 'react';
import { Localization } from 'connex-cds';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { omit } from 'lodash';

import { withFormikField } from '../../hoc';
import FormTooltip from '../form-tooltip/FormTooltip';

import { MuiRadioGroupStyled } from './styles';

const TooltipIcon = forwardRef((props, ref) => <InfoOutlinedIcon {...props} ref={ref} className="info-icon" />);

const RadioGroup = ({ name, value, disabled, testId, onChange, isSubmitting, errors, options, ...otherProps }) => {
  const translate = Localization.useTranslateMessage();

  const handleChange = e => {
    onChange(name, e.target.value);
  };

  return (
    <MuiRadioGroupStyled
      name={name}
      value={value}
      data-testid={testId}
      onChange={handleChange}
      {...omit(otherProps, ['setFieldTouched', 'setFieldValue'])}
    >
      {options.map(({ value, label, labelStringId, tooltipStringId }) => (
        <FormControlLabel
          className="radio-option-label"
          key={value}
          control={<Radio />}
          value={value}
          label={
            <>
              {!!labelStringId ? translate(labelStringId) : label}
              {tooltipStringId && (
                <FormTooltip
                  title={translate(tooltipStringId)}
                  PopperProps={{ disablePortal: true }}
                  arrow
                  placement="right"
                >
                  <TooltipIcon />
                </FormTooltip>
              )}
            </>
          }
          disabled={isSubmitting || disabled}
        />
      ))}
    </MuiRadioGroupStyled>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string,
      labelStringId: PropTypes.string,
      tooltipStringId: PropTypes.string,
    })
  ).isRequired,
};

RadioGroup.defaultProps = {
  disabled: false,
  options: [],
  isSubmitting: false,
};

export default withFormikField(RadioGroup);
