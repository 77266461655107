import cn from 'classnames';
import { Core, Form, Layout, Localization, Typography } from 'connex-cds';
import { find, omit, pick } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useApps } from '../../../api/hooks';
import { App } from './App';
import { NewApp } from './NewApp';
import style from './style.js';

const Styled = styled.div`
  ${style}
`;

export const AppPermissions = ({ fieldConfig, onChange, isRequired, name }) => {
  const { values } = Form.useFormContext();
  const { data: allApps, isLoading } = useApps();

  const role = React.useMemo(() => {
    return { ...values, permissions: fieldConfig?.transformIn?.(values?.permissions) };
  }, [fieldConfig, values]);

  const selectedApps = React.useMemo(() => {
    const rolePermissions = role?.permissions;
    return Object.keys(rolePermissions || {})?.map?.(key => {
      const targetAppDef = find(allApps, { crn: key });
      return {
        ...pick(targetAppDef, ['crn', 'name']),
        permissions: rolePermissions[key],
      };
    });
  }, [allApps, role]);

  const handleAddApp = React.useCallback(
    appRef => {
      if (find(selectedApps, { crn: appRef })) return;

      const appDef = find(allApps, { crn: appRef });
      const permissions = appDef?.permissions?.length ? {} : { '*': true };
      const transformed = fieldConfig.transformOut(appRef, { ...(role?.permissions || {}), [appRef]: permissions });
      onChange(transformed);
    },
    [selectedApps, allApps, role?.permissions, fieldConfig, onChange]
  );

  const handleChange = React.useCallback(
    (appRef, permissions) => {
      // Gather permissions that are set to true
      const truePermissions = Object.keys(permissions || {})?.reduce?.((acc, key) => {
        if (permissions[key]) {
          acc[key] = true;
        }
        return acc;
      }, {});

      const updatedPermissions = {
        ...(role.permissions || {}),
        [appRef]: truePermissions,
      };

      const transformed = fieldConfig?.transformOut?.(appRef, updatedPermissions) || permissions;

      onChange(transformed);
    },
    [fieldConfig, onChange, role]
  );

  const handleDelete = React.useCallback(
    appRef => {
      const updatedPermissions = {
        ...omit(role.permissions || {}, appRef),
      };
      const transformed = fieldConfig?.transformOut?.(appRef, updatedPermissions);
      onChange(transformed);
    },
    [fieldConfig, onChange, role]
  );

  return (
    <Styled className={cn('permissions')}>
      <Core.Spinner spin={isLoading}>
        <Typography.Subtitle>
          <Localization.Translate stringId={'app-permissions'} data-testid={`permissions-label`} />
        </Typography.Subtitle>

        <Layout.Container className="app-permissions-section">
          {selectedApps.map(app => (
            <App
              app={app}
              onChange={permissions => handleChange(app.crn, permissions)}
              onDelete={() => handleDelete(app.crn)}
            />
          ))}
          <NewApp onChange={handleAddApp} allApps={allApps} />
        </Layout.Container>
      </Core.Spinner>
    </Styled>
  );
};
