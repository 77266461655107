import menuLogo from './assets/menu-logo.svg';

export default {
  menuLogo,
  sidebarOptions: [
    { id: 'home', labelStringId: 'home', path: '/home', iconName: 'HomeOutlined', defaultFeature: true },
    {
      id: 'users',
      labelStringId: 'activeUsers',
      path: '/users',
      iconName: 'PersonOutlineOutlined',
    },
    {
      id: 'company',
      labelStringId: 'company',
      path: '/company',
      iconName: 'BusinessOutlined',
      permissions: ['*'],
    },
    {
      id: 'masterdata',
      labelStringId: 'master_data',
      path: '/masterdata',
      iconName: 'DataArrayOutlined',
      children: [
        { id: 'locations', labelStringId: 'locations', path: '/locations' },
        { id: 'products', labelStringId: 'products', path: '/products' },
        {
          id: 'product-types',
          labelStringId: 'productTypes',
          path: '/product-types',
        },
        {
          id: 'mixes',
          labelStringId: 'mixDesigns',
          path: '/mixes',
        },
        {
          id: 'mix-components',
          labelStringId: 'mixComponents',
          path: '/mix-components',
        },
        { id: 'concrete-classes', labelStringId: 'concreteClasses', path: '/concrete-classes' },
        { id: 'price-books', labelStringId: 'priceBooks', path: '/price-books' },
        { id: 'customers', labelStringId: 'customers', path: '/customers' },
        { id: 'contact-types', labelStringId: 'contactTypes', path: '/contact-types' },
        { id: 'contact-reasons', labelStringId: 'contactReasons', path: '/contact-reasons' },
        { id: 'carriers', labelStringId: 'carriers', path: '/carriers' },
        { id: 'vehicles', labelStringId: 'vehicles', path: '/vehicles' },
        { id: 'vehicle-types', labelStringId: 'vehicleTypes', path: '/vehicle-types' },
        { id: 'trailers', labelStringId: 'trailers', path: '/trailers' },
        { id: 'drivers', labelStringId: 'drivers', path: '/drivers' },
      ],
    },
    {
      id: 'roles',
      labelStringId: 'roles',
      path: '/roles',
      iconName: 'ManageAccountsOutlined',
    },
    {
      id: 'billing',
      labelStringId: 'billing',
      path: '/billing',
      iconName: 'PaymentsOutlined',
    },
  ],
};
