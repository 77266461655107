import styled from '@emotion/styled';
import { Localization } from 'connex-cds';

import styles from './styles';

const Styled = styled.div`
  ${styles}
`;

const TranslatedCell = ({ stringId = [] }) => {
  const translate = Localization.useTranslateMessage();

  const translatedMessage = Array.isArray(stringId) ? stringId.map(translate).join(', ') : translate(stringId);

  if (!stringId.length) return null;

  return (
    <Styled role="columnheader-text" title={translatedMessage}>
      {translatedMessage}
    </Styled>
  );
};

export default TranslatedCell;
