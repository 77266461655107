import * as yup from 'yup';
import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  AddCustomerProfile: 0,
};

export const validationSchema = yup.object().shape({
  id: yup.string().required('required'),
  name: yup.string().required('required'),
  status: yup.object().required('required'),
  streetAddress: yup.string().required('required'),
  complement: yup.string(),
  city: yup.string().required('required'),
  state: yup.string().required('required'),
  postalCode: yup.string().required('required'),
});

export const initialValues = {
  id: '',
  name: '',
  status: STATUS_OPTIONS[0],
  streetAddress: '',
  complement: '',
  city: '',
  state: '',
  postalCode: '',
};
