import { Core, Form } from 'connex-cds';
import React from 'react';
import ListAndDetailProvider, { useListAndDetailContext } from './ListAndDetailProvider';
import { View } from './View';
import { DefaultEditor } from './DefaultEditor';

export const DETAIL_TYPES = {
  DRAWER: 'drawer',
  VIEW: 'view',
  FULL_WIDTH_DRAWER: 'full_width_drawer',
  MODAL: 'modal',
};

const ListAndDetailForm = () => {
  const { config, data, isLoading, formConfig } = useListAndDetailContext();

  return (
    <Core.Spinner spin={isLoading || isLoading === null}>
      <Form.FormProvider config={formConfig} initialValues={{ [config.name || config.path || 'rows']: data }}>
        <View />
      </Form.FormProvider>
    </Core.Spinner>
  );
};

export const ListAndDetail = ({
  config,
  Editor = DefaultEditor,
  detailType,
  detailOptions,
  updateQuery,
  listQuery,
  createQuery,
  deleteQuery,
  pickerMode,
  context,
  onPick,
  showHeader,
}) => {
  return (
    <ListAndDetailProvider
      config={config}
      Editor={Editor}
      detailType={detailType}
      detailOptions={detailOptions}
      createQuery={createQuery}
      listQuery={listQuery}
      updateQuery={updateQuery}
      deleteQuery={deleteQuery}
      pickerMode={pickerMode}
      context={context}
      onPick={onPick}
      showHeader={showHeader}
    >
      <ListAndDetailForm />
    </ListAndDetailProvider>
  );
};
