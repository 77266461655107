import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { Localization } from 'connex-cds';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, InputAdornment, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { TextInputWithFormikField, SelectWithFormikField, SwitchWithFormikField } from '../../../../commons';
import { NUMBER_MAX_TWO_DECIMALS_REGEX } from '../../../../util/forms';
import { VAT_RATE_OPTION } from '../../constants';
import SwitchLabel from '../switch-label';
import { CustomIcon } from '../../../../commons';
import { validationSchema } from './form-config';
import { TaxFormStyled } from './styles';

const TaxForm = ({ initialValues, isLoading, taxProviderOptions, onSubmit }) => {
  const translateMessage = Localization.useTranslateMessage();

  const navigate = useNavigate();
  const [taxProvider, setTaxProvider] = useState({});

  useEffect(() => {
    if (initialValues?.taxProvider !== undefined) {
      setTaxProvider(initialValues.taxProvider);
    }
  }, [initialValues]);

  const handleGoBack = () => navigate('../billing', { replace: true });

  const handleChange = setFieldValue => (name, value) => {
    setTaxProvider(value);
    setFieldValue(name, value);
  };

  const handleAmountChange = setFieldValue => (name, value) => {
    if (value === '' || NUMBER_MAX_TWO_DECIMALS_REGEX.test(value)) {
      setFieldValue(name, value);
    }
  };

  return (
    <TaxFormStyled>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ isSubmitting, dirty, isValid, handleSubmit, setFieldValue }) => {
          return (
            <>
              <Stack className="tax-settings">
                <Field
                  className="tax-vat-input"
                  component={SelectWithFormikField}
                  customOnChange={handleChange(setFieldValue)}
                  disabled={isLoading}
                  labelStringId="billing_tax_provider_type"
                  name="taxProvider"
                  options={taxProviderOptions}
                  required
                  variant="outlined"
                />
                {isLoading ? (
                  <Skeleton variant="rectangular">
                    <Field name="taxPerProduct" />
                  </Skeleton>
                ) : (
                  <Field
                    component={SwitchWithFormikField}
                    name="taxPerProduct"
                    labelPlacement="start"
                    customLabel={<SwitchLabel />}
                  />
                )}
              </Stack>

              <Stack className="tax-vat-container">
                {taxProvider?.value === VAT_RATE_OPTION && (
                  <Field
                    className="tax-vat-input"
                    component={TextInputWithFormikField}
                    labelStringId="billing_vat_rate"
                    name="taxPercentage"
                    required
                    variant="outlined"
                    customOnChange={handleAmountChange(setFieldValue)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                )}
              </Stack>

              <Divider />

              <Stack className="buttons-container">
                <Button onClick={handleGoBack} className="back-button">
                  <CustomIcon iconName="arrowBack" />
                  <Typography>{translateMessage('general_dialogAction_back')}</Typography>
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting || !dirty || !isValid}
                  onClick={handleSubmit}
                >
                  <Typography>{translateMessage('general_dialogAction_save')}</Typography>
                </Button>
              </Stack>
            </>
          );
        }}
      </Formik>
    </TaxFormStyled>
  );
};

TaxForm.propTypes = {
  initialValues: PropTypes.object,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  taxProviderOptions: PropTypes.array,
};

export default TaxForm;
