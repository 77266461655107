import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DialogContent } from '@mui/material';

export const DialogContentStyled = styled(DialogContent)`
  ${({ theme }) => css`
    display: flex;
    overflow: hidden;
    padding: 20px 40px;

    ${theme.validateMode({ dark: 'border-top: 1px solid #938F99;' })}
    ${theme.validateMode({ dark: 'border-bottom: 1px solid #938F99;' })}
  `}
`;
