import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GridActionsCellItem } from '@mui/x-data-grid';

const ActionMenuItemStyles = css`
  width: 155px;
  gap: 16px;
  padding: 10px 24px 10px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;

  .MuiListItemIcon-root {
    min-width: 0;
  }
`;

export const KebabMenuStyled = styled(GridActionsCellItem)`
  ${ActionMenuItemStyles}
`;
