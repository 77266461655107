import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, string } from 'prop-types';

import { TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { PriceBookProfileContainer } from './styles';

const PriceBookProfileTab = ({ priceBookStatusOptions, mode }) => {
  return (
    <PriceBookProfileContainer>
      <h3 className="price-book-profile-title">
        <Localization.Translate stringId="priceBooks_profileTab_title" />
      </h3>
      <Form className="price-book-profile-form">
        <div className="first-column">
          <Field
            labelStringId="priceBooks_profileTab_priceBookId"
            component={TextInputWithFormikField}
            variant="outlined"
            name="priceBookId"
            tooltipInfoStringId="priceBooks_profileTab_priceBookId_tooltip"
            required
            disabled={mode === DIALOG_TYPES.EDIT}
          />
          <Field
            labelStringId="priceBooks_profileTab_priceBookName"
            component={TextInputWithFormikField}
            variant="outlined"
            name="priceBookName"
            required
          />
        </div>
        <div className="second-column">
          <Field
            labelStringId="priceBooks_profileTab_priceBookStatus"
            component={SelectWithFormikField}
            name="priceBookStatus"
            options={priceBookStatusOptions}
            infoTooltipStringId="priceBooks_profileTab_priceBookStatus_tooltip"
            testId="select-price-book-status"
            required
          />
        </div>
      </Form>
    </PriceBookProfileContainer>
  );
};

PriceBookProfileTab.propTypes = {
  priceBookStatusOptions: array.isRequired,
  mode: string.isRequired,
};

export default PriceBookProfileTab;
