import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS, BOOLEAN_VALUES } from '../../../../constants';
import { useLocations, useProductTypes } from '../../../../api/hooks';
import LocationFormView from '../location-form-view';
import { initialValues } from '../location-form-view/form-config';
import { parseProductTypesOptions } from '../../../../api/adapters';

const AddLocationDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { Create: useCreateLocation } = useLocations();
  const { mutateAsync: createLocation } = useCreateLocation();
  const { List: useListProductTypes } = useProductTypes();
  const { data: productTypesOptions, isLoading: isLoadingProductTypesOptions } = useListProductTypes({
    adapter: parseProductTypesOptions,
  });

  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      locationId,
      description,
      primaryProductType,
      locationName,
      status,
      streetAddress,
      complement,
      city,
      state,
      postalCode,
      trackInventory,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const data = {
        id: locationId,
        name: locationName,
        description: description,
        status: status.value,
        primaryProductType: primaryProductType.value,
        address: {
          address1: streetAddress,
          address2: complement,
          city,
          countrySubDivision: state,
          postalCode,
        },
        trackInventory: trackInventory ? BOOLEAN_VALUES.Yes : BOOLEAN_VALUES.No,
      };

      await createLocation({ data });
      notification.success(translateMessage('locations_addLocationsNotification_success_msg'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LocationFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      locationStatusOptions={STATUS_OPTIONS}
      productTypesOptions={productTypesOptions}
      isLoadingProductTypesOptions={isLoadingProductTypesOptions}
    />
  );
};

AddLocationDialog.propTypes = {
  onClose: func.isRequired,
};

AddLocationDialog.defaultProps = {
  onClose: () => {},
};

export default AddLocationDialog;
