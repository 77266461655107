import { Fragment } from 'react';
import { Field } from 'formik';
import { Localization } from 'connex-cds';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import { RadioGroupStyled, AccordionStyled } from './styles';

const RoleRadioGroup = ({ name, roleList = [] }) => {
  const handleAccordionChange = (value, setFieldValue) => (_, isExpanded) => {
    if (isExpanded) {
      setFieldValue(name, value);
    }
  };

  return (
    <Field name={name}>
      {({ field, form }) => (
        <RadioGroupStyled className="role-radio-group" {...field}>
          {roleList.map(({ crn, name, permissions }) => (
            <AccordionStyled
              key={crn}
              disableGutters
              expanded={field.value === crn}
              onChange={handleAccordionChange(crn, form.setFieldValue)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id="role-label">
                <FormControlLabel value={crn} disabled={form.isSubmitting} control={<Radio />} />
                {name}
              </AccordionSummary>
              <AccordionDetails>
                <ul className="role-apps">
                  {permissions &&
                    Object.keys(permissions).map(app => (
                      <Fragment key={app}>
                        <li>
                          <Localization.Translate stringId={app} />
                        </li>
                        <ul className="role-permissions">
                          {!!permissions[app].length &&
                            permissions[app].map(permission => (
                              <li key={permission}>
                                {permission === '*' ? (
                                  <Localization.Translate stringId="admin-permission" />
                                ) : (
                                  permission
                                )}
                              </li>
                            ))}
                        </ul>
                      </Fragment>
                    ))}
                </ul>
              </AccordionDetails>
            </AccordionStyled>
          ))}
        </RadioGroupStyled>
      )}
    </Field>
  );
};

export default RoleRadioGroup;
