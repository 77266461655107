import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const IconCellStyled = styled.div`
  ${({ theme }) => css`
    gap: 10px;
    display: flex;
    overflow: hidden;
    align-items: center;

    .icon {
      width: 30px;
      height: 30px;
    }

    .value {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `};
`;
