import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Button from '@mui/material/Button';

export const CustomButtonStyled = styled(Button)`
  ${({ theme }) => css`
    display: flex;
    align-item: center;
    gap: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 24px;
    color: ${theme.colorsCA.neutral[100]};
    background-color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[10], dark: theme.colorsCA.darkBlue[60] })};

    &:hover {
      background-color: ${theme.validateMode({
        light: theme.colorsCA.darkBlue[40],
        dark: theme.colorsCA.darkBlue[50],
      })};
    }
  `}
`;
