import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CategoryOutlined from '@mui/icons-material/CategoryOutlined';
import Delete from '@mui/icons-material/Delete';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DriveFileRenameOutlineRounded from '@mui/icons-material/DriveFileRenameOutlineRounded';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InventoryOutlined from '@mui/icons-material/InventoryOutlined';
import PriceChangeOutlined from '@mui/icons-material/PriceChangeOutlined';
import CodeOutlined from '@mui/icons-material/CodeOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import { LocalAtmOutlined, InfoOutlined, ArrowBack } from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as NonStarted } from '../../assets/icons/nonStarted.svg';
import { ReactComponent as Started } from '../../assets/icons/started.svg';
import { ReactComponent as Completed } from '../../assets/icons/completed.svg';
import CloseIcon from '@mui/icons-material/Close';

const iconNames = {
  addOutlinedIcon: props => <AddOutlinedIcon {...props} />,
  personAddAltOutlinedIcon: props => <PersonAddAltOutlinedIcon {...props} />,
  personOutlineIcon: props => <PersonOutlineIcon {...props} />,
  categoryOutlined: props => <CategoryOutlined {...props} />,
  deleteIcon: props => <Delete {...props} />,
  editOutlinedIcon: props => <EditOutlined {...props} />,
  driveFileRenameOutlineRounded: props => <DriveFileRenameOutlineRounded {...props} />,
  highlightOffOutlinedIcon: props => <HighlightOffOutlinedIcon {...props} />,
  inventoryOutlined: props => <InventoryOutlined {...props} />,
  priceChangeOutlined: props => <PriceChangeOutlined {...props} />,
  nonStarted: props => <SvgIcon inheritViewBox component={NonStarted} {...props} />,
  started: props => <SvgIcon inheritViewBox component={Started} {...props} />,
  completed: props => <SvgIcon inheritViewBox component={Completed} {...props} />,
  taxation: props => <LocalAtmOutlined {...props} />,
  codeOutlined: props => <CodeOutlined {...props} />,
  alert: props => <InfoOutlined {...props} />,
  arrowBack: props => <ArrowBack {...props} />,
  closeIcon: props => <CloseIcon {...props} />,
  businessOutlinedIcon: props => <BusinessOutlinedIcon {...props} />,
};
export default iconNames;
