import React, { useState, useEffect } from 'react';
import { any, func, string, bool, shape, array } from 'prop-types';
import { Localization } from 'connex-cds';
import { Autocomplete as MaterialAutocomplete, Checkbox } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox as CheckBoxIcon, Search, Close } from '@mui/icons-material';
import { omit } from 'lodash';
import { getIn } from 'formik';

import { withFormikField } from '../../hoc';

import { TextFieldStyled, ChipStyled, CustomTagStyled } from './styles';

const Autocomplete = ({
  name,
  value,
  disabled,
  testId,
  onChange,
  isSubmitting,
  labelStringId,
  errors,
  options,
  setFieldTouched,
  touched,
  ...otherProps
}) => {
  const [shrink, setShrink] = useState(false);
  const translateMessage = Localization.useTranslateMessage();

  useEffect(() => {
    if (value && value.length) setShrink(true);
  }, [value]);

  const handleOnChange = (_e, newValue) => {
    onChange(name, newValue);
  };

  const hasValue = Boolean(value && value.length);
  const hasError = !!getIn(errors, name) && getIn(touched, name);
  const errorText = hasError ? errors[name] : '';

  return (
    <MaterialAutocomplete
      name={name}
      value={value}
      onChange={handleOnChange}
      onClose={() => {
        setFieldTouched(name);
      }}
      disabled={disabled || isSubmitting}
      data-testid={testId}
      multiple
      options={options}
      disableCloseOnSelect
      getOptionLabel={option => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={`${option.value}-${option.label}`}>
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      renderInput={params => {
        return (
          <TextFieldStyled
            {...params}
            label={translateMessage(labelStringId)}
            error={hasError}
            variant="outlined"
            helperText={translateMessage(errorText)}
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <ChipStyled tabIndex={-1} icon={<Search />} />
                  <> {params.InputProps.startAdornment} </>
                </>
              ),
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
            onFocus={() => setShrink(true)}
            onBlur={e => {
              setShrink(!!e.target.value || hasValue);
              setFieldTouched(name);
            }}
            InputLabelProps={{ shrink }}
          />
        );
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((tag, index) => (
          <CustomTagStyled {...getTagProps({ index })} label={tag.label} deleteIcon={<Close />} />
        ))
      }
      {...omit(otherProps, ['setFieldTouched', 'setFieldValue'])}
    />
  );
};

Autocomplete.propTypes = {
  errors: shape({}).isRequired,
  disabled: bool,
  isSubmitting: bool,
  labelStringId: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  options: array.isRequired,
  testId: string.isRequired,
  value: any.isRequired,
  setFieldTouched: func.isRequired,
  touched: shape({}).isRequired,
};

Autocomplete.defaultProps = {
  disabled: false,
  options: [],
  isSubmitting: false,
};

export default withFormikField(Autocomplete);
