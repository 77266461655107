import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TableControlsStyled = styled.section`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-left: 6px;
  `}
`;
