import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { Localization } from 'connex-cds';

import CustomStepIcon from './CustomStepIcon';
import { StepConnectorStyled, StepLabelStyled } from './styles';

const Stepper = ({ steps = [], activeStep = 0, onStepClick }) => {
  const handleStep = index => () => {
    if (onStepClick) {
      onStepClick(index);
    }
  };

  return (
    <MuiStepper activeStep={activeStep} orientation="vertical" connector={<StepConnectorStyled />}>
      {steps.map((step, index) => (
        <Step key={step.stringId} {...step.stepProps}>
          <StepLabelStyled StepIconComponent={CustomStepIcon} {...step.labelProps} onClick={handleStep(index)}>
            <Localization.Translate stringId={step.stringId} />
          </StepLabelStyled>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;
