import { http } from 'connex-cds';

export default {
  listTrailers: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/trailers`, queryParams }),
  createTrailer: ({ entityRef, trailer }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/trailers`,
      data: trailer,
    }),
  updateTrailer: ({ entityRef, trailerRef, trailer }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/trailers/${trailerRef}`, data: trailer }),
};
