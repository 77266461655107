import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const LocationsAvailableCellStyled = styled.span`
  ${({ theme }) => css`
    .highlighted-text {
      color: ${theme.colorsCA.blue[60]};
      cursor: pointer;
    }
  `}
`;
