import { Field, Form } from 'formik';
import { Localization } from 'connex-cds';
import { TextInputWithFormikField } from '../../../../commons';

import { ContainerStyled } from './styles';

const ContactInfoTab = () => {
  return (
    <ContainerStyled className="contact-info">
      <h3 className="contact-info-title">
        <Localization.Translate stringId="addUserDialog_contactInfoTab_title" />
      </h3>
      <p className="contact-info-description">
        <Localization.Translate stringId="addUserDialog_contactInfoTab_description" />
      </p>
      <Form className="contact-info-form">
        <Field
          testId="addUser-username"
          labelStringId="addUserDialog_contactInfoTab_username"
          component={TextInputWithFormikField}
          type="email"
          variant="outlined"
          name="username"
          required
        />
      </Form>
    </ContainerStyled>
  );
};

export default ContactInfoTab;
