import * as yup from 'yup';
import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  AddMixProfile: 0,
};

export const validationSchema = yup.object().shape({
  mixDesignId: yup.string().required('required'),
  mixDesignName: yup.string().required('required'),
  mixDesignStatus: yup.object().required('required'),
  maxBatchSize: yup.number().required('required'),
  maxBatchUom: yup.object().required('required'),
  mixFormulaId: yup.string().required('required'),
});

export const initialValues = {
  mixDesignId: '',
  mixDesignName: '',
  mixDesignStatus: STATUS_OPTIONS[0],
  maxBatchSize: 0,
  maxBatchUom: '',
  mixFormulaId: '',
};
