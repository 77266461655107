import { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { Localization, useNotification } from 'connex-cds';

import { ConfirmationDialog } from '../../../../commons';
import { useContactReasons } from '../../../../api/hooks';
import { DELETED_STATUS } from '../../../../constants';
import { trimWithDots } from '../../../../util/strings';

const DeleteContactReasonDialog = ({ onClose, contactReasonData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Update: useUpdateContactReason } = useContactReasons();
  const { mutateAsync: updateContactReason } = useUpdateContactReason();

  const handleDelete = async () => {
    try {
      if (contactReasonData?.crn) {
        await updateContactReason({
          data: { status: DELETED_STATUS },
          contactReasonRef: contactReasonData.crn,
        });
        notification.success(translateMessage('contactReasons_notification_contactReasonDeleted'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmText="delete"
      color="delete"
    >
      <h3 className="delete-title">
        <Localization.Translate stringId="contactReasons_deleteDialog_title" />
      </h3>
      <p className="delete-description">
        <Localization.Translate
          stringId="contactReasons_deleteDialog_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: contactReasonData?.name,
          }}
        />
      </p>
    </ConfirmationDialog>
  );
};

DeleteContactReasonDialog.propTypes = {
  onClose: func.isRequired,
  contactReasonData: shape({
    crn: string.isRequired,
    name: string.isRequired,
  }),
};

export default DeleteContactReasonDialog;
