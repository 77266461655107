import { useQuery } from '@tanstack/react-query';
import { users } from '../api/queries';
import { useParams } from 'react-router';

export const useProfile = profileRef => {
  const { entityRef } = useParams();
  return useQuery({
    queryKey: ['profile', profileRef],
    queryFn: () => users.getUserProfile({ profileRef, entityRef }),
    refetchOnWindowFocus: false,
  });
};
