export const DIALOG_TYPES = Object.freeze({
  DELETE: 'delete',
  EDIT: 'edit',
  ADD: 'add',
  NONE: null,
});

export const STATUS_OPTIONS = [
  { value: 'ACTIVE', translationKey: 'active' },
  { value: 'INACTIVE', translationKey: 'inactive' },
];

export const DELETED_STATUS = 'DELETED';

export const BOOLEAN_VALUES = {
  Yes: 'YES',
  No: 'NO',
};
