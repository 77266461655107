import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, shape, string } from 'prop-types';

import ContactReasonFormView from '../contact-reason-form-view';
import { useContactReasons } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';

const EditContactReasonDialog = ({ onClose, contactReasonData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Update: useUpdateContactReason } = useContactReasons();
  const { mutateAsync: updateContactReason } = useUpdateContactReason();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { contactReasonName, contactReasonStatus } = values;

    formikBag.setSubmitting(true);

    try {
      const ContactReason = {
        name: contactReasonName,
        status: contactReasonStatus.value,
      };
      await updateContactReason({ data: ContactReason, contactReasonRef: contactReasonData.crn });
      notification.success(translateMessage('notification_changesSaved'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!contactReasonData) return null;
    const { id, name } = contactReasonData;

    const values = {
      contactReasonId: id,
      contactReasonName: name,
      contactReasonStatus: STATUS_OPTIONS.find(status => status.value === contactReasonData.status),
    };
    return values;
  }, [contactReasonData]);

  return (
    <ContactReasonFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

EditContactReasonDialog.propTypes = {
  handleOnDelete: func,
  onClose: func.isRequired,
  contactReasonData: shape({
    id: string.isRequired,
    name: string.isRequired,
    status: string,
  }),
};

EditContactReasonDialog.defaultProps = {
  onClose: () => {},
  contactReasonData: {},
};

export default EditContactReasonDialog;
