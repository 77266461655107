import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MuiAccordion from '@mui/material/Accordion';
import RadioGroup from '@mui/material/RadioGroup';

export const RadioGroupStyled = styled(RadioGroup)`
  ${({ theme }) => css`
    padding: 0 20px;
    border-radius: 12px;
    border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#49454F' })};
    box-shadow: ${theme.validateMode({
      light: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
      dark: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    })};

    .role-apps {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > li {
        display: flex;
        align-items: center;
        height: 47px;
        font-weight: 600;
        padding-bottom: 10px;
        letter-spacing: 0.5px;
      }
    }

    .role-permissions {
      display: grid;
      width: fit-content;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 25px;
      row-gap: 20px;

      & > li {
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }

    .role-apps,
    .role-permissions {
      font-size: 16px;
      line-height: 24px;
      list-style: none;
      padding: 0;
      margin: 0;
    }
  `}
`;

export const AccordionStyled = styled(MuiAccordion)`
  ${({ theme }) => css`
    box-shadow: none;
    background: transparent;

    &:before {
      display: none;
    }

    .MuiAccordionSummary-root {
      padding: 0;
      border-bottom: 1px solid ${theme.colorsCA.neutral[90]};
    }

    &:last-child:not(.Mui-expanded) .MuiAccordionSummary-root {
      border-bottom: none;
    }

    .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    .MuiAccordionDetails-root {
      padding: 10px 0;
    }
  `}
`;
