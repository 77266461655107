import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, object } from 'prop-types';

import { useVehicles, useVehicleTypes, useLocations, useCarriers } from '../../../../api/hooks';
import { parseVehicleTypesOptions, parseLocations, parseCarriersOptions } from '../../../../api/adapters';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import VehicleFormView from '../vehicle-form-view';

const EditVehicleDialog = ({ onClose, vehicleData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Update: useUpdateVehicle } = useVehicles();
  const { mutateAsync: updateVehicle } = useUpdateVehicle();
  const { List: useListVehicleTypes } = useVehicleTypes();
  const { data: vehicleTypesOptions, isLoading: isLoadingVehicleTypesOptions } = useListVehicleTypes({
    adapter: parseVehicleTypesOptions,
  });
  const { List: useListLocations } = useLocations();
  const { data: locationsOptions, isLoading: isLoadingLocationsOptions } = useListLocations({
    adapter: parseLocations,
  });
  const { List: useListCarriers } = useCarriers();
  const { data: carriersOptions, isLoading: isLoadingCarriersOptions } = useListCarriers({
    adapter: parseCarriersOptions,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { vehicleName, vehicleStatus, carrier, vehicleType, vehicleHomeLocation } = values;

    formikBag.setSubmitting(true);

    try {
      const vehicle = {
        name: vehicleName,
        status: vehicleStatus.value,
        homeLocation: { locationRef: vehicleHomeLocation.value },
        carrierParty: { carrierRef: carrier.value },
        vehicleType: { vehicleTypeRef: vehicleType.value },
      };

      await updateVehicle({ vehicle, vehicleRef: vehicleData?.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!vehicleData) return null;
    const { id, name, carrierParty, vehicleType, homeLocation } = vehicleData;

    const editValues = {
      vehicleId: id,
      vehicleName: name,
      vehicleStatus: STATUS_OPTIONS.find(status => status.value === vehicleData.status),
      carrier: carriersOptions.find(item => item.value === carrierParty?.carrierRef) || '',
      vehicleType: vehicleTypesOptions.find(item => item.value === vehicleType?.vehicleTypeRef) || '',
      vehicleHomeLocation: locationsOptions.find(item => item.value === homeLocation?.locationRef) || '',
    };

    return editValues;
  }, [carriersOptions, locationsOptions, vehicleData, vehicleTypesOptions]);

  return (
    <VehicleFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      vehicleTypesOptions={vehicleTypesOptions}
      isLoadingVehicleTypesOptions={isLoadingVehicleTypesOptions}
      locationsOptions={locationsOptions}
      isLoadingLocationsOptions={isLoadingLocationsOptions}
      carriersOptions={carriersOptions}
      isLoadingCarriersOptions={isLoadingCarriersOptions}
      handleOnDelete={handleOnDelete}
    />
  );
};

EditVehicleDialog.propTypes = {
  vehicleData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
};

EditVehicleDialog.defaultProps = {
  onClose: () => {},
};

export default EditVehicleDialog;
