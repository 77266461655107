import PropTypes from 'prop-types';
import { Localization } from 'connex-cds';
import { CardContent, CardActions, Stack, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { CustomIcon } from '../../../../commons';
import { STATE_TEXT } from './constants';
import CardSettingStyled from './styles';

const CardSetting = ({ state, title, icon, description }) => {
  const translateMessage = Localization.useTranslateMessage();
  const navigate = useNavigate();

  const handleClick = () => navigate('taxation');

  return (
    <CardSettingStyled>
      <CardContent>
        <Stack className="header">
          <CustomIcon iconName={icon} className="title-icon" />
          <Stack>
            <Typography>{translateMessage(title)}</Typography>
            {/* COMMENTED UNTIL FEATURE IS REQUIRED */}
            {/* <Stack className="state-info">
              <CustomIcon iconName={STATE_TEXT[state].icon} />
              <Typography>{translateMessage(STATE_TEXT[state].text)}</Typography>
            </Stack> */}
          </Stack>
        </Stack>
        <Typography className="description">{translateMessage(description)}</Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" onClick={handleClick}>
          {translateMessage(STATE_TEXT[state].button)}
        </Button>
      </CardActions>
    </CardSettingStyled>
  );
};

CardSetting.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  state: PropTypes.string,
  title: PropTypes.string,
};

export { CardSetting };
