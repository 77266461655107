import colors from '../colors';

const lightOverrides = {
  MuiTabs: {
    styleOverrides: {
      root: {
        '& .MuiTabs-indicator': {
          backgroundColor: colors.blue[60],
          height: '3px',
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        color: colors.neutral[40],
        '&.Mui-selected': {
          color: colors.blue[60],
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: '100px',
      },
    },
    variants: [
      {
        props: { variant: 'text' },
        style: {
          padding: '10px 24px',

          '&:hover': {
            background: colors.surface.light[1],
          },
          '&:focus': {
            background: colors.surface.light[2],
          },
          '&:active': {
            background: colors.surface.light[3],
          },
          '&:disabled': {
            color: colors.neutral[10],
            opacity: 0.38,
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          color: colors.darkBlue[30],
          border: `1px solid ${colors.neutral[40]}`,
        },
      },
    ],
  },
  MuiInputLabel: {
    defaultProps: {
      sx: {
        fontSize: 16,
        top: -4,
      },
    },
    styleOverrides: {
      shrink: {
        top: '0 !important',
        color: colors.neutral[10],
        fontWeight: 600,
        '&.Mui-focused': {
          color: colors.darkBlue[50],
        },
        '&.Mui-error': {
          color: colors.red[50],
        },
        '&.Mui-disabled': {
          color: colors.neutral[80],
        },
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      sx: {
        fontSize: 16,
      },
    },
    styleOverrides: {
      root: {
        '&.Mui-focused': {
          '.MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${colors.darkBlue[50]}`,
          },
        },
        '&.Mui-error': {
          '.MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${colors.red[50]}`,
          },
        },
        '&.Mui-disabled': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: colors.neutral[90],
          },
        },
      },
      notchedOutline: {
        border: `1px solid ${colors.neutral[70]}`,
        borderRadius: '4px',
      },
      input: {
        height: 40,
        padding: '4px 16px',
        paddingRight: 0,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
      },
    },
  },
};

export default lightOverrides;
