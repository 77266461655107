import React from 'react';
import { Localization } from 'connex-cds';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import WelcomeImage from '../../../../assets/welcome.svg';

import { StyledDialog, StyledDialogContent } from './styles';

const WelcomeDialog = ({ open, onClose }) => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <StyledDialog className="welcome-dialog" open={open} maxWidth="md" fullWidth scroll="paper">
      <DialogTitle className="welcome-dialog__title">
        <AutoAwesomeIcon fontSize="inherit" />
        <Localization.Translate stringId="home_welcomeDialog_title" />
      </DialogTitle>
      <StyledDialogContent className="welcome-dialog__content" dividers>
        <img src={WelcomeImage} alt="Welcome" />
        <div className="welcome-dialog__content-message">
          <h3>{translateMessage('home_welcomeDialog_contentTitle')}</h3>
          <p>
            Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis
            tellus. Sed dignissim, metus nec fringilla accumsa
          </p>
          <h4>{translateMessage('home_welcomeDialog_contentSubTitle')}</h4>
          <ul>
            <li>
              <span>Etiam eu turpis molestie, dictum est.</span>
            </li>
            <li>Sed dignissim, metus nec fringilla.</li>
            <li>Morem ipsum dolor sit amet, consectetur.</li>
          </ul>
        </div>
      </StyledDialogContent>
      <DialogActions className="welcome-dialog__actions">
        <Button onClick={onClose} variant="contained">
          <Localization.Translate stringId="home_welcomeDialog_next" />
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default WelcomeDialog;
