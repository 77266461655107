import { useState, useMemo, useCallback } from 'react';
import { Localization } from 'connex-cds';

import { useDrivers } from '../../api/hooks';
import { getDriversListColumns } from './tableConfig';
import { TableLayout, EntityTable, SearchFilter, DialogManager, ActionButton } from '../../commons';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { DIALOG_TYPES } from '../../constants';
import AddDriverDialog from './components/add-driver-dialog';
import EditDriverDialog from './components/edit-driver-dialog';
import DeleteDriverDialog from './components/delete-driver-dialog';

const Drivers = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [query, setQuery] = useState('');
  const [currentDriver, setCurrentDriver] = useState(null);
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const { List: useDriversList } = useDrivers();
  const { data: drivers, isLoading } = useDriversList({ queryParams: { activeOnly: false } });

  const filteredData = useMemo(
    () =>
      getFilteredDataByProperties({
        data: drivers,
        query,
        properties: ['id', 'firstName', 'lastName', 'mobile', 'phone', 'email'],
      }),
    [drivers, query]
  );

  const setDelete = useCallback(
    driver => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentDriver(driver);
    },
    []
  );

  const driversColumns = useMemo(
    () => getDriversListColumns({ setDeleteDriver: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setCurrentDriver(null);
    setDialog(DIALOG_TYPES.NONE);
  };

  const handleOnRowClick = params => {
    setCurrentDriver(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="drivers_addDriver_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={driversColumns}
          rows={filteredData}
          getRowId={row => row.crn}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddDriverDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditDriverDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          onClose={resetDialogs}
          driverData={currentDriver}
        />
        <DeleteDriverDialog identifier={DIALOG_TYPES.DELETE} onClose={resetDialogs} driverData={currentDriver} />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default Drivers;
