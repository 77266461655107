import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Theme } from 'connex-cds';
import { createTheme } from '@mui/material/styles';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import { Global } from '@emotion/react';

import getDesignConfig from '../../theme/modes/config';
import GlobalStyles from './styles';

const ROUTES_WITH_BG = ['home', 'billing', 'taxation', 'company'];

const ThemeProvider = ({ children }) => {
  const { themeName } = Theme.useThemeContext();
  const { pathname } = useLocation();

  const theme = useMemo(() => createTheme(getDesignConfig(themeName)), [themeName]);
  const currentPath = pathname.slice(1).split('/');
  const hasBackground = useMemo(() => ROUTES_WITH_BG.includes(currentPath[currentPath.length - 1]), [currentPath]);
  const globalStyles = GlobalStyles({ theme: theme, hasBackground });

  return (
    <MuiThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
