import { useMemo, useState, useCallback } from 'react';
import { Localization } from 'connex-cds';

import { useVehicleTypes } from '../../api/hooks';
import { getVehicleTypesColumns } from './tableConfig';
import { TableLayout, EntityTable, SearchFilter, ActionButton, DialogManager } from '../../commons';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { DIALOG_TYPES } from '../../constants';
import AddVehicleTypeDialog from './components/add-vehicle-type-dialog';
import EditVehicleTypeDialog from './components/edit-vehicle-type-dialog';
import DeleteVehicleTypeDialog from './components/delete-vehicle-type-dialog';

const VehicleTypes = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [query, setQuery] = useState('');
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const [currentVehicleType, setCurrentVehicleType] = useState(null);
  const { data, isLoading } = useVehicleTypes().List({ activeOnly: false });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data: data, query, properties: ['id', 'name'] }),
    [data, query]
  );

  const setDelete = useCallback(
    vehicleType => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentVehicleType(vehicleType);
    },
    []
  );

  const columns = useMemo(
    () => getVehicleTypesColumns({ setDeleteVehicleType: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setDialog(DIALOG_TYPES.NONE);
    setCurrentVehicleType(null);
  };

  const handleOnRowClick = params => {
    setCurrentVehicleType(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="vehicleTypes_addVehicleType_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={columns}
          rows={filteredData}
          getRowId={row => row.crn}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddVehicleTypeDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditVehicleTypeDialog
          identifier={DIALOG_TYPES.EDIT}
          onClose={resetDialogs}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          vehicleTypeData={currentVehicleType}
        />
        <DeleteVehicleTypeDialog
          identifier={DIALOG_TYPES.DELETE}
          onClose={resetDialogs}
          vehicleTypeData={currentVehicleType}
        />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default VehicleTypes;
