import React, { useState, useMemo, useCallback } from 'react';
import { Localization, useNotification } from 'connex-cds';

import DeleteUserDialog from './components/delete-user-dialog/DeleteUserDialog';
import EntityTable from '../../commons/entity-table';
import { useUsers, useConnexions, useRoles } from '../../api/hooks';
import { getUserListColumns } from './tableConfig';
import DetailsDialog from './components/details-dialog/DetailsDialog';
import AddUserDialog from './components/add-user-dialog';
import { SearchFilter } from '../../commons';
import ActionButton from '../../commons/action-button/ActionButton';
import { TableLayout } from '../../commons';

const Users = () => {
  const [currentUserProfileRef, setCurrentUserProfileRef] = useState(null);
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { List: useUsersList } = useUsers();
  const { data: users, isLoading: areUsersLoading } = useUsersList({ queryParams: { activeOnly: false } });
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Delete: useConnexionDelete } = useConnexions();
  const { mutateAsync: deleteConnexion } = useConnexionDelete();

  /**
   * TODO: The purpose is to preload data in the parent. This data can be loaded
   * when entity changes as an improvement.
   */
  const { List: useRolesList } = useRoles();
  const { data: roleList } = useRolesList();

  const handleRowClick = params => {
    setCurrentUserProfileRef(params.row.profileRef);
  };

  const handleAddUserClick = () => {
    setOpenAddUserDialog(true);
  };

  const handleCloseDetailsDialog = ({ success }) => {
    setCurrentUserProfileRef(null);
    if (success) {
      notification.success(translateMessage('notification_editSaved'));
    }
  };

  const handleCloseAddUserDialog = () => {
    setOpenAddUserDialog(false);
  };

  const setUserDelete = useCallback(
    userData => () => {
      setDeleteUser(userData);
    },
    []
  );

  const closeDeleteUserDialog = () => {
    setDeleteUser(null);
  };

  const handleDeleteUser = async () => {
    try {
      if (deleteUser?.connexionRef) {
        await deleteConnexion(deleteUser.connexionRef);
        notification.success(translateMessage('notification_userDeleted'));
      }
    } catch (error) {
      // TODO:: handle error flow
      console.error(error);
    }
  };

  const filteredData = useMemo(() => {
    if (searchTerm) {
      const searchTermToLower = searchTerm.toLowerCase();
      const newData = users.filter(user => {
        const { email, firstName, lastName } = user;
        const validEmail = email ? email.toLowerCase() : '';
        const fullName = `${firstName || ''} ${lastName || ''}`.toLowerCase();

        return validEmail.includes(searchTermToLower) || fullName.includes(searchTermToLower);
      });
      return newData;
    }

    return !users || !users?.length ? [] : users;
  }, [users, searchTerm]);

  const currentUserData = filteredData.find(({ profileRef }) => profileRef === currentUserProfileRef);
  const userListColumns = useMemo(() => getUserListColumns({ setUserDelete }), [setUserDelete]);

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setSearchTerm} disabled={areUsersLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="personAddAltOutlinedIcon"
          label={<Localization.Translate stringId="userList_addUser" />}
          variant="outlined"
          onClick={handleAddUserClick}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          getRowId={row => row.profileRef}
          rows={filteredData}
          columns={userListColumns}
          onRowClick={handleRowClick}
          isLoading={areUsersLoading}
        />
        {!areUsersLoading && !!currentUserProfileRef && (
          <DetailsDialog
            open={!!currentUserProfileRef}
            onClose={handleCloseDetailsDialog}
            profileRef={currentUserProfileRef}
            userData={currentUserData}
          />
        )}
        <DeleteUserDialog
          userData={deleteUser}
          open={!!deleteUser}
          onClose={closeDeleteUserDialog}
          onConfirm={handleDeleteUser}
        />
        <AddUserDialog open={openAddUserDialog} onClose={handleCloseAddUserDialog} roleList={roleList} />
      </TableLayout.TableBody>
    </TableLayout.ListLayoutContainer>
  );
};

export default Users;
