import { useMemo, useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, shape, string } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS, BOOLEAN_VALUES } from '../../../../constants';
import { useLocations, useProductTypes } from '../../../../api/hooks';
import LocationFormView from '../location-form-view';
import { parseProductTypesOptions } from '../../../../api/adapters';

const EditLocationDialog = ({ onClose, locationData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { Update: useUpdateLocation } = useLocations();
  const { mutateAsync: updateLocation } = useUpdateLocation();
  const { List: useListProductTypes } = useProductTypes();
  const { data: productTypesOptions, isLoading: isLoadingProductTypesOptions } = useListProductTypes({
    adapter: parseProductTypesOptions,
  });

  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      description,
      primaryProductType,
      locationName,
      status,
      streetAddress,
      complement,
      city,
      state,
      postalCode,
      trackInventory,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const data = {
        name: locationName,
        description: description,
        status: status.value,
        primaryProductType: primaryProductType.value,
        address: {
          address1: streetAddress,
          address2: complement,
          city,
          countrySubDivision: state,
          postalCode,
        },
        trackInventory: trackInventory ? BOOLEAN_VALUES.Yes : BOOLEAN_VALUES.No,
      };

      await updateLocation({ data, locationRef: locationData.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!locationData) return null;

    const { id, name, description, primaryProductType, status, address, trackInventory } = locationData;

    const values = {
      locationId: id,
      locationName: name || '',
      description: description || '',
      primaryProductType: productTypesOptions?.find(item => item?.value === primaryProductType) || '',
      status: STATUS_OPTIONS.find(item => item.value === status) || '',
      streetAddress: address?.address1 || '',
      complement: address?.address2 || '',
      city: address?.city || '',
      state: address?.countrySubDivision || '',
      postalCode: address?.postalCode || '',
      trackInventory: trackInventory === BOOLEAN_VALUES.Yes,
    };

    return values;
  }, [locationData, productTypesOptions]);

  return (
    <LocationFormView
      handleOnDelete={handleOnDelete}
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      locationStatusOptions={STATUS_OPTIONS}
      productTypesOptions={productTypesOptions}
      isLoadingProductTypesOptions={isLoadingProductTypesOptions}
    />
  );
};

EditLocationDialog.propTypes = {
  onClose: func.isRequired,
  handleOnDelete: func,
  locationData: shape({
    crn: string.isRequired,
    id: string.isRequired,
    name: string.isRequired,
    description: string,
    primaryProductType: string,
    status: string.isRequired,
    address: shape({}),
  }),
};

EditLocationDialog.defaultProps = {
  onClose: () => {},
  locationData: {},
};

export default EditLocationDialog;
