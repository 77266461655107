import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, string, func } from 'prop-types';
import { Grid } from '@mui/material';

import { TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { ContactTypeProfileContainer } from './styles';

const ContactTypeProfileTab = ({ statusOptions, mode }) => {
  return (
    <ContactTypeProfileContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="contactTypes_profileTab_title" />
      </h3>
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="contactTypes_profileTab_contactTypeId"
              component={TextInputWithFormikField}
              variant="outlined"
              name="contactTypeId"
              tooltipInfoStringId="contactTypes_profileTab_contactTypesId_tooltip"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="contactTypes_profileTab_contactTypeName"
              component={TextInputWithFormikField}
              variant="outlined"
              name="contactTypeName"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="contactTypes_profileTab_contactTypeStatus"
              component={SelectWithFormikField}
              name="contactTypeStatus"
              options={statusOptions}
              infoTooltipStringId="contactTypes_profileTab_contactTypeStatus_tooltip"
              variant="outlined"
              testId="select-contact-types-status"
              required
            />
          </Grid>
        </Grid>
      </Form>
    </ContactTypeProfileContainer>
  );
};

ContactTypeProfileTab.propTypes = {
  statusOptions: array.isRequired,
  mode: string.isRequired,
  setFieldValue: func.isRequired,
};

export default ContactTypeProfileTab;
