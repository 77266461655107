import { useCallback, useState, useMemo } from 'react';
import { Localization } from 'connex-cds';

import { usePriceBooks } from '../../api/hooks';
import { TableLayout, DialogManager, EntityTable, SearchFilter, ActionButton } from '../../commons';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { DIALOG_TYPES } from '../../constants';
import AddPriceBookDialog from './components/add-price-book-dialog';
import EditPriceBookDialog from './components/edit-price-book-dialog';
import DeletePriceBookDialog from './components/delete-price-book-dialog';
import { getPriceBooksListColumns } from './tableConfig';

const PriceBooks = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [query, setQuery] = useState('');
  const [currentPriceBook, setCurrentPriceBook] = useState(null);
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const { List: usePriceBooksList } = usePriceBooks();
  const { data: priceBooks, isLoading } = usePriceBooksList({ queryParams: { activeOnly: false } });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data: priceBooks, query, properties: ['id', 'name'] }),
    [priceBooks, query]
  );

  const setDelete = useCallback(
    priceBook => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentPriceBook(priceBook);
    },
    []
  );

  const priceBooksColumns = useMemo(
    () => getPriceBooksListColumns({ setDeletePriceBook: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setCurrentPriceBook(null);
    setDialog(DIALOG_TYPES.NONE);
  };

  const handleOnRowClick = params => {
    setCurrentPriceBook(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="priceBooks_addPriceBook_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={priceBooksColumns}
          rows={filteredData}
          getRowId={row => row.crn}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddPriceBookDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditPriceBookDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          onClose={resetDialogs}
          priceBookData={currentPriceBook}
        />
        ;
        <DeletePriceBookDialog
          identifier={DIALOG_TYPES.DELETE}
          onClose={resetDialogs}
          priceBookData={currentPriceBook}
        />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default PriceBooks;
