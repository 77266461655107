import { http } from 'connex-cds';

export default {
  listContactTypes: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/contact-types`, queryParams }),
  createContactType: ({ entityRef, contactType }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/contact-types`,
      data: contactType,
    }),
  updateContactType: ({ entityRef, contactTypeRef, contactType }) =>
    http.patch({
      apiName: 'company-admin',
      path: `/${entityRef}/contact-types/${contactTypeRef}`,
      data: contactType,
    }),
};
