import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,

  selectCompany: 'Select Company',
  companies: 'Companies',
  profiles: 'Profiles',
  apps: 'Apps',
  roles: 'Roles',
  createRole: 'Create Role',
  permissions: 'Permissions',
  status: 'Status',
  services: 'Services',
  showAll: 'Show All',
  hideAll: 'Hide All',
  undoChanges: 'Undo Changes',
  resetColumns: 'Reset Columns',
  all: 'All',
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  yes: 'YES',
  no: 'NO',

  displayRowCount: `Displaying {count, plural,
    =0 {0 rows}
    one {# row}
    other {# rows}
  }`,

  columnFilters: 'Column Filters',

  type: 'Type',
  id: 'ID',
  hostname: 'Hostname',
  name: 'Name',
  apiKey: 'API Key',
  origin: 'Origin',
  servicesListHeader: 'Services',
  profilesListHeader: 'Profiles',
  'add-services': 'Add Service',
  'add-profiles': 'Add Profile',
  servicesEditor: 'Service Editor',
  profileEditor: 'Profile Editor',

  add: 'Add',
  cancel: 'Cancel',

  crn: 'CRN',
  timeZone: 'Time Zone',
  modifyDate: 'Last Modified',
  entitiesListHeader: 'Companies',
  'add-entities': 'Add Company',
  entitiesEditor: 'Company Editor',

  family_name: 'Last Name',
  given_name: 'First Name',
  email: 'Email',
  verified: 'Verified',
  createDate: 'Registered On',
  'custom:eulaVersion': 'EULA Version',
  'custom:privacyVersion': 'Privacy Version',
  enabled: 'Enabled',

  notAuthorized: 'Not Authorized',

  general: 'General',
  general_cancel: 'Cancel',
  isTestEntity: 'Test Company',
  password: 'Password',
  createProfile: 'Create Profile',
  createEntity: 'Create Company',
  users: 'Users',
  profilesEditor: 'Profiles Editor',
  delete: 'Delete',
  update: 'Update',

  save: 'Save',
  visible: 'Visible',
  hidden: 'Hidden',
  gridPreferences: 'Grid Preferences',
  partners: 'Partners',
  'add-partners': 'Add Partner',
  partnersListHeader: 'Partners',
  partnersEditor: 'Partners Editor',

  register: 'Register',
  registerInstructions: 'Please fill out this form to finalize your registration.',

  code: 'Verification Code',
  username: 'Username',
  familyName: 'Last Name',
  givenName: 'First Name',
  phoneNumber: 'Phone Number',
  verifyPassword: 'Verify Password',

  'password-requirements':
    'Your password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.',

  required: 'Required',
  general_minimumZero: 'Min value is 0',
  general_tooSmallValue: 'Too small value',
  general_select_clearSelection: 'Clear selection',

  success: 'Success!',
  'registration-success': 'Your registration was successful.',
  'post-registration-instructions': "You've completed the registration process. You may now log in.",

  'admin-permission': 'Administrator',
  'app-permissions': 'App Permissions',

  'company-admin': 'Company Admin',
  'billing-exp': 'Billing',
  'dashboards-exp': 'Dashboards',
  assurance: 'Assurance',
  'cxp-admin': 'CP Admin',
  mt: 'Deliveries',
  admin: 'Platform Admin',
  support: 'Platform Support',

  activeUsers: 'Active Users',
  usersTable_header_displayName: 'Display Name',
  usersTable_header_email: 'Email',
  usersTable_header_role: 'Role',
  usersTable_header_products: 'Products',
  activeUser_searchFilterPlaceholder: 'Search',
  searchFilter_resultsCount: 'Displaying {count, plural, =0 {0 records} one {# record} other {# records}}',

  home: 'Home',
  home_welcomeDialog_next: 'Next',
  home_welcomeDialog_title: 'Welcome',
  home_welcomeDialog_contentTitle: 'Admin Onboarding',
  home_welcomeDialog_contentSubTitle: 'Includes',
  home_header_title: 'Welcome to',
  home_header_content:
    'Congratulations, you have licensed our Dispatch Module! When you’re ready, press the blue button below to start your configuration.',
  home_filters_button: 'Manage Configurations',
  home_filters_tooltip: 'Click “Manage Configurations” to see an overview of your setup process',
  home_cards_chip: 'New',
  home_cards_button_learnMore: 'Learn More',
  home_cards_button_buy: 'Buy',
  home_cards_button_try: 'Try',
  home_cards_button_activate: 'Activate',

  userDetailsDialog_resetPassword: 'Reset password',
  userDetailsDialog_deleteUSer: 'Delete user',
  userDetailsDialog_blockSignIn: 'Block sign-in',
  userDetailsDialog_userInformation: 'User Details',
  userDetailsDialog_role: 'Role',
  userDetailsDialog_internalId: 'Internal User ID',
  userDetailsDialog_manageRole: 'Manage Role',
  userDetailsDialog_email: 'Email',
  userDetailsDialog_phone: 'Phone Number',
  userDetailsDialog_firstName: 'First Name',
  userDetailsDialog_lastName: 'Last Name',
  userDetailsDialog_manageContactInfo: 'Manage contact information',
  userDetailsDialog_noneAdded: 'None added',

  userList_addUser: 'Add user',

  addUserDialog_addAUser: 'Add a user',
  addUserDialog_tabs_contactInfo: 'Contact Info',
  addUserDialog_tabs_role: 'Roles',
  addUserDialog_contactInfoTab_title: 'Set up user contact information',
  addUserDialog_contactInfoTab_description:
    'To get started, fill out some basic information about the user you’re adding.',
  addUserDialog_contactInfoTab_username: 'Email Address',
  addUserDialog_roleTab_title: 'Role',
  addUserDialog_roleTab_description:
    'If needed, change the user role and complete additional profile information for the user.',

  addUserStepper_contactInfo: 'Contact Info',
  addUserStepper_role: 'Role',

  products: 'Products',
  productsTable_header_productName: 'Product Name',
  productsTable_header_productId: 'Product ID',
  productsTable_header_productType: 'Product Type',
  productsTable_header_uom: 'Unit of Measure',
  productsTable_header_location: 'Location Availability',
  productsTable_header_taxable: 'Taxable',
  productsTable_header_productStatus: 'Product Status',
  productsDialog_deleteProduct_title: 'Delete product?',
  productsDialog_deleteProduct_description:
    'Deleting this product will permanently remove it from your product list. Are you sure you want to delete the product, <strong>{name}</strong>?',
  productNotification_productDeleted: 'Product successfully deleted',
  products_addProduct_title: 'Add a Product',
  products_addProduct_title_button: 'Add a product',
  products_addProduct_productProfile: 'Product Profile',
  products_addProduct_productProfile_description:
    'Torem ipsum dolor it amet, consecteur adipiscing elit. Nunc vulputate libero velit.',
  products_addProduct_productTypes: 'Product types',
  products_addProduct_component: 'Component',
  products_addProduct_mixDesign: 'Mix design',
  products_addProduct_productId: 'Product ID',
  products_addProduct_productName: 'Product name',
  products_addProduct_uom: 'Product unit of measure (UOM)',
  products_addProduct_productStatus: 'Product status',
  products_addProduct_locations: 'Locations',
  products_addProduct_productStatus_tooltip: 'Active products are available. Archived products are hidden by default.',
  products_addProduct_inventory: 'Inventory',
  products_addProduct_keepInInventory: 'Keep in inventory',
  products_addProduct_inventorySource: 'Inventory source',
  products_addProduct_location: 'Location',
  products_addProduct_safetyLevel: 'Safety Stock',
  products_addProduct_reorderLevel: 'Reorder',
  products_addProduct_maxLevel: 'Max Inventory',
  products_addProduct_safetyLevelDescription:
    'Warning level to indicate to the user to schedule an order for more material to replenish stock.',
  products_addProduct_reorderLevelDescription: 'The amount to the system should try to maintain inventory levels.',
  products_addProduct_maxLevelDescription: 'The maximum inventory amount that can be held at this location.',
  products_addProduct_noData: 'Nothing here yet',
  products_addProduct_noDataText: 'Add locations from the Product Profile or select another product',
  products_addProduct_productMapping: 'Product Mapping',
  products_addProduct_partner: 'Partner',
  products_addProduct_projects: 'Projects',
  products_addProduct_products: 'Products',
  products_addProduct_purchaseOrders: 'Purchase Orders',
  productNotification_productAdded: 'New product added',

  products_editProduct_title: 'Edit a Product',

  editUserDialog_editUser: 'Edit user',
  editUserDialog_tabs_contactInfo: 'Contact Info',
  editUserDialog_tabs_contactInfo_title: 'Contact information',
  editUserDialog_tabs_contactInfo_firstName: 'First Name',
  editUserDialog_tabs_contactInfo_lastName: 'Last Name',
  editUserDialog_tabs_contactInfo_email: 'Email',
  editUserDialog_tabs_contactInfo_phone: 'Phone Number',
  editUserDialog_tabs_contactInfo_save: 'Save',

  deleteUserDialog_title: 'Delete user?',
  deleteUserDialog_description: `Deleting this user will remove their access to your company's workspace. Are you sure you want to delete the user <strong>{name} ({email})</strong>?`,

  error_invalidEmailFormat: 'Invalid email format.',
  error_invalidPhone: 'Invalid phone.',
  notification_editSaved: 'Edit Saved',
  notification_userDeleted: 'User successfully deleted',

  general_confirm: 'Confirm',
  general_roles: 'Roles',
  general_role: 'Role',

  productTypes: 'Product Types',
  general_tableHeader_id: 'ID',
  general_tableHeader_name: 'Name',
  general_tableHeader_status: 'Status',
  general_tableHeader_description: 'Description',
  general_tableHeader_pricing: 'Pricing',
  general_tableHeader_vehicleCount: 'Vehicle Count',
  general_tableHeader_address: 'Address',
  general_tableHeader_uom: 'UOM',
  general_tableHeader_weight: 'Weight',
  general_tableHeader_firstName: 'First Name',
  general_tableHeader_lastName: 'Last Name',
  general_tableHeader_email: 'Email',
  general_tableHeader_mobile: 'Mobile',
  general_tableHeader_alternatePhone: 'Alternate Phone',
  general_tableHeader_carrier: 'Carrier',
  general_tableHeader_vehicleType: 'Vehicle Type',

  general_dialogAction_save: 'Save',
  general_dialogAction_next: 'Next',
  general_dialogAction_back: 'Back',

  general_notification_copy: 'Copied to clipboard',

  general_formLabel_address: 'Address',
  general_formLabel_state: 'State',
  general_formLabel_city: 'City',

  locations: 'Locations',
  locations_addLocations_title: 'Add a Location',
  locations_editLocations_title: 'Edit Location',
  locations_addLocations_profileTab_title: 'Location profile',
  locations_addLocationsNotification_success_msg: 'New Location added',
  locations_profileTab_locationId_field_label: 'Location ID',
  locations_profileTab_locationDescription_field_label: 'Description',
  locations_profileTab_locationId_field_tooltip:
    'Unique identifier for this production facility. An ID is a key record and is required for sharing information between systems.',
  locations_profileTab_locationName_field_label: 'Location name',
  locations_profileTab_streetAddress_field_label: 'Street address',
  locations_profileTab_complement_field_label: 'Apartment, suite, etc.',
  locations_profileTab_city_field_label: 'City',
  locations_profileTab_state_field_label: 'State',
  locations_profileTab_postalCode_field_label: 'Postal Code',
  locations_profileTab_primaryProductType_field_label: 'Primary product type',
  locations_profileTab_status_field_label: 'Location status',
  locations_profileTab_status_field_tooltip:
    'Active locations are available for use. Archived locations are hidden by default.',
  locations_profileTab_track_inventory_label: 'Track inventory',
  locations_deleteDialog_title: 'Delete location?',
  locations_deleteDialog_description:
    'Deleting this location will permanently remove it from your list of locations. Are you sure you want to delete the location, <strong>{name}</strong>?',
  locations_notification_locationDeleted: 'Location successfully deleted',

  productTypeForm_id: 'Product Type ID',
  productTypeForm_id_tooltip:
    'Unique identifier for this product type. An ID is a key record and is required for sharing information between systems.',
  productTypeForm_status: 'Product Type status',
  productTypeForm_status_tooltip:
    'Active product types are available in order entry. Archived product types are hidden by default.',
  productTypeForm_name: 'Product Type Name',
  productTypeForm_category: 'How do you want ticket quantities calculated?',
  productTypeForm_category_associated: 'Include ordered quantity on every load',
  productTypeForm_category_associated_tooltip:
    'For batchable items include the ordered quantity on every load (e.g., colored dye that should be in every load of concrete).',
  productTypeForm_category_other: 'Deliver up to the ordered quantity',
  productTypeForm_category_other_tooltip:
    'For non-batchable products included on an order (e.g., zip ties), deliver only up to the quantity ordered.',
  productTypes_addProductType: 'Add a Product Type',
  productTypes_addProductType_button: 'Add a product type',
  productTypes_addProductType_profile: 'Product Type Profile',
  productTypes_editProductType: 'Product Type Details',

  priceBooks: 'Price Books',
  priceBooks_addPriceBook_title: 'Add a Price Book',
  priceBooks_profileTab_title: 'Price Book Profile',
  priceBooks_pricesTab_title: 'Prices',
  priceBooks_profileTab_priceBookId: 'Price book ID',
  priceBooks_profileTab_priceBookId_tooltip:
    'Unique identifier for this price book. An ID is a key record and is required for sharing information between systems.',
  priceBooks_profileTab_priceBookName: 'Price book name',
  priceBooks_profileTab_priceBookStatus: 'Price book status',
  priceBooks_profileTab_priceBookStatus_tooltip:
    'Active price books are currently available for orders and billing. Archived price books are hidden by default.',
  priceBooks_notification_priceBookAdded: 'New price book added',
  priceBooks_editPriceBook_title: 'Price Book Details',
  priceBooks_deleteDialog_title: 'Delete price book?',
  priceBooks_deleteDialog_description:
    'Deleting this price book will permanently remove it from your list of price books. Are you sure you want to delete the price book, <strong>{name}</strong>?',
  priceBooks_notification_priceBookDeleted: 'Price book successfully deleted',

  customers: 'Customers',
  customers_notification_customerDeleted: 'Customer successfully deleted',
  customers_deleteDialog_title: 'Delete customer?',
  customers_deleteDialog_description:
    'Deleting this customer will permanently remove it from your list of customers. Are you sure you want to delete the customer, <strong>{name}</strong>?',

  customers_addCustomer_btn: 'Add a Customer',
  customers_addCustomer_title: 'Add a Customer',
  customers_editCustomer_title: 'Edit Customer',
  customers_addCustomerProfile_tab: 'Customer profile',
  customers_id_field: 'Customer ID',
  customers_id_field_tooltip:
    'Unique identifier for this customer. An ID is a key record and is required for sharing information between systems.',
  customers_assignedSalesPerson_field: 'Assigned salesperson',
  customers_name_field: 'Customer name',
  customers_status_field: 'Customer status',
  customers_status_field_tooltip:
    'Active customers are available for assignments. Archived customers are hidden by default.',
  customers_addresss_field: 'Street Address',
  customers_address_complement_field: 'Apartment, suite, etc.',
  customers_address_city_field: 'City',
  customers_address_state_field: 'State',
  customers_address_postalCode_field: 'Postal code',
  customers_notification_customerAdded: 'New customer added',

  carriers: 'Carriers',
  carriers_addCarrier_title: 'Add a Carrier',
  carriers_notification_carrierAdded: 'New carrier added',
  carriers_profileTab_title: 'Carrier Profile',
  carriers_editCarrier_title: 'Carrier Details',
  carriers_profileTab_carrierId: 'Carrier ID',
  carriers_profileTab_carrierId_tooltip:
    'Unique identifier for this carrier. An ID is a key record and is required for sharing information between systems.',
  carriers_profileTab_carrierName: 'Carrier Name',
  carriers_profileTab_carrierStatus: 'Carrier status',
  carriers_profileTab_carrierStatus_tooltip:
    'Active carriers are available for assignments. Archived carriers are hidden by default.',
  carriers_profileTab_carrierTrackItID: 'TrackIt reference ID',
  carriers_profileTab_carrierTrackItID_tooltip:
    "TrackIt ID for this carrier record. This field isn't integrated with TrackIt.",
  carriers_profileTab_carrierCompanyFleet: 'Belongs to company fleet',
  carriers_notification_carrierDeleted: 'Carrier successfully deleted',
  carriers_deleteDialog_title: 'Delete carrier?',
  carriers_deleteDialog_description:
    'Deleting this carrier will permanently remove it from your list of carriers. Are you sure you want to delete the carrier, <strong>{name}</strong>?',

  drivers: 'Drivers',
  drivers_addDriver_title: 'Add a Driver',
  drivers_profileTab_title: 'Driver Profile',
  drivers_profileTab_driverId: 'Driver ID',
  drivers_profileTab_driverId_tooltip:
    'Unique identifier for this driver. An ID is a key record and is required for sharing information between systems.',
  drivers_profileTab_driverMobile: 'Driver mobile phone number',
  drivers_profileTab_driverFirstName: 'Driver first name',
  drivers_profileTab_driverPhone: 'Driver alternate phone number',
  drivers_profileTab_driverLastName: 'Driver last name',
  drivers_profileTab_driverEmail: 'Driver email address',
  drivers_profileTab_driverStatus: 'Driver status',
  drivers_profileTab_driverStatus_tooltip:
    'Active drivers are currently available for jobs. Records with archived drivers are hidden by default.',
  drivers_notification_driverAdded: 'New driver added',
  drivers_editDriver_title: 'Driver Details',
  drivers_deleteDialog_title: 'Delete driver?',
  drivers_deleteDialog_description:
    'Deleting this driver will permanently remove it from your list of drivers. Are you sure you want to delete the driver, <strong>{name}</strong>?',
  drivers_notification_driverDeleted: 'Driver successfully deleted',

  notification_productTypeAdded: 'New product type added',
  notification_changesSaved: 'Changes saved',
  notification_productTypeDeleted: 'Product type successfully deleted',

  deleteProductTypeDialog_title: 'Delete product type?',
  deleteProductTypeDialog_description:
    'Deleting this product type will permanently remove it from your list of product types. Are you sure you want to delete the product type, <strong>{name}</strong>?',

  master_data: 'Master Data',
  general_save: 'Save',

  vehicleTypes: 'Vehicle Types',
  vehicleTypes_addVehicleType_title: 'Add a Vehicle Type',
  vehicleTypes_editVehicleType_title: 'Vehicle Type Details',
  vehicleTypes_profileTab_title: 'Vehicle Type Profile',
  vehicleTypes_profileTab_vehicleTypeId: 'Vehicle type ID',
  vehicleTypes_profileTab_vehicleTypeId_tooltip:
    'Unique identifier for this vehicle type. An ID is a key record and is required for sharing information between systems.',
  vehicleTypes_profileTab_defaultLoadSize: 'Default load size',
  vehicleTypes_profileTab_defaultLoadSize_tooltip:
    'Helps with vehicle scheduling. When you select the vehicle type during order entry, the system uses the default load size to ensure vehicles are scheduled so they’re full/as efficiently as possible.',
  vehicleTypes_profileTab_defaultLoadSizeUom: 'Unit of measure',
  vehicleTypes_profileTab_vehicleName: 'Vehicle type description',
  vehicleTypes_profileTab_vehicleName_tooltip: 'Description or name of this vehicle type.',
  vehicleTypes_profileTab_maxLoadSize: 'Max load size',
  vehicleTypes_profileTab_maxLoadSize_tooltip:
    'Used during ticketing to trigger a warning that you may overfill a vehicle. ',
  vehicleTypes_profileTab_maxLoadSizeUom: 'Unit of measure',
  vehicleTypes_profileTab_vehicleTypeStatus: 'Vehicle type status',
  vehicleTypes_profileTab_vehicleTypeStatus_tooltip:
    'Active vehicle types are available for assignments. Archived vehicle types are hidden by default.',
  vehicleTypes_profileTab_trackingColor: 'Tracking color',
  vehicleTypes_profileTab_trackingColor_tooltip:
    'Vehicle types assigned to this location appear in this color in your Tracking workspace.',
  vehicleTypes_profileTab_trackingColor_invalidValue: 'Invalid tracking color format',
  vehicleTypes_profileTab_defaultValue: 'Make this vehicle type the default for all orders',
  vehicleTypes_notification_vehicleTypeAdded: 'New vehicle type added',
  vehicleTypes_notification_vehicleTypeDeleted: 'Vehicle type successfully deleted',
  vehicleTypes_deleteDialog_title: 'Delete vehicle type?',
  vehicleTypes_deleteDialog_description:
    'Deleting this vehicle type will permanently remove it from your list of vehicle types. Are you sure you want to delete the vehicle type, <strong>{name}</strong>?',

  mixComponents: 'Mix Components',
  mixComponents_addMixComponent_title: 'Add a Mix Component',
  mixComponents_profileTab_title: 'Mix Component Profile',
  mixComponents_editMixComponent_title: 'Mix Component Details',
  mixComponents_profileTab_mixComponentId: 'Mix Component ID',
  mixComponents_profileTab_mixComponentId_tooltip:
    'Unique identifier for this mix component. An ID is a key record and is required for sharing information between systems.',
  mixComponents_profileTab_mixComponentUOM: 'Batch unit of measure (UOM)',
  mixComponents_profileTab_mixComponentName: 'Mix Component name',
  mixComponents_profileTab_mixComponentProduct: 'Product',
  mixComponents_profileTab_mixComponentStatus: 'Mix Component status',
  mixComponents_profileTab_mixComponentStatus_tooltip:
    'Active mix components are available for orders. Archived mix components are hidden by default.',
  mixComponents_profileTab_mixComponentInvUOM: 'Inventory unit of measure (UOM)',
  mixComponents_notification_mixComponentAdded: 'New mix component added',
  mixComponents_deleteDialog_title: 'Delete mix component?',
  mixComponents_deleteDialog_description:
    'Deleting this mix component will permanently remove it from your list of mix components. Are you sure you want to delete the mix component, <strong>{name}</strong>?',
  mixComponents_notification_mixComponentDeleted: 'Mix component successfully deleted',
  mixComponents_batchCodeTab_title: 'Batch Code',
  mixComponents_batchCodeTab_noData: 'Nothing here yet',
  mixComponents_batchCodeTab_batchCode: 'Batch code',

  mixDesigns: 'Mix Designs',
  mixDesigns_tableHeader_hasFormulas: 'Has Formulas',
  mixDesigns_addMixDesign: 'Add a mix design',
  mixDesigns_addMixDesignTitle: 'Add a Mix Design',
  mixDesigns_editMixDesignTitle: 'Edit Mix Design',
  mixDesigns_profileTab_title: 'Mix Design Profile',
  mixDesigns_profileTab_id_field_label: 'Mix design ID',
  mixDesigns_profileTab_mix_formula_id_field_label: 'Mix formula ID',
  mixDesigns_profileTab_mix_formula_id_field_label_tooltip:
    'Alternate identifier for a mix design that can be printed on the ticket.',
  mixDesigns_profileTab_max_batch_size_field_label: 'Maximum batch size',
  mixDesigns_profileTab_max_batch_uom_field_label: 'UOM',
  mixDesigns_profileTab_name_field_label: 'Mix design name',
  mixDesigns_profileTab_status_field_label: 'Mix design status',
  mixDesigns_profileTab_status_field_tooltip:
    'Active mix designs are available for use. Archived mix designs are hidden by default.',
  mixDesigns_addMixDesignNotification_success_msg: 'New mix design added',
  mixDesigns_deleteMixDesignTitle: 'Delete mix design?',
  mixDesigns_deleteMixDesign_description:
    'Deleting this mix design will permanently remove it from your list of mix designs. Are you sure you want to delete this mix design, <strong>{name}</strong>?',
  mixDesigns_deleteMixDesignNotification_success_msg: 'Mix design successfully deleted',

  vehicles: 'Vehicles',
  vehicles_addVehicle_title: 'Add a Vehicle',
  vehicles_editVehicle_title: 'Vehicle Details',
  vehicles_profileTab_title: 'Vehicle Profile',
  vehicles_profileTab_vehicleId: 'Vehicle ID',
  vehicles_profileTab_vehicleId_tooltip:
    'Unique identifier for this vehicle. An ID is a key record and is required for sharing information between systems.',
  vehicles_profileTab_vehicleName: 'Vehicle name',
  vehicles_profileTab_vehicleStatus: 'Vehicle status',
  vehicles_profileTab_vehicleStatus_tooltip:
    'Active vehicles are available for assignments. Archived vehicles are hidden by default.',
  vehicles_profileTab_vehicleType: 'Vehicle type',
  vehicles_profileTab_carrier: 'Carrier',
  vehicles_profileTab_vehicleHomeLocation: 'Vehicle home location',
  vehicles_profileTab_vehicleHomeLocation_tooltip:
    'Location where the vehicle is parked at night and where it typically departs from.',
  vehicles_notification_vehicleAdded: 'New vehicle added',
  vehicles_notification_vehicleDeleted: 'Vehicle successfully deleted',
  vehicles_deleteDialog_title: 'Delete vehicle?',
  vehicles_deleteDialog_description:
    'Deleting this vehicle will permanently remove it from your list of vehicles. Are you sure you want to delete the vehicle, <strong>{name}</strong>?',

  contactReasons: 'Contact Reasons',
  contactReasons_addContactReason_title: 'Add a Contact Reason',
  contactReasons_editContactReason_title: 'Contact Reasons',
  contactReasons_profileTab_title: 'Contact Reason Profile',
  contactReasons_profileTab_contactReasonId: 'Contact Reason ID',
  contactReasons_profileTab_contactReasonName: 'Contact Reason Name',
  contactReasons_profileTab_contactReasonStatus: 'Status',
  contactReasons_notification_contactReasonAdded: 'New contact reason added',
  contactReasons_deleteDialog_title: 'Delete contact reason?',
  contactReasons_deleteDialog_description:
    'Deleting this contact reason will permanently remove it from your list of contact reasons. Are you sure you want to delete the contact reason, <strong>{name}</strong>?',
  contactReasons_notification_contactReasonDeleted: 'Contact reason successfully deleted',

  concreteClasses: 'Concrete Classes',
  concreteClasses_tableHeader_nominalValue: 'Nominal Value',
  concreteClasses_tableHeader_minimumValue: 'Minimum Value',
  concreteClasses_tableHeader_maximumValue: 'Maximum Value',
  concreteClasses_tableHeader_classType: 'Class Type',
  concreteClasses_deleteDialog_title: 'Delete concrete class?',
  concreteClasses_deleteDialog_description:
    'Deleting this concrete class will permanently remove it from your list of concrete classes. Are you sure you want to delete the concrete class, <strong>{name}</strong>?',
  concreteClasses_notification_concreteClassDeleted: 'Concrete class successfully deleted',
  concreteClasses_addConcreteClass_title: 'Add a Concrete Class',
  concreteClasses_profileTab_title: 'Concrete Class Profile',
  concreteClasses_editConcreteClass_title: 'Concrete Class Details',
  concreteClasses_profileTab_concreteClassId: 'Concrete class ID',
  concreteClasses_profileTab_concreteClassId_tooltip:
    'Unique identifier for this concrete class. An ID is a key record and is required for sharing information between systems.',
  concreteClasses_profileTab_concreteClassUOM: 'Unit of measure (UOM)',
  concreteClasses_profileTab_concreteClassName: 'Concrete class name',
  concreteClasses_profileTab_concreteClassNominalValue: 'Nominal value',
  concreteClasses_profileTab_concreteClassType: 'Class Type',
  concreteClasses_profileTab_concreteClassMinimumValue: 'Minimum value',
  concreteClasses_profileTab_concreteClassSubtype: 'Class Subtype',
  concreteClasses_profileTab_concreteClassMaximumValue: 'Maximum value',
  concreteClasses_profileTab_concreteClassStatus: 'Concrete Class status',
  concreteClasses_profileTab_concreteClassesStatus_tooltip:
    'Active concrete classes are available for orders. Archived concrete classes are hidden by default.',
  concreteClasses_profileTab_nominalValue_tooltip: 'Ideal value for this concrete class.',
  concreteClasses_notification_concreteClassAdded: 'New concrete class added',
  concreteClasses_profileTab_defaultSettings: 'Use as default settings',

  contactTypes: 'Contact Types',
  contactTypes_addContactTypes_title: 'Add a Contact Type',
  contactTypes_editContactTypes_title: 'Contact Type Details',
  contactTypes_notification_contactTypesAdded: 'New contact type added',

  contactTypeList_addContactType: 'Add a Contact Type',
  contactTypes_profileTab_title: 'Contact Types Profile',
  contactTypes_profileTab_contactTypeId: 'Contact Type ID',
  contactTypes_profileTab_contactTypeName: 'Contact type name',
  contactTypes_profileTab_contactTypeStatus: 'Contact type status',
  contactTypes_profileTab_contactTypesId_tooltip:
    'Unique identifier for this contact type. An ID is a key record and is required for sharing information between systems.',
  contactTypes_profileTab_contactTypeStatus_tooltip:
    'Active contact types are available for use. Archived contact types are hidden by default.',
  contactTypes_deleteDialog_title: 'Delete contact type?',
  contactTypes_deleteDialog_description:
    'Deleting this contact type will permanently remove it from your list of contact types. Are you sure you want to delete the contact type, <strong>{name}</strong>?',
  contactTypes_notification_contactTypeDeleted: 'Contact type successfully deleted',

  trailers: 'Trailers',
  trailers_tableHeader_maxGross: 'Max Gross',
  trailers_tableHeader_maxNet: 'Max Net',
  trailers_tableHeader_targetGross: 'Target Gross',
  trailers_tableHeader_targetNet: 'Target Net',
  trailers_addTrailer_button: 'Add a trailer',
  trailers_addTrailer_title: 'Add a Trailer',
  trailers_editTrailer_title: 'Trailer Details',
  trailers_addTrailer_profile: 'Trailer Profile',
  trailers_trailerTab_fieldTrailerId_label: 'Trailer ID',
  trailers_trailerTab_fieldTrailerId_tooltip:
    'Unique identifier for this trailer. An ID is a key record and is required for sharing information between systems.',
  trailers_trailerTab_fieldTargetGross_label: 'Target gross cargo weight',
  trailers_trailerTab_fieldTargetGross_tooltip: 'Weight of the goods plus any packaging used for transport.',
  trailers_trailerTab_fieldTrailerName_label: 'Trailer name',
  trailers_trailerTab_fieldTargetNet_label: 'Target net weight of goods',
  trailers_trailerTab_fieldTargetNet_tooltip:
    'Weight of the goods minus the weight of the container, packaging, and protection equipment such as pallets.',
  trailers_trailerTab_fieldMaxGross_label: 'Maximum gross trailer weight',
  trailers_trailerTab_fieldMaxGross_tooltip:
    'Maximum total safe weight of the trailer. This means the total weight of the trailer when empty plus the weight of the cargo. It doesn’t include the weight of the vehicle pulling the trailer.',
  trailers_trailerTab_fieldMaxNet_label: 'Maximum net trailer weight',
  trailers_trailerTab_fieldMaxNet_tooltip: 'Maximum weight of the trailer’s cargo.',
  trailers_trailerTab_fieldTrailerStatus_label: 'Trailer Status',
  trailers_trailerTab_status_tooltip: 'Active trailers are available for use. Archived trailers are hidden by default.',
  trailers_notification_trailerAdded: 'New trailer added',
  trailers_notification_trailerDeleted: 'Trailer successfully deleted',
  trailers_deleteDialog_title: 'Delete trailer?',
  trailers_deleteDialog_description:
    'Deleting this trailer will permanently remove it from your list of trailers. Are you sure you want to delete the trailer, <strong>{name}</strong>?',

  company: 'Company',
  company_summary_title: 'Company Profile',
  company_form_companyName: 'Company Name',
  company_form_displayName: 'Display Name',
  company_form_primaryContact: 'Primary Contact',
  company_form_addressOptional: 'Address 2 (optional)',
  company_form_postalCode: 'Postal Code',
  company_form_phoneNumber: 'Phone Number',

  // BILLING AND INVOICING
  billing: 'Billing & Invoicing',
  billing_home_title: 'Configure Billing for your customers',
  billing_home_description:
    'Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per.',
  billing_home_button: 'Get started',
  billing_card_state_notStarted: 'Not Started',
  billing_card_state_started: 'Started',
  billing_card_state_completed: 'Completed',
  billing_card_state_button_notStarted: 'Start',
  billing_card_state_button_started: 'Resumen',
  billing_card_state_button_completed: 'View',
  billing_card_taxation_title: 'Taxation',
  billing_card_taxation_description:
    "Add your company's tax information manually or by connecting your company's preferred tax compliance software.",

  // BILLING AND INVOICING -- TAXATION
  billing_tax_settings: 'Tax Settings',
  billing_hq_construction: 'HQ Construction',
  billing_tax_description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
  billing_tax_provider_type: 'Tax provider type',
  billing_tax_type_geocoder: 'US Geocoder',
  billing_tax_type_zip_tax: 'Zip Tax',
  billing_tax_type_vat_rate: 'Company VAT Rate',
  billing_tax_per_product: 'Tax per product',
  billing_tax_per_product_tooltip: 'Apply taxes per product line',
  billing_vat_rate: 'VAT rate',
  billing_tax_Percentage_min_error: 'Tax Percentage should be more or equal to 0.00',
  billing_tax_Percentage_max_error: 'Tax Percentage should be less or equal to 999.99',

  errors,
  themes,
};
