import { shadow, background } from './index';

const elevation = mode => ({
  1: `box-shadow: ${shadow[mode][1]}; background:  ${background[mode][1]};`,
  2: `box-shadow: ${shadow[mode][2]}; background:  ${background[mode][2]};`,
  3: `box-shadow: ${shadow[mode][3]}; background:  ${background[mode][3]};`,
  4: `box-shadow: ${shadow[mode][4]}; background:  ${background[mode][4]};`,
  5: `box-shadow: ${shadow[mode][5]}; background:  ${background[mode][5]};`,
  6: `box-shadow: ${shadow[mode][5]}; background:  ${background[mode][6]};`,
});

export default elevation;
