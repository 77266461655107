import { TranslatedCell, CustomIcon } from '../../commons';
import { KebabOption } from '../../commons/entity-table';
import { CONCRETE_CLASS_TYPE_OPTIONS } from './components/concrete-class-form-view/form-config';

export const getConcreteClassesListColumns = ({ setDeleteConcreteClass, translateMessage }) => [
  {
    field: 'id',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_id'} />,
  },
  {
    field: 'name',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_name'} />,
  },
  {
    field: 'nominalValue',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'concreteClasses_tableHeader_nominalValue'} />,
  },
  {
    field: 'minimumValue',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'concreteClasses_tableHeader_minimumValue'} />,
  },
  {
    field: 'maximumValue',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'concreteClasses_tableHeader_maximumValue'} />,
  },
  {
    field: 'classType',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'concreteClasses_tableHeader_classType'} />,
    valueGetter: ({ row }) => CONCRETE_CLASS_TYPE_OPTIONS.find(option => option.value === row.classType)?.label,
  },
  {
    field: 'status',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_status'} />,
    valueGetter: ({ row }) => translateMessage(row.status.toLowerCase()),
  },
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    width: 80,
    cellClassName: 'table-actions',
    getActions: params => [
      <KebabOption
        icon={<CustomIcon iconName="deleteIcon" />}
        label={translateMessage('delete')}
        onClick={setDeleteConcreteClass(params.row)}
        showInMenu
      />,
    ],
  },
];
