import * as yup from 'yup';
import { PHONE_REGEX } from '../../../../util/forms';

export const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required('required'),
  lastName: yup.string().trim().required('required'),
  email: yup.string().email('error_invalidEmailFormat').required('required'),
  phone: yup.string().matches(PHONE_REGEX, 'error_invalidPhone'),
  role: yup.object().shape({
    roleRef: yup.string().trim().required('required'),
  }),
});
