import React, { useState, useMemo, useCallback } from 'react';
import { Localization } from 'connex-cds';

import { getContactTypesListColumns } from './tableConfig';
import { TableLayout, EntityTable, SearchFilter, DialogManager, ActionButton } from '../../commons';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { useContactTypes } from '../../api/hooks';
import { DIALOG_TYPES } from '../../constants';
import AddContactTypeDialog from './components/add-contact-type-dialog';
import EditContactTypeDialog from './components/edit-contact-type-dialog';
import DeleteContactTypeDialog from './components/delete-contact-type-dialog';

const ContactTypes = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [query, setQuery] = useState('');
  const [currentContactType, setCurrentContactType] = useState(null);
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const { List: useContactTypesList } = useContactTypes();
  const { data, isLoading } = useContactTypesList({ queryParams: { activeOnly: false } });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data, query, properties: ['id', 'name'] }),
    [data, query]
  );

  const setDelete = useCallback(
    contactType => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentContactType(contactType);
    },
    []
  );

  const contactTypesColumns = useMemo(
    () => getContactTypesListColumns({ setDeleteContactType: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setDialog(DIALOG_TYPES.NONE);
    setCurrentContactType(null);
  };

  const handleOnRowClick = params => {
    setCurrentContactType(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="contactTypeList_addContactType" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={contactTypesColumns}
          rows={filteredData}
          getRowId={row => row.crn}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddContactTypeDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditContactTypeDialog
          identifier={DIALOG_TYPES.EDIT}
          onClose={resetDialogs}
          contactTypeData={currentContactType}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
        />
        <DeleteContactTypeDialog
          identifier={DIALOG_TYPES.DELETE}
          onClose={resetDialogs}
          contactTypeData={currentContactType}
        />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default ContactTypes;
