import DialogContent from '@mui/material/DialogContent';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Button from '@mui/material/Button';
import { Localization, useNotification } from 'connex-cds';
import CopyAllIcon from '@mui/icons-material/CopyAll';
// import KeyIcon from '@mui/icons-material/Key';
// import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
// import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';

import { maskPhoneNumber, sanitizePhoneNumber } from '../../../../util/forms';
import { DialogTitle } from '../../../../commons';
import DetailsViewSkeleton from './DetailsViewSkeleton';

import { ContainerStyled } from './styles';
import copyToClipboard from '../../../../util/copyToClipboard';

const DetailsView = ({ userData = {}, onClose, isLoading = false, onManageContactInfo }) => {
  const { firstName = '', lastName = '', email, role, phone = '', crn = '' } = userData;
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const copyId = async () => {
    try {
      await copyToClipboard(crn);
      notification.info(translateMessage('general_notification_copy'), { materialIconName: 'CopyAll' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <DetailsViewSkeleton onClose={onClose} />}
      {!isLoading && (
        <ContainerStyled>
          <DialogTitle className="dialog-title" onClose={onClose}>
            <div className="user-avatar">
              <PersonOutlineOutlinedIcon fontSize="inherit" />
            </div>
            <span className="user-full-name" title={`${firstName} ${lastName}`}>{`${firstName} ${lastName}`}</span>
          </DialogTitle>
          <DialogContent className="dialog-content" dividers>
            {/* Hide until the buttons are connected */}
            {/* <div className="user-actions">
          <Button variant="outlined">
            <KeyIcon fontSize="inherit" />
            <Localization.Translate stringId={'userDetailsDialog_resetPassword'} />
          </Button>
          <Button variant="outlined">
            <PersonRemoveAlt1OutlinedIcon fontSize="inherit" />
            <Localization.Translate stringId={'userDetailsDialog_deleteUSer'} />
          </Button>
          <Button variant="outlined">
            <BlockOutlinedIcon fontSize="inherit" />
            <Localization.Translate stringId={'userDetailsDialog_blockSignIn'} />
          </Button>
        </div> */}

            <h3 className="user-info-title">
              <Localization.Translate stringId={'userDetailsDialog_userInformation'} />
            </h3>

            <div className="general-information">
              <div className="info-description">
                <span className="info-title">
                  <Localization.Translate stringId={'userDetailsDialog_email'} />
                </span>
                <span>{email}</span>
              </div>
              <div className="info-description">
                <span className="info-title">
                  <Localization.Translate stringId={'userDetailsDialog_phone'} />
                </span>
                <span>
                  {maskPhoneNumber(sanitizePhoneNumber(phone)) || (
                    <Localization.Translate stringId="userDetailsDialog_noneAdded" />
                  )}
                </span>
              </div>
              <div className="info-description">
                <span className="info-title">
                  <Localization.Translate stringId={'userDetailsDialog_firstName'} />
                </span>
                <span>{firstName}</span>
              </div>
              <div className="info-description">
                <span className="info-title">
                  <Localization.Translate stringId={'userDetailsDialog_lastName'} />
                </span>
                <span>{lastName}</span>
              </div>
            </div>
            <Button onClick={onManageContactInfo} className="manage-button" disableRipple>
              <Localization.Translate stringId={'userDetailsDialog_manageContactInfo'} />
            </Button>

            <div className="user-information">
              <div className="info-description">
                <span className="info-title">
                  <Localization.Translate stringId={'userDetailsDialog_role'} />
                </span>
                <Localization.Translate stringId={role?.name || 'userDetailsDialog_noneAdded'} />
                {/* <Button className="manage-button" disableRipple>
              <Localization.Translate stringId={'userDetailsDialog_manageRole'} />
            </Button> */}
              </div>
            </div>
            <div className="info-description">
              <span className="info-title">
                <Localization.Translate stringId={'userDetailsDialog_internalId'} />
              </span>
              <Button onClick={copyId} className="copy-id" disableRipple>
                <CopyAllIcon className="copy-icon" />
                <span className="id-text">{crn}</span>
              </Button>
            </div>
          </DialogContent>
        </ContainerStyled>
      )}
    </>
  );
};

export default DetailsView;
