import { http } from 'connex-cds';

export default {
  listComponents: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/components`, queryParams }),
  createComponent: ({ entityRef, mixComponent }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/components`,
      data: mixComponent,
    }),
  updateComponent: ({ entityRef, componentRef, data }) =>
    http.patch({
      apiName: 'company-admin',
      path: `/${entityRef}/components/${componentRef}`,
      data,
    }),
};
