import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { roles } from '../queries';

export const useRoles = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['roles', entityRef];

  const List = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['roles', entityRef, queryString.stringify(queryParams)],
      queryFn: () => roles.listRoles({ entityRef, queryParams }),
      staleTime: Infinity,
      enabled: !!entityRef,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const Create = () =>
    useMutation({
      mutationFn: role => roles.createRole({ entityRef, role }),
      onMutate: async newItem => {
        await queryClient.cancelQueries(queryKey);
        const previousItem = queryClient.getQueryData(queryKey);
        return { previousItem, newItem };
      },
      onError: (err, newItem, context) => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const Update = () =>
    useMutation({
      mutationFn: role => roles.updateRole({ entityRef, role }),
      onMutate: async newItem => {
        await queryClient.cancelQueries(queryKey);
        const previousItem = queryClient.getQueryData(queryKey);
        const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
        queryClient.setQueryData(queryKey, newData);
        return { previousItem, newItem };
      },
      onError: (err, newItem, context) => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  return { List, Create, Update };
};
