import React, { useMemo, useState, useCallback } from 'react';
import { Localization } from 'connex-cds';

import { getLocationsListColumns } from './tableConfig';
import { useLocations } from '../../api/hooks';
import { TableLayout, DialogManager, SearchFilter, EntityTable, ActionButton } from '../../commons';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { DIALOG_TYPES } from '../../constants';
import AddLocationDialog from './components/add-new-location-dialog';
import EditLocationDialog from './components/edit-location-dialog';
import DeleteLocationDialog from './components/delete-location-dialog';

const Locations = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [query, setQuery] = useState('');
  const [currentLocation, setCurrentLocation] = useState(null);
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const { List: useListLocations } = useLocations();
  const { data: locations, isLoading } = useListLocations({ activeOnly: false });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data: locations, query, properties: ['id', 'name'] }),
    [locations, query]
  );

  const setDelete = useCallback(
    location => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentLocation(location);
    },
    []
  );

  const columns = useMemo(
    () => getLocationsListColumns({ setDeleteLocation: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setCurrentLocation(null);
    setDialog(DIALOG_TYPES.NONE);
  };

  const handleOnRowClick = params => {
    setCurrentLocation(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="locations_addLocations_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          getRowId={row => row.crn}
          rows={filteredData}
          columns={columns}
          isLoading={isLoading}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddLocationDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditLocationDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          locationData={currentLocation}
          onClose={resetDialogs}
        />
        <DeleteLocationDialog identifier={DIALOG_TYPES.DELETE} onClose={resetDialogs} locationData={currentLocation} />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default Locations;
