import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';

export const DialogStyled = styled(Dialog)`
  ${({ theme }) => css`
    font-family: 'Work Sans';

    .MuiDialog-paper {
      max-height: 817px;
      height: 80%;
    }

    .add-user-dialog-tabs {
      border-top: 1px solid ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.12)', dark: '#938F99' })};
      flex-shrink: 0;
    }

    .MuiTab-root.MuiTab-labelIcon {
      font-size: 14px;

      .MuiTab-iconWrapper {
        font-size: 24px;
      }
    }

    .dialog-title {
      gap: 10px;

      .person-icon {
        font-size: 38px;
      }
    }

    .dialog-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px 20px;

      .step-controls {
        display: flex;
        gap: 22px;

        button {
          ${theme.validateMode({ dark: `color: ${theme.colorsCA.blue[80]};` })}
        }
      }

      button {
        font-size: 16px;
        padding: 14px 24px;
        line-height: 20px;
      }
    }

    .dialog-content {
      display: flex;
      padding: 0;
      overflow: hidden;

      ${theme.validateMode({ dark: 'border-top: 1px solid #938F99;' })}
      ${theme.validateMode({ dark: 'border-bottom: 1px solid #938F99;' })}
    }

    .stepper {
      overflow: hidden;
      width: 30ch;
      padding: 40px 45px 0 40px;
      border-right: 1px solid ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.12)', dark: '#938F99' })};
    }

    .tab-content {
      flex: 1;
      padding: 40px 40px 40px 45px;
      overflow: auto;
    }
  `}
`;
