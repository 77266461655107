import { useState } from 'react';
import DetailsView from '../details-view/DetailsView';

import { DialogStyled } from './styles';
import { useProfile } from '../../../../query-hooks/user-profile';
import EditUserView from '../edit-user-view/EditUserView';

export const VIEWS = {
  DETAILS: 'details',
  EDIT_USER: 'edit-user',
};

const DetailsDialog = ({ open = false, onClose, profileRef, userData }) => {
  const [view, setView] = useState(VIEWS.DETAILS);
  const [currentTab, setCurrentTab] = useState(0);

  const { data: profileData, isLoading } = useProfile(profileRef);

  const handleClose = ({ success = false }) => {
    onClose({ success });
    setView(VIEWS.DETAILS);
    setCurrentTab(0);
  };

  const manageContactInfo = () => {
    setView(VIEWS.EDIT_USER);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose({ success: false });
    }
  };

  return (
    <DialogStyled className="details-dialog" open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      {/* Else branch will be the edit users view (contains tabs) */}
      {view === VIEWS.DETAILS ? (
        <DetailsView
          userData={{ role: userData.role, ...profileData }}
          onClose={handleClose}
          isLoading={isLoading}
          onManageContactInfo={manageContactInfo}
        />
      ) : (
        <EditUserView
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          onClose={handleClose}
          userData={{ role: userData.role, ...profileData }}
        />
      )}
    </DialogStyled>
  );
};

export default DetailsDialog;
