import { useState, useMemo, useCallback } from 'react';
import { Localization } from 'connex-cds';

import { useMixes } from '../../api/hooks';
import { TableLayout, EntityTable, SearchFilter, ActionButton, DialogManager } from '../../commons';
import { getMixListColumns } from './tableConfig';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import { DIALOG_TYPES } from '../../constants';
import AddMixDesignDialog from './components/add-mix-dialog';
import EditMixDesignDialog from './components/edit-mix-dialog';
import DeleteMixDesignDialog from './components/delete-mix-dialog';

const Mixes = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const [currentMix, setCurrenMix] = useState(null);
  const [query, setQuery] = useState('');
  const { List: listMixes } = useMixes();
  const { data, isLoading } = listMixes({ queryParams: { activeOnly: false } });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data, query, properties: ['id', 'name'] }),
    [data, query]
  );

  const setDelete = useCallback(
    item => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrenMix(item);
    },
    []
  );

  const mixColumns = useMemo(
    () => getMixListColumns({ handleOnDeleteClick: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setDialog(DIALOG_TYPES.NONE);
  };

  const handleOnRowClick = params => {
    setCurrenMix(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setQuery} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="mixDesigns_addMixDesign" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          isLoading={isLoading}
          columns={mixColumns}
          rows={filteredData}
          onRowClick={handleOnRowClick}
          getRowId={row => row.crn}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddMixDesignDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditMixDesignDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          mixData={currentMix}
          onClose={resetDialogs}
        />
        <DeleteMixDesignDialog identifier={DIALOG_TYPES.DELETE} mixData={currentMix} onClose={resetDialogs} />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default Mixes;
