import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { sanitizePhoneNumber } from '../../../../util/forms';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { useDrivers } from '../../../../api/hooks';
import DriverFormView from '../driver-form-view';
import { initialValues } from '../driver-form-view/form-config';

const AddDriverDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCreateDriver } = useDrivers();
  const { mutateAsync: createDriver } = useCreateDriver();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { driverId, driverFirstName, driverLastName, driverMobile, driverPhone, driverEmail, driverStatus } = values;

    formikBag.setSubmitting(true);

    try {
      const driver = {
        id: driverId,
        firstName: driverFirstName,
        lastName: driverLastName,
        email: driverEmail,
        mobile: sanitizePhoneNumber(driverMobile),
        phone: sanitizePhoneNumber(driverPhone),
        status: driverStatus.value,
      };

      await createDriver({ driver });
      notification.success(translateMessage('drivers_notification_driverAdded'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DriverFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
    />
  );
};

AddDriverDialog.propTypes = {
  onClose: func.isRequired,
};

AddDriverDialog.defaultProps = {
  onClose: () => {},
};

export default AddDriverDialog;
