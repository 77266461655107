import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { vehicleTypes } from '../queries';

export const useVehicleTypes = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['vehicle-types', entityRef];

  const List = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['vehicle-types', entityRef, queryString.stringify(queryParams)],
      queryFn: () => vehicleTypes.listVehicleTypes({ entityRef, queryParams }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const Create = () =>
    useMutation({
      mutationFn: ({ vehicleType }) => vehicleTypes.createVehicleType({ entityRef, vehicleType }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const Update = () =>
    useMutation({
      mutationFn: ({ data, vehicleTypeRef }) => vehicleTypes.updateVehicleType({ entityRef, vehicleTypeRef, data }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  return { List, Create, Update };
};
