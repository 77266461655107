import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, number, shape, string } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import MixFormView from '../mix-form-view';
import { useUom, useMixes } from '../../../../api/hooks';
import { parseUomOptions } from '../../../../api/adapters';

const EditMixDialog = ({ onClose, mixData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useEditMix } = useMixes();
  const { mutateAsync: updateMix } = useEditMix();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { mixDesignName, mixDesignStatus, maxBatchSize, maxBatchUom, mixFormulaId } = values;

    formikBag.setSubmitting(true);

    try {
      const data = {
        name: mixDesignName,
        status: mixDesignStatus.value,
        maxBatchSize: parseInt(maxBatchSize),
        maxBatchSizeUOM: maxBatchUom.value,
        jobMixFormulaId: mixFormulaId,
      };

      await updateMix({ data, mixRef: mixData.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!mixData) return null;

    const { id, name, maxBatchSize, jobMixFormulaId } = mixData;

    const values = {
      mixDesignId: id,
      mixDesignName: name,
      mixDesignStatus: STATUS_OPTIONS.find(status => status.value === mixData.status),
      maxBatchSize: maxBatchSize || 0,
      maxBatchUom: uomOptions.find(uomItem => uomItem.value === mixData?.maxBatchSizeUOM) || '',
      mixFormulaId: jobMixFormulaId || '',
    };

    return values;
  }, [mixData, uomOptions]);

  return (
    <MixFormView
      handleOnDelete={handleOnDelete}
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      mixDesignStatusOptions={STATUS_OPTIONS}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
    />
  );
};

EditMixDialog.propTypes = {
  onClose: func.isRequired,
  handleOnDelete: func,
  mixData: shape({
    crn: string.isRequired,
    id: string.isRequired,
    name: string.isRequired,
    maxBatchSize: number,
    jobMixFormulaId: string,
    maxBatchSizeUOM: string,
    status: string.isRequired,
  }),
};

EditMixDialog.defaultProps = {
  onClose: () => {},
  mixData: {},
};

export default EditMixDialog;
