import { BOOLEAN_VALUES } from '../../constants';

export const parseLocations = data => {
  return (
    data?.map(item => ({
      value: item?.crn,
      label: item?.name,
      trackInventory: item?.trackInventory === BOOLEAN_VALUES.Yes,
    })) || []
  );
};
