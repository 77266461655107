import React from 'react';
import { DialogActionsStyled } from './style';

const DialogActions = ({ lefSide, rightSide }) => {
  return (
    <DialogActionsStyled>
      <div className="first-action-column">{lefSide}</div>
      <div className="second-action-column">{rightSide}</div>
    </DialogActionsStyled>
  );
};

export default DialogActions;
