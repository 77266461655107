import cn from 'classnames';
import { Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useListAndDetailContext } from './ListAndDetailProvider';

const Styled = styled(Layout.Column)``;

export const Detail = ({ onFinally }) => {
  const { Editor, updateQuery, createQuery, deleteQuery } = useListAndDetailContext();

  if (!updateQuery || !createQuery) {
    console.error('updateQuery and createQuery are required.');
  }

  return (
    <Styled className={cn('detail')}>
      <Editor updateQuery={updateQuery} createQuery={createQuery} deleteQuery={deleteQuery} onFinally={onFinally} />
    </Styled>
  );
};
