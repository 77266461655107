import { http } from 'connex-cds';

export default {
  listDrivers: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/drivers`, queryParams }),
  createDriver: ({ entityRef, driver }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/drivers`,
      data: driver,
    }),
  updateDriver: ({ entityRef, driverRef, driver }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/drivers/${driverRef}`, data: driver }),
};
