import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { products } from '../queries';

export const useProducts = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['products', entityRef];

  const List = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['products', entityRef, queryString.stringify(queryParams)],
      queryFn: () => products.listProducts({ entityRef, queryParams }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const Create = () =>
    useMutation({
      mutationFn: ({ product }) => products.createProduct({ entityRef, product }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const Update = () =>
    useMutation({
      mutationFn: ({ product, productRef }) => products.updateProduct({ entityRef, productRef, product }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: (_data, _error, variables) => {
        queryClient.invalidateQueries({ queryKey });
        queryClient.invalidateQueries({ queryKey: ['product-locations', entityRef, variables.productRef] });
      },
    });

  return { List, Create, Update };
};
