import { http } from 'connex-cds';

export default {
  listProducts: ({ entityRef }) => http.get({ apiName: 'company-admin', path: `/${entityRef}/products` }),
  deleteProduct: ({ entityRef, productRef }) =>
    http.delete({ apiName: 'company-admin', path: `/${entityRef}/products/${productRef}` }),
  createProduct: ({ entityRef, product }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/products`,
      data: product,
    }),
  updateProduct: ({ entityRef, productRef, product }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/products/${productRef}`, data: product }),
};
