import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ContainerStyled = styled('div')`
  ${({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Work Sans';

    .general-information-tile {
      font-weight: 600;

      &.skeleton {
        width: 30ch;
      }
    }

    .general-information {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, max-content));
      row-gap: 33px;
      column-gap: 160px;

      &.skeleton {
        grid-template-columns: repeat(2, 16ch);
      }
    }

    .user-information {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, max-content));
      row-gap: 20px;
      column-gap: 160px;
      margin-bottom: 33px;

      &.skeleton {
        grid-template-columns: repeat(2, 16ch);
      }
    }

    .general-information {
      margin-bottom: 10px;
    }

    .manage-button {
      color: ${theme.colorsCA.blue[50]};
      padding: 0;
      font-weight: 600;
      justify-content: flex-start;
      margin-bottom: 33px;

      &:hover,
      &:focus {
        background: transparent;
      }

      &.skeleton {
        width: 18ch;
      }
    }

    .info-description {
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: hidden;
      letter-spacing: 0.15px;
      line-height: 24px;

      & > span {
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }

    .info-title {
      font-weight: 500;
    }

    .user-actions {
      display: flex;
      gap: 22px;
      margin: 0 10px 20px 10px;

      button {
        font-size: 16px;
        display: flex;
        gap: 8px;
      }
    }

    h3.user-info-title {
      font-size: 22px;
      margin-bottom: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;

      &.skeleton {
        width: 20ch;
      }
    }

    .dialog-content {
      padding: 20px 55px;

      & > * {
        font-size: 16px;
      }
    }

    .dialog-title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 20px 0;

      .user-avatar {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid ${theme.colorsCA.darkBlue[10]};
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .user-full-name {
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
        font-size: 30px;
        line-height: 38px;

        &.skeleton {
          width: 24ch;
          height: 30px;
        }
      }
    }

    .copy-id {
      width: fit-content;
      display: flex;
      gap: 10px;
      padding: 10px 12px;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[40] })};

      .copy-icon {
        font-size: 24px;
      }

      .id-text {
        min-width: 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  `}
`;
