import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, string, func } from 'prop-types';
import { Grid } from '@mui/material';

import { TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { maskPhoneNumber, sanitizePhoneNumber } from '../../../../util/forms';
import { DIALOG_TYPES } from '../../../../constants';
import { DriverProfileContainer } from './styles';

const DriverProfileTab = ({ statusOptions, mode, setFieldValue }) => {
  const handlePhoneChange = setFieldValue => (name, value) => {
    setFieldValue(name, maskPhoneNumber(sanitizePhoneNumber(value)));
  };

  return (
    <DriverProfileContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="drivers_profileTab_title" />
      </h3>
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="drivers_profileTab_driverId"
              component={TextInputWithFormikField}
              variant="outlined"
              name="driverId"
              tooltipInfoStringId="drivers_profileTab_driverId_tooltip"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="drivers_profileTab_driverMobile"
              component={TextInputWithFormikField}
              variant="outlined"
              name="driverMobile"
              customOnChange={handlePhoneChange(setFieldValue)}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="drivers_profileTab_driverFirstName"
              component={TextInputWithFormikField}
              variant="outlined"
              name="driverFirstName"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="drivers_profileTab_driverPhone"
              component={TextInputWithFormikField}
              variant="outlined"
              name="driverPhone"
              customOnChange={handlePhoneChange(setFieldValue)}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="drivers_profileTab_driverLastName"
              component={TextInputWithFormikField}
              variant="outlined"
              name="driverLastName"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="drivers_profileTab_driverEmail"
              component={TextInputWithFormikField}
              variant="outlined"
              name="driverEmail"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="drivers_profileTab_driverStatus"
              component={SelectWithFormikField}
              name="driverStatus"
              options={statusOptions}
              infoTooltipStringId="drivers_profileTab_driverStatus_tooltip"
              testId="select-driver-status"
              required
            />
          </Grid>
        </Grid>
      </Form>
    </DriverProfileContainer>
  );
};

DriverProfileTab.propTypes = {
  statusOptions: array.isRequired,
  mode: string.isRequired,
  setFieldValue: func.isRequired,
};

export default DriverProfileTab;
