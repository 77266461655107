import React from 'react';
import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';

import { DIALOG_TYPES } from '../../../../constants';
import { RadioGroupWithFormikField, SelectWithFormikField, TextInputWithFormikField } from '../../../../commons';

import { Styled } from './styles';

const ProductTypeProfileTab = ({ mode, statusOptions, categoryOptions }) => {
  return (
    <Styled>
      <h3>
        <Localization.Translate stringId="productTypes_addProductType_profile" />
      </h3>
      <Form className="product-type-form">
        <div className="form-grid">
          <Field
            name="id"
            required
            disabled={mode === DIALOG_TYPES.EDIT}
            testId="productTypeForm-id"
            labelStringId="productTypeForm_id"
            variant="outlined"
            component={TextInputWithFormikField}
            tooltipInfoStringId="productTypeForm_id_tooltip"
          />
          <Field
            name="status"
            required
            testId="productTypeForm-status"
            component={SelectWithFormikField}
            labelStringId="productTypeForm_status"
            options={statusOptions}
            infoTooltipStringId="productTypeForm_status_tooltip"
          />
          <Field
            name="name"
            required
            testId="productTypeForm-name"
            labelStringId="productTypeForm_name"
            variant="outlined"
            component={TextInputWithFormikField}
          />
        </div>
        <p className="category-field-description">
          <Localization.Translate stringId="productTypeForm_category" />
        </p>
        <Field
          name="category"
          testId="productTypeForm-category"
          options={categoryOptions}
          component={RadioGroupWithFormikField}
        />
      </Form>
    </Styled>
  );
};

export default ProductTypeProfileTab;
