import { http } from 'connex-cds';

export default {
  listContactReasons: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/contact-reasons`, queryParams }),
  createContactReason: ({ entityRef, data }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/contact-reasons`,
      data,
    }),
  updateContactReasons: ({ entityRef, data, contactReasonRef }) =>
    http.patch({
      apiName: 'company-admin',
      path: `/${entityRef}/contact-reasons/${contactReasonRef}`,
      data,
    }),
};
