export const STATE_TEXT = {
  notStarted: {
    text: 'billing_card_state_notStarted',
    icon: 'nonStarted',
    button: 'billing_card_state_button_notStarted',
  },
  started: {
    text: 'billing_card_state_started',
    icon: 'started',
    button: 'billing_card_state_button_started',
  },
  completed: {
    text: 'billing_card_state_completed',
    icon: 'completed',
    button: 'billing_card_state_button_completed',
  },
};
