import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { billing } from '../queries';

export const useBilling = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['billing', entityRef];

  const Get = () => {
    return useQuery({
      queryKey: ['billing', entityRef],
      queryFn: () => billing.getSettings({ entityRef }),
      staleTime: Infinity,
    });
  };

  const Update = () =>
    useMutation({
      mutationFn: ({ payload }) => billing.updateSettings({ entityRef, payload }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  return { Get, Update };
};
