import * as yup from 'yup';
import { STATUS_OPTIONS } from '../../../../constants/common';

export const categoryOptions = [
  {
    value: 'associated',
    labelStringId: 'productTypeForm_category_associated',
    tooltipStringId: 'productTypeForm_category_associated_tooltip',
  },
  {
    value: 'other',
    labelStringId: 'productTypeForm_category_other',
    tooltipStringId: 'productTypeForm_category_other_tooltip',
  },
];

export const validationSchema = yup.object().shape({
  id: yup.string().trim().required('required'),
  name: yup.string().trim().required('required'),
  category: yup.string().required('required'),
  status: yup.object().required('required'),
});

export const initialValues = {
  id: '',
  name: '',
  category: 'associated',
  status: STATUS_OPTIONS[0],
};
