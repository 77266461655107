import React from 'react';
import { Localization } from 'connex-cds';
import { Typography, IconButton } from '@mui/material';

import { CustomIcon, FormTooltip } from '../../../../commons';
import { SwitchLabelStyled } from './styles';

const SwitchLabel = () => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <SwitchLabelStyled>
      <Typography>{translateMessage('billing_tax_per_product')}</Typography>
      <FormTooltip title={translateMessage('billing_tax_per_product_tooltip')} placement="top-end" arrow>
        <IconButton>
          <CustomIcon iconName="alert" />
        </IconButton>
      </FormTooltip>
    </SwitchLabelStyled>
  );
};

export default SwitchLabel;
