import * as yup from 'yup';

import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  VehicleProfile: 0,
};

export const validationSchema = yup.object().shape({
  vehicleId: yup.string().required('required'),
  vehicleName: yup.string().required('required'),
  vehicleStatus: yup.object().required('required'),
  vehicleType: yup.object().required('required'),
  vehicleHomeLocation: yup.object().required('required'),
  carrier: yup.object().required('required'),
});

export const initialValues = {
  vehicleId: '',
  vehicleName: '',
  vehicleStatus: STATUS_OPTIONS[0],
  vehicleType: '',
  vehicleHomeLocation: '',
  carrier: '',
};
