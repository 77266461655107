import DialogContent from '@mui/material/DialogContent';
import Skeleton from '@mui/material/Skeleton';

import { DialogTitle } from '../../../../commons';

import { ContainerStyled } from './styles';

const DetailsViewSkeleton = ({ onClose }) => {
  return (
    <ContainerStyled>
      <DialogTitle className="dialog-title" onClose={onClose}>
        <Skeleton className="user-full-name skeleton" variant="text" />
      </DialogTitle>
      <DialogContent className="dialog-content" dividers>
        <h3 className="user-info-title skeleton">
          <Skeleton variant="text" />
        </h3>

        <div className="general-information skeleton">
          {Array.from({ length: 4 }, (_, index) => (
            <div key={index} className="info-description">
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </div>
          ))}
        </div>
        <Skeleton className="manage-button skeleton" variant="text" />

        <div className="user-information skeleton">
          <div className="info-description">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </div>
        </div>
      </DialogContent>
    </ContainerStyled>
  );
};

export default DetailsViewSkeleton;
