import React from 'react';
import { TabsViewsWrapper } from './styles';

const CustomTabsView = ({ children, tab }) => {
  return (
    <TabsViewsWrapper>
      {React.Children.map(children, child => {
        if (child.props?.tabIdentifier === tab) {
          return React.cloneElement(child);
        }
        return null;
      })}
    </TabsViewsWrapper>
  );
};

export default CustomTabsView;
