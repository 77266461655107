import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, string } from 'prop-types';
import Grid from '@mui/material/Grid';

import { TextInputWithFormikField, SelectWithFormikField, CheckboxWithFormikField } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { CarrierProfileContainer } from './styles';

const CarrierProfileTab = ({ carrierStatusOptions, mode }) => {
  return (
    <CarrierProfileContainer>
      <h3 className="carrier-profile-title">
        <Localization.Translate stringId="carriers_profileTab_title" />
      </h3>

      <Form className="carrier-profile-form">
        <Grid container spacing={5}>
          <Grid item md={6} xs={12} order={{ xs: 1, md: 1 }}>
            <Field
              fullWidth
              labelStringId="carriers_profileTab_carrierId"
              component={TextInputWithFormikField}
              variant="outlined"
              name="carrierId"
              tooltipInfoStringId="carriers_profileTab_carrierId_tooltip"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 3, md: 2 }}>
            <Field
              fullWidth
              labelStringId="carriers_profileTab_carrierName"
              component={TextInputWithFormikField}
              variant="outlined"
              name="carrierName"
              required
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 2, md: 3 }}>
            <Field
              fullWidth
              labelStringId="carriers_profileTab_carrierStatus"
              component={SelectWithFormikField}
              name="carrierStatus"
              options={carrierStatusOptions}
              infoTooltipStringId="carriers_profileTab_carrierStatus_tooltip"
              testId="select-carrier-status"
              required
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 4, md: 4 }} className="custom-form-control-checkbox">
            <Field
              labelStringId="carriers_profileTab_carrierCompanyFleet"
              component={CheckboxWithFormikField}
              name="carrierCompanyFleet"
            />
          </Grid>
        </Grid>
      </Form>
    </CarrierProfileContainer>
  );
};

CarrierProfileTab.propTypes = {
  carrierStatusOptions: array.isRequired,
  mode: string.isRequired,
};

export default CarrierProfileTab;
