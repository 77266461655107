import cn from 'classnames';
import { Form } from 'connex-cds';
import { camelCase, toUpper } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import style from './style';

const Styled = styled.div`
  ${style}
`;

const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export const DefaultEditor = ({ updateFn, createFn, deleteFn, onFinally }) => {
  const { Components, values, mode, ...formContext } = Form.useFormContext();

  const { SaveButton, CancelButton, DeleteButton } = Components;

  const handleCreate = React.useCallback(
    connection =>
      createFn?.(connection)?.then(response => {
        onFinally?.();
        return response;
      }),
    [createFn, onFinally]
  );

  const handleUpdate = React.useCallback(
    connection =>
      updateFn?.(connection)?.then(response => {
        onFinally?.();
        return response;
      }),
    [onFinally, updateFn]
  );

  const handleDelete = React.useCallback(
    connectionRef =>
      deleteFn?.(connectionRef)?.then(response => {
        onFinally?.();
        return response;
      }),
    [deleteFn, onFinally]
  );

  return (
    <Styled className={cn('service-editor')}>
      {formContext?.config?.fields?.map?.(field => {
        const componentName = pascalCase(field.name || field.path);
        const Component = Components[componentName];
        return Component ? <Component /> : null;
      })}
      <div className="buttons">
        <div className="left">
          <DeleteButton onDelete={handleDelete} />
        </div>
        <div className="right">
          <CancelButton onCancel={onFinally} enabled />
          <SaveButton onCreate={handleCreate} onUpdate={handleUpdate} />
        </div>
      </div>
    </Styled>
  );
};
