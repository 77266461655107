import { http } from 'connex-cds';

export default {
  listProductTypes: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/product-types`, queryParams }),
  createProductType: ({ entityRef, productType }) =>
    http.post({ apiName: 'company-admin', path: `/${entityRef}/product-types`, data: productType }),
  updateProductType: ({ entityRef, productType, productTypeRef }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/product-types/${productTypeRef}`, data: productType }),
};
