import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import { CustomStepIconRootStyled } from './styles';

const CustomStepIcon = props => {
  const { active, completed, error, className } = props;

  return (
    <CustomStepIconRootStyled ownerState={{ active, completed, error }} className={className}>
      {completed && <CheckIcon className="CustomStepIcon-completedIcon" />}
      {error && <PriorityHighIcon className="CustomStepIcon-errorIcon " />}
    </CustomStepIconRootStyled>
  );
};

export default CustomStepIcon;
