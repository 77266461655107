import Skeleton from '@mui/material/Skeleton';

import { SkeletonContainer } from './styles';

const EntityTableSkeleton = ({ numRows = 3 }) => {
  return (
    <SkeletonContainer data-testid="table-skeleton" className="entity-table-skeleton">
      <Skeleton variant="rectangular" className="header-skeleton" />
      <div className="rows-skeleton-container">
        {Array.from({ length: numRows }, (_, index) => (
          <Skeleton key={index} className="row-skeleton" variant="rectangular" />
        ))}
      </div>
    </SkeletonContainer>
  );
};

export { EntityTableSkeleton };
