import * as yup from 'yup';
import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  AddLocationProfile: 0,
};

export const validationSchema = yup.object().shape({
  locationId: yup.string().required('required'),
  description: yup.string().required('required'),
  primaryProductType: yup.object().required('required'),
  locationName: yup.string().required('required'),
  status: yup.object().required('required'),
  streetAddress: yup.string().required('required'),
  complement: yup.string(),
  city: yup.string().required('required'),
  state: yup.string(),
  postalCode: yup.string().required('required'),
});

export const initialValues = {
  locationId: '',
  description: '',
  primaryProductType: '',
  locationName: '',
  status: STATUS_OPTIONS[0],
  streetAddress: '',
  complement: '',
  city: '',
  state: '',
  postalCode: '',
};
