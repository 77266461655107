import { Core, Localization } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  .permissions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 15px;
  }
`;

export const PermissionsPicker = ({ availablePermissions, permissions, onChange }) => {
  const [isAdmin, setIsAdmin] = React.useState(permissions?.['*']);

  const handleAdminClick = React.useCallback(
    value => {
      setIsAdmin(value);
      onChange('*', value);
    },
    [onChange]
  );

  return (
    <Styled className={cn('permissions-picker')}>
      <div>
        <Core.Checkbox value={isAdmin} onChange={handleAdminClick} disabled={!availablePermissions?.length}>
          <Localization.Translate stringId="admin-permission" data-testid="admin-label" />
        </Core.Checkbox>
        {!!availablePermissions?.length && <hr />}
      </div>

      <div className="permissions">
        {availablePermissions.map(p => (
          <div className="asdf">
            <Core.Checkbox
              disabled={isAdmin}
              value={isAdmin || permissions[p]}
              onChange={checked => {
                onChange(p, checked);
              }}
            >
              {p}
            </Core.Checkbox>
          </div>
        ))}
      </div>
    </Styled>
  );
};
