import { background, filter, shadow } from './elevation';
import colors from './colors/';
import AdminBackgroundLight from '../assets/images/admin-bg-light.jpg';
import AdminBackgroundDark from '../assets/images/admin-bg-dark.jpg';

const mixin = {
  validateMode: mode => colors => {
    if (typeof colors === 'string') {
      return colors;
    }
    const customColors = {
      light: colors.light,
      dark: colors.dark,
    };
    return customColors[mode];
  },
  backgroundImageTheme: mode => {
    const srcBackground = {
      light: AdminBackgroundLight,
      dark: AdminBackgroundDark,
    };
    return srcBackground[mode];
  },
  surfaceTheme: mode => scale => colors.surface[mode][scale],
  shadowTheme: mode => scale => shadow[mode][scale],
  backgroundTheme: mode => scale => background[mode][scale],
  filterTheme: mode => scale => filter[mode][scale],
  pxToRem: value => `${value / 16}rem`,
};

export default mixin;
