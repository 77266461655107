import React from 'react';
import { any, func, string, bool, shape, array } from 'prop-types';
import { Localization } from 'connex-cds';
import { MenuItem, InputAdornment } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { omit } from 'lodash';
import { getIn } from 'formik';

import { withFormikField } from '../../hoc';
import { FormTooltip, CustomIcon } from '..';

import { TextFieldStyled, MenuItemStyled } from './styles';

const Select = ({
  name,
  value,
  disabled,
  required,
  clearable,
  testId,
  onChange,
  isSubmitting,
  labelStringId,
  errors,
  options,
  infoTooltipStringId,
  setFieldTouched,
  touched,
  ...otherProps
}) => {
  const translateMessage = Localization.useTranslateMessage();

  const handleOnChange = e => {
    onChange(name, e.target.value);
  };

  const hasError = !!getIn(errors, name) && getIn(touched, name);
  const errorText = hasError ? errors[name] : '';

  return (
    <TextFieldStyled
      select
      name={name}
      value={value}
      data-testid={testId}
      label={translateMessage(labelStringId)}
      error={hasError}
      helperText={translateMessage(errorText)}
      disabled={disabled || isSubmitting}
      required={required}
      onChange={handleOnChange}
      InputProps={{
        endAdornment: infoTooltipStringId && (
          <FormTooltip
            arrow
            title={translateMessage(infoTooltipStringId)}
            placement="top-start"
            PopperProps={{ disablePortal: true }}
          >
            <InputAdornment position="end">
              <InfoOutlined />
            </InputAdornment>
          </FormTooltip>
        ),
      }}
      {...omit(otherProps, ['setFieldTouched', 'setFieldValue'])}
    >
      {!required && clearable && (
        <MenuItemStyled key={-1} value="">
          <CustomIcon iconName="highlightOffOutlinedIcon" size="small" />
          {translateMessage('general_select_clearSelection')}
        </MenuItemStyled>
      )}
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option?.translationKey ? translateMessage(option.translationKey) : option.label}
        </MenuItem>
      ))}
    </TextFieldStyled>
  );
};

Select.propTypes = {
  errors: shape({}).isRequired,
  disabled: bool,
  required: bool,
  clearable: bool,
  infoTooltipStringId: string,
  isSubmitting: bool,
  labelStringId: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  options: array.isRequired,
  testId: string,
  value: any.isRequired,
  setFieldTouched: func.isRequired,
  touched: shape({}).isRequired,
};

Select.defaultProps = {
  disabled: false,
  required: false,
  clearable: false,
  infoTooltipStringId: '',
  options: [],
  isSubmitting: false,
  value: {},
};

export default withFormikField(Select);
