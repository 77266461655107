import { http } from 'connex-cds';

export default {
  listCarriers: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/carriers`, queryParams }),
  createCarrier: ({ entityRef, carrier }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/carriers`,
      data: carrier,
    }),
  updateCarrier: ({ entityRef, carrierRef, data }) =>
    http.patch({
      apiName: 'company-admin',
      path: `/${entityRef}/carriers/${carrierRef}`,
      data,
    }),
};
