import React from 'react';
import { Field } from 'formik';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import { array, bool, func, number, object } from 'prop-types';

import { SelectWithFormikField, AutocompleteWithFormikField } from '../../../../commons';
import { usePartners } from '../../../../api/hooks';
import { parseProductOptions, parseProjectOptions, parsePurchaseOrderOptions } from '../../../../api/adapters';
import { MappingSection, MappingSectionControls, MappingSectionIndex, MappingData } from './styles';

const ProductMappingItem = ({
  inventoryLocationIndex,
  productMappingIndex,
  productMappingItem,
  partnerOptions,
  isLoadingPartners,
  setFieldValue,
  removeItem,
}) => {
  const partnerRef = productMappingItem?.partner?.value;

  const {
    ListPartnerProjects: useProjectsList,
    ListPartnerProjectProducts: useProductsList,
    ListPartnerPurchaseOrders: usePurchaseOrdersList,
  } = usePartners();
  const { data: projectOptions, isLoading: isLoadingProjects } = useProjectsList({
    partnerRef,
    adapter: parseProjectOptions,
  });
  const { data: productOptions, isLoading: isLoadingProducts } = useProductsList({
    partnerRef,
    projectRefs: productMappingItem.projects?.map(project => project.value),
    adapter: parseProductOptions,
  });
  const { data: purchaseOrderOptions, isLoading: isLoadingPurchaseOrders } = usePurchaseOrdersList({
    partnerRef,
    adapter: parsePurchaseOrderOptions,
  });

  const partnerControlName = `inventoryLocations.${inventoryLocationIndex}.productMappingList.${productMappingIndex}.partner`;
  const projectsControlName = `inventoryLocations.${inventoryLocationIndex}.productMappingList.${productMappingIndex}.projects`;
  const productsControlName = `inventoryLocations.${inventoryLocationIndex}.productMappingList.${productMappingIndex}.products`;
  const purchaseOrdersControlName = `inventoryLocations.${inventoryLocationIndex}.productMappingList.${productMappingIndex}.purchaseOrders`;

  const handlePartnerChange = (setFieldValue, prevValue) => (name, value) => {
    setFieldValue(name, value);
    if (prevValue !== value) {
      setFieldValue(projectsControlName, []);
      setFieldValue(productsControlName, []);
      setFieldValue(purchaseOrdersControlName, []);
    }
  };

  const handleProjectsChange = (setFieldValue, prevValue) => (name, value) => {
    setFieldValue(name, value);
    if (prevValue !== value) {
      setFieldValue(productsControlName, []);
    }
  };

  return (
    <MappingSection>
      <MappingSectionControls>
        <MappingSectionIndex>{productMappingIndex + 1}</MappingSectionIndex>
        <IconButton onClick={removeItem}>
          <Delete />
        </IconButton>
      </MappingSectionControls>
      <MappingData>
        <Field
          testId="select-productmapping-partner"
          labelStringId="products_addProduct_partner"
          component={SelectWithFormikField}
          name={partnerControlName}
          disabled={isLoadingPartners}
          required
          options={partnerOptions}
          customOnChange={handlePartnerChange(setFieldValue, productMappingItem?.partner)}
        />
        <Field
          testId="autocomplete-productmapping-projects"
          labelStringId="products_addProduct_projects"
          component={AutocompleteWithFormikField}
          name={projectsControlName}
          disabled={!productMappingItem.partner || isLoadingProjects}
          options={projectOptions}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          customOnChange={handleProjectsChange(setFieldValue, productMappingItem?.projects)}
        />
        <Field
          testId="autocomplete-productmapping-products"
          labelStringId="products_addProduct_products"
          component={AutocompleteWithFormikField}
          multiple
          name={productsControlName}
          disabled={!productMappingItem.partner || !productMappingItem.projects?.length || isLoadingProducts}
          options={productOptions}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
        />
        <Field
          testId="autocomplete-productmapping-purchaseOrders"
          labelStringId="products_addProduct_purchaseOrders"
          component={AutocompleteWithFormikField}
          name={purchaseOrdersControlName}
          disabled={!productMappingItem.partner || isLoadingPurchaseOrders}
          options={purchaseOrderOptions}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
        />
      </MappingData>
    </MappingSection>
  );
};

ProductMappingItem.propTypes = {
  inventoryLocationIndex: number.isRequired,
  productMappingIndex: number.isRequired,
  productMappingItem: object.isRequired,
  partnerOptions: array.isRequired,
  isLoadingPartners: bool,
  setFieldValue: func.isRequired,
  removeItem: func.isRequired,
};

export default ProductMappingItem;
