import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';

export const ContainerStyled = styled.div`
  ${({ theme }) => css`
    padding: 20px;
    border-radius: 20px;
    border: 1px solid
      ${theme.validateMode({
        light: theme.colorsCA.neutral[100],
        dark: '#938F99',
      })};
    background: ${theme.validateMode({
      light: 'rgba(252, 251, 255, 0.40)',
      dark: 'rgba(136, 136, 136, 0.23)',
    })};
    box-shadow: ${theme.shadowCA(6)};
    backdrop-filter: blur(24px);
  `}
`;

export const GridContainerStyled = styled(Grid)`
  ${({ theme }) => css`
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  `}
`;

export const Header3Styled = styled.h3`
  ${({ theme }) => css`
    font-family: Work Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin: 0;
  `}
`;

export const SpanStyled = styled.span`
  ${({ theme }) => css`
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[60], dark: theme.colorsCA.neutral[80] })};
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  `}
`;

export const ParagraphStyled = styled.p`
  ${({ theme }) => css`
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
    word-break: break-word;
  `}
`;
