import { http } from 'connex-cds';

export default {
  listVehicleTypes: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/vehicle-types`, queryParams }),
  createVehicleType: ({ entityRef, vehicleType }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/vehicle-types`,
      data: vehicleType,
    }),
  updateVehicleType: ({ entityRef, vehicleTypeRef, data }) =>
    http.patch({
      apiName: 'company-admin',
      path: `/${entityRef}/vehicle-types/${vehicleTypeRef}`,
      data,
    }),
};
