import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const SwitchLabelStyled = styled(Stack)(
  () => css`
    flex-direction: row;
    align-items: center;

    .MuiTypography-root {
      text-align: right;
    }
  `
);
