import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, string, bool, shape } from 'prop-types';
import Grid from '@mui/material/Grid';

import { TextInputWithFormikField, SelectWithFormikField, CheckboxWithFormikField } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { ConcreteClassProfileTabContainer } from './styles';

const showConcreteClassSubtypeField = value => {
  return value === 'consistence' ? true : false;
};

const showConcreteClassUOMField = value => {
  return ['consistence', 'slumpLossRate', 'temperature'].includes(value);
};

const showConcreteClassNominalValueField = value => {
  return ['air', 'consistence', 'slumpLossRate'].includes(value);
};

const showConcreteClassMinimumAndMaximumValueField = value => {
  return ['air', 'consistence', 'temperature'].includes(value);
};

const ConcreteClassProfileTab = ({
  concreteClassesStatusOptions,
  mode,
  uomOptions,
  isLoadingUom,
  concreteClassesTypeOptions,
  concreteClassType,
  concreteClassesSubtypeOptions,
}) => {
  return (
    <ConcreteClassProfileTabContainer>
      <h3 className="concrete-classes-profile-title">
        <Localization.Translate stringId="concreteClasses_profileTab_title" />
      </h3>

      <Form className="concrete-classes-profile-form">
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  labelStringId="concreteClasses_profileTab_concreteClassId"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="concreteClassId"
                  tooltipInfoStringId="concreteClasses_profileTab_concreteClassId_tooltip"
                  required
                  disabled={mode === DIALOG_TYPES.EDIT}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  labelStringId="concreteClasses_profileTab_concreteClassName"
                  component={TextInputWithFormikField}
                  variant="outlined"
                  name="concreteClassName"
                  required
                />
              </Grid>
              {concreteClassType && showConcreteClassSubtypeField(concreteClassType.value) && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    testId="select-class-subtype"
                    labelStringId="concreteClasses_profileTab_concreteClassSubtype"
                    component={SelectWithFormikField}
                    name="concreteClassSubtype"
                    options={concreteClassesSubtypeOptions}
                  />
                </Grid>
              )}
              <Grid item sx={{ marginLeft: 2 }} xs={12}>
                <Field
                  labelStringId="concreteClasses_profileTab_defaultSettings"
                  component={CheckboxWithFormikField}
                  name="concreteClassesDefaultSettings"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Field
                  fullWidth
                  testId="select-class-type"
                  labelStringId="concreteClasses_profileTab_concreteClassType"
                  component={SelectWithFormikField}
                  name="concreteClassType"
                  options={concreteClassesTypeOptions}
                  required
                />
              </Grid>
              {concreteClassType && showConcreteClassUOMField(concreteClassType.value) && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    testId="select-batch-uom"
                    labelStringId="concreteClasses_profileTab_concreteClassUOM"
                    component={SelectWithFormikField}
                    name="concreteClassUOM"
                    disabled={isLoadingUom}
                    options={uomOptions}
                  />
                </Grid>
              )}
              {concreteClassType && showConcreteClassNominalValueField(concreteClassType.value) && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    testId="select-nominal-value"
                    type="number"
                    labelStringId="concreteClasses_profileTab_concreteClassNominalValue"
                    tooltipInfoStringId="concreteClasses_profileTab_nominalValue_tooltip"
                    component={TextInputWithFormikField}
                    inputProps={{ min: 0 }}
                    name="concreteClassNominalValue"
                  />
                </Grid>
              )}
              {concreteClassType && showConcreteClassMinimumAndMaximumValueField(concreteClassType.value) && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    testId="select-minimum-value"
                    type="number"
                    labelStringId="concreteClasses_profileTab_concreteClassMinimumValue"
                    component={TextInputWithFormikField}
                    inputProps={{ min: 0 }}
                    name="concreteClassMinimumValue"
                  />
                </Grid>
              )}
              {concreteClassType && showConcreteClassMinimumAndMaximumValueField(concreteClassType.value) && (
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    testId="select-maximum-value"
                    type="number"
                    labelStringId="concreteClasses_profileTab_concreteClassMaximumValue"
                    component={TextInputWithFormikField}
                    inputProps={{ min: 0 }}
                    name="concreteClassMaximumValue"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Field
                  fullWidth
                  labelStringId="concreteClasses_profileTab_concreteClassStatus"
                  component={SelectWithFormikField}
                  name="concreteClassStatus"
                  options={concreteClassesStatusOptions}
                  infoTooltipStringId="concreteClasses_profileTab_concreteClassesStatus_tooltip"
                  testId="select-concrete-class-status"
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </ConcreteClassProfileTabContainer>
  );
};

ConcreteClassProfileTab.propTypes = {
  concreteClassesStatusOptions: array.isRequired,
  mode: string.isRequired,
  isLoadingUom: bool.isRequired,
  uomOptions: array.isRequired,
  concreteClassesTypeOptions: array.isRequired,
  concreteClassType: shape({}),
  concreteClassesSubtypeOptions: array.isRequired,
};

ConcreteClassProfileTab.defaultProps = {
  concreteClassType: {},
};
export default ConcreteClassProfileTab;
