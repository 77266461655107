import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MuiStepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';

export const StepLabelStyled = styled(MuiStepLabel)`
  ${({ theme, onClick }) => css`
    gap: 16px;
    cursor: ${!!onClick ? 'pointer' : 'default'};
    &.Mui-disabled {
      cursor: ${!!onClick ? 'pointer' : 'default'};
    }

    .MuiStepLabel-iconContainer {
      padding: 0;
    }

    .MuiStepLabel-label {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};

      &.Mui-active,
      &.Mui-completed,
      &.Mui-error {
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
        font-weight: 700;
      }
    }
  `}
`;

export const StepConnectorStyled = styled(StepConnector)`
  ${({ theme }) => css`
    height: 28px;
    width: 30px;
    display: flex;
    justify-content: center;
    margin: 0;

    &.Mui-completed {
      .MuiStepConnector-line {
        background-color: ${theme.colorsCA.green[50]};
      }
    }

    .MuiStepConnector-line {
      border: none;
      border-radius: 25px;
      background: ${theme.validateMode({
        light: 'rgba(0, 43, 84, 0.14)',
        dark: 'linear-gradient(0deg, rgba(0, 43, 84, 0.14) 0%, rgba(0, 43, 84, 0.14) 100%), #FFFBFE',
      })};
      width: 3px;
    }
  `}
`;

export const CustomStepIconRootStyled = styled('div')`
  ${({ theme, ownerState }) => css`
    width: 30px;
    height: 30px;
    background-color: ${theme.validateMode({ light: 'rgba(0, 43, 84, 0.14)', dark: theme.colorsCA.neutral[99] })};
    border-radius: 100px;
    border: 3px solid ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: '#403a4c' })};
    box-shadow: ${theme.validateMode({
      light: '0px 4px 20px 0px rgba(101, 119, 136, 0.2)',
      dark: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    })};

    display: flex;
    align-items: center;
    justify-content: center;
    ${ownerState.active ? `background-color: ${theme.colorsCA.green[80]};` : ''}
    ${ownerState.completed ? `background-color: ${theme.colorsCA.green[50]};` : ''}
  ${ownerState.error ? `background-color: ${theme.colorsCA.red[50]};` : ''}

  .CustomStepIcon-completedIcon, .CustomStepIcon-errorIcon {
      color: ${theme.colorsCA.neutral[100]};
      z-index: 1;
      font-size: 18px;
    }
  `}
`;
