import cn from 'classnames';
import { Form, Layout } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const Styled = styled(Layout.Container)`
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .app-selection {
    flex: 1;
  }
`;

export const NewApp = ({ onChange, allApps }) => {
  const { values } = Form.useFormContext();

  const [selectedApp, setSelectedApp] = React.useState();

  const apps = React.useMemo(() => {
    // Only include apps that are assignable.
    return allApps?.filter?.(a => a.isAssignableToProfile) || [];
  }, [allApps]);

  const selectedApps = React.useMemo(() => {
    return Object.keys(values?.permissions || {}) || [];
  }, [values?.permissions]);

  const unselectedApps = React.useMemo(() => {
    return apps?.filter?.(app => !selectedApps.includes(app.crn)) || [];
  }, [apps, selectedApps]);

  const handleAppChange = React.useCallback(
    e => {
      setSelectedApp(find(apps, { crn: e.target.value }));
    },
    [apps]
  );

  React.useEffect(() => {
    if (selectedApp) {
      onChange(selectedApp.crn);
      setSelectedApp(undefined);
    }
  }, [onChange, selectedApp]);

  if (!unselectedApps?.length) return null;

  return (
    <Styled className={cn('new-app')}>
      <div className="app-selection">
        <select value={selectedApp} onChange={handleAppChange} style={{ height: '25px', padding: '0 10px' }}>
          <option value="">Add App...</option>
          {unselectedApps.map(app => (
            <option value={app.crn}>{app.name}</option>
          ))}
        </select>
      </div>
    </Styled>
  );
};
