import { Core, Layout } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useApps } from '../../../api/hooks';
import { PermissionsPicker } from './PermissionsPicker';

const Styled = styled(Layout.Container)`
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:first-child {
    flex: 1;
  }

  .actions {
    padding-left: 20px;
  }
  .permissions-picker {
    margin: 0 0 10px 30px;
    padding: 0;
  }
`;

const getInitialValues = (availablePermissions, actualPermissions = {}) => {
  const allPermissionsSetToFalse = availablePermissions.reduce((acc, permission) => {
    acc[permission] = false;
    return acc;
  }, {});

  return { ...allPermissionsSetToFalse, ...actualPermissions };
};

export const App = ({ app, onChange, onDelete }) => {
  const { data: allApps } = useApps();

  const availablePermissions = React.useMemo(() => {
    const targetAppDef = find(allApps, { crn: app.crn });

    if (Array.isArray(targetAppDef?.permissions)) {
      // Check for permission groups
      if (targetAppDef?.permissions?.[0]?.permissions?.length) {
        //Here we can expect the new array-of-permission-groups format.
        return targetAppDef?.permissions.reduce((acc, group) => {
          acc.push(...group.permissions.map(p => p.id));
          return acc;
        }, []);
      }
      // Here, we can expect the older, array-of-permission-objects format.
      return targetAppDef?.permissions.map(p => p.id);
    }

    // Here, we can expect the very old object format.
    return Object.keys(targetAppDef?.permissions || []);
  }, [allApps, app.crn]);

  const [permissions, setPermissions] = React.useState(getInitialValues(availablePermissions, app.permissions));

  const handleChange = React.useCallback(
    (permission, value) => {
      setPermissions(s => {
        const newPermissions = { ...s, [permission]: value };
        onChange(newPermissions);
        return newPermissions;
      });
    },
    [onChange]
  );

  return (
    <Styled className={cn('permission')}>
      <div>
        <div className="app-name">
          <h3>{app?.name || ''}</h3>
        </div>
        <PermissionsPicker
          permissions={permissions}
          onChange={handleChange}
          availablePermissions={availablePermissions}
        />
      </div>
      <div className="actions">
        <Core.Button stringId="delete" data-testid="delete-app-button" onClick={onDelete} type="primary" danger />
      </div>
    </Styled>
  );
};
