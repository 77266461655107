import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { productTypes } from '../queries';

export const useProductTypes = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['product-types', entityRef];

  const List = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['product-types', entityRef, queryString.stringify(queryParams)],
      queryFn: () => productTypes.listProductTypes({ entityRef, queryParams }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const Create = () =>
    useMutation({
      mutationFn: productType => productTypes.createProductType({ entityRef, productType }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  const Update = () =>
    useMutation({
      mutationFn: ({ productTypeRef, productType }) =>
        productTypes.updateProductType({ entityRef, productTypeRef, productType }),
      onError: context => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return { List, Create, Update };
};
