import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Card } from '@mui/material';

const CardSettingStyled = styled(Card)(
  ({ theme }) => css`
    width: 340px;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#49454F' })};
    .header {
      flex-direction: row;
      gap: 16px;
      align-items: center;
      .title-icon {
        margin: 8px;
      }
      .MuiTypography-root {
        font-size: 16px;
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[10], dark: theme.colorsCA.neutral[95] })};
      }
      .state-info {
        flex-direction: row;
        align-items: center;
        gap: 10px;
        .icon {
          width: 15px;
        }
        .MuiTypography-root {
          font-size: 14px;
        }
      }
    }
    .description {
      padding-top: 28px;
      font-size: 14px;
      color: ${theme.validateMode({ light: '#49454F', dark: theme.colorsCA.neutral[95] })};
    }
    .MuiCardActions-root {
      padding: 16px;
      justify-content: flex-end;
      .MuiButtonBase-root {
        font-size: 14px;
        border: ${theme.validateMode({ dark: theme.colorsCA.blue[90] })};
        background: ${theme.validateMode({ dark: theme.colorsCA.blue[90] })};
        color: ${theme.validateMode({ light: theme.colorsCA.darkBlue[10], dark: theme.colorsCA.blue[10] })};
        &:hover {
          background: ${theme.validateMode({
            light: `linear-gradient(0deg, rgba(0, 43, 84, 0.05) 0%, rgba(0, 43, 84, 0.05) 100%), ${theme.colorsCA.neutral[99]}`,
            dark: theme.colorsCA.blue[80],
          })};
        }
      }
    }
  `
);

export default CardSettingStyled;
