import { useState, useMemo, useCallback } from 'react';
import { Localization } from 'connex-cds';

import { useConcreteClasses } from '../../api/hooks';
import { getFilteredDataByProperties } from '../../commons/search-filter';
import AddConcreteClassDialog from './components/add-concrete-class-dialog';
import EditConcreteClassDialog from './components/edit-concrete-class-dialog';
import DeleteConcreteClassDialog from './components/delete-concrete-class-dialog';
import { EntityTable, SearchFilter, TableLayout, DialogManager, ActionButton } from '../../commons';
import { getConcreteClassesListColumns } from './tableConfig';
import { DIALOG_TYPES } from '../../constants';

const ConcreteClasses = () => {
  const [isOnEditDialog, setIsOnEditDialog] = useState(false);
  const translateMessage = Localization.useTranslateMessage();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentConcreteClass, setCurrentConcreteClass] = useState(null);
  const [dialog, setDialog] = useState(DIALOG_TYPES.NONE);
  const { List: useConcreteClassesList } = useConcreteClasses();
  const { data, isLoading } = useConcreteClassesList({ queryParams: { activeOnly: false } });

  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data, query: searchTerm, properties: ['id', 'name'] }),
    [data, searchTerm]
  );

  const setDelete = useCallback(
    concreteClass => () => {
      setDialog(DIALOG_TYPES.DELETE);
      setCurrentConcreteClass(concreteClass);
    },
    []
  );

  const concreteClassesListColumns = useMemo(
    () => getConcreteClassesListColumns({ setDeleteConcreteClass: setDelete, translateMessage }),
    [setDelete, translateMessage]
  );

  const resetDialogs = () => {
    if (isOnEditDialog) {
      setDialog(DIALOG_TYPES.EDIT);
      setIsOnEditDialog(false);
      return;
    }
    setCurrentConcreteClass(null);
    setDialog(DIALOG_TYPES.NONE);
  };

  const handleOnRowClick = params => {
    setCurrentConcreteClass(params.row);
    setDialog(DIALOG_TYPES.EDIT);
  };

  return (
    <TableLayout.ListLayoutContainer>
      <TableLayout.TableControls>
        <SearchFilter onSearchChange={setSearchTerm} disabled={isLoading} resultsCount={filteredData.length} />
        <ActionButton
          icon="addOutlinedIcon"
          label={<Localization.Translate stringId="concreteClasses_addConcreteClass_title" />}
          variant="outlined"
          onClick={() => setDialog(DIALOG_TYPES.ADD)}
        />
      </TableLayout.TableControls>
      <TableLayout.TableBody>
        <EntityTable
          checkboxSelection={false}
          getRowId={row => row.crn}
          rows={filteredData}
          columns={concreteClassesListColumns}
          isLoading={isLoading}
          onRowClick={handleOnRowClick}
        />
      </TableLayout.TableBody>
      <DialogManager screen={dialog}>
        <AddConcreteClassDialog identifier={DIALOG_TYPES.ADD} onClose={resetDialogs} />
        <EditConcreteClassDialog
          identifier={DIALOG_TYPES.EDIT}
          handleOnDelete={() => {
            setIsOnEditDialog(true);
            setDialog(DIALOG_TYPES.DELETE);
          }}
          onClose={resetDialogs}
          concreteClassData={currentConcreteClass}
        />
        ;
        <DeleteConcreteClassDialog
          identifier={DIALOG_TYPES.DELETE}
          onClose={resetDialogs}
          concreteClassData={currentConcreteClass}
        />
      </DialogManager>
    </TableLayout.ListLayoutContainer>
  );
};

export default ConcreteClasses;
