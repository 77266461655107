import { TWELVE_HOURS } from './time';

export const DEFAULT_OPTIONS = Object.freeze({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      cacheTime: TWELVE_HOURS,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
});
