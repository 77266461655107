import { useQuery, useQueries } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { partners } from '../queries';

export const usePartners = () => {
  const { entityRef } = useParams();

  const List = ({ adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['partners', entityRef],
      queryFn: () => partners.listPartners({ entityRef }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const ListPartnerProjects = ({ partnerRef, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['partner-projects', entityRef, partnerRef],
      queryFn: () => partners.listPartnerProjects({ entityRef, partnerRef }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const ListPartnerProjectProducts = ({ partnerRef, projectRefs, adapter = null } = {}) => {
    const queryResults = useQueries({
      queries: projectRefs?.map(projectRef => ({
        queryKey: ['partner-project-products', entityRef, partnerRef, projectRef],
        queryFn: () => partners.listPartnerProjectProducts({ entityRef, partnerRef, projectRef }),
        staleTime: Infinity,
      })),
    });

    const allData = queryResults?.map(q => (!adapter ? q.data || [] : adapter(q.data))).flat();
    return { isLoading: queryResults.map(q => q.isLoading).some(i => i), data: allData };
  };

  const ListPartnerPurchaseOrders = ({ partnerRef, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['partner-purchase-orders', entityRef, partnerRef],
      queryFn: () => partners.listPartnerPurchaseOrders({ entityRef, partnerRef }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  return { List, ListPartnerProjects, ListPartnerProjectProducts, ListPartnerPurchaseOrders };
};
