import React from 'react';
import { Form } from 'connex-cds';

import { useRoles } from '../../api/hooks';
import { RolesEditor } from './RolesEditor';
import config from './config';

const { DETAIL_TYPES, ListAndDetail } = Form;

const Roles = () => {
  const { List: useRolesList, Create: useCreateRole, Update: useUpdateRole } = useRoles();
  const listRoles = useRolesList();
  const { mutateAsync: createRole } = useCreateRole();
  const { mutateAsync: updateRole } = useUpdateRole();

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.VIEW}
      Editor={RolesEditor}
      showHeader={false}
      config={config}
      listQuery={listRoles}
      createQuery={createRole}
      updateQuery={updateRole}
    />
  );
};

export default Roles;
