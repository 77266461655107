import React, { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';
import { useLocations, useMixes, usePartners, useProductTypes, useProducts, useUom } from '../../../../api/hooks';
import ProductFormView from '../product-form-view';
import { BOOLEAN_VALUES, STATUS_OPTIONS } from '../../../../constants';
import { isDevEnvironment, isLocalEnvironment } from '../../../../util/environment';
import { getMappedProductLocations } from '../../../../util/productLocationHelper';
import { INVENTORY_SOURCE_OPTIONS } from '../product-form-view/form-config';
import {
  parseLocations,
  parseMixOptions,
  parsePartnerOptions,
  parseProductTypesOptions,
  parseUomOptions,
} from '../../../../api/adapters';

export const initialValues = {
  productId: '',
  productName: '',
  productType: {},
  uom: '',
  locations: [],
  productStatus: '',
  mix: '',
  keepInInventory: false,
  inventorySource: '',
  inventoryLocations: [],
};

const AddProductDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { List: useListProductTypes } = useProductTypes();
  const { data: productTypesOptions, isLoading: isLoadingProductTypes } = useListProductTypes({
    adapter: parseProductTypesOptions,
  });
  const { List: listMixes } = useMixes();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const { List: useListLocations } = useLocations();
  const { data: locationOptions, isLoading: isLoadingLocations } = useListLocations({ adapter: parseLocations });
  const { List: listPartners } = usePartners();
  const { data: partnerOptions, isLoading: isLoadingPartners } = listPartners({ adapter: parsePartnerOptions });
  const { Create: useCreateProduct } = useProducts();
  const { mutateAsync: createProduct } = useCreateProduct();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { data: mixesOptions, isLoading: isLoadingMixes } = listMixes({
    queryParams: { activeOnly: true },
    adapter: parseMixOptions,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleLocationsChange = (setFieldValue, value) => {
    const inventoryLocations =
      value
        ?.filter(x => !!x.trackInventory)
        ?.map(location => ({
          ...location,
          safetyStock: '',
          reorderStock: '',
          maxStock: '',
        })) ?? [];
    setFieldValue('inventoryLocations', inventoryLocations);
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      productId,
      productName,
      productStatus,
      productType,
      uom,
      locations,
      keepInInventory,
      inventorySource,
      inventoryLocations,
    } = values;

    const locationsObject = locations.reduce((accumulator, location) => {
      return { ...accumulator, [location.value]: true };
    }, {});

    formikBag.setSubmitting(true);

    try {
      const msg = 'productNotification_productAdded';
      const inventory = {
        source: keepInInventory ? inventorySource?.value : undefined,
      };
      const productLocationsList =
        keepInInventory && (isDevEnvironment || isLocalEnvironment)
          ? getMappedProductLocations(inventoryLocations)
          : undefined;
      const product = {
        id: productId,
        name: productName,
        type: productType.value,
        uomCode: uom.value,
        locations: locations.length === locationOptions.length ? { all: true } : locationsObject,
        status: productStatus.value,
        inventory,
        productLocations: productLocationsList,
        trackInventory: keepInInventory ? BOOLEAN_VALUES.Yes : BOOLEAN_VALUES.No,
      };

      await createProduct({ product });
      notification.success(translateMessage(msg));
    } catch (error) {
      console.error(error);
    }

    formikBag.setSubmitting(false);
    formikBag.resetForm();
    handleClose();
  };

  return (
    <ProductFormView
      open={isOpen}
      onClose={handleClose}
      mode="ADD"
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      productTypesOptions={productTypesOptions}
      isLoadingProductTypes={isLoadingProductTypes}
      mixesOptions={mixesOptions}
      isLoadingMixes={isLoadingMixes}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      locationOptions={locationOptions}
      isLoadingLocations={isLoadingLocations}
      productStatusOptions={STATUS_OPTIONS}
      inventorySourceOptions={INVENTORY_SOURCE_OPTIONS}
      onLocationsChange={handleLocationsChange}
      partnerOptions={partnerOptions}
      isLoadingPartners={isLoadingPartners}
    />
  );
};

AddProductDialog.propTypes = {
  onClose: func,
};

AddProductDialog.defaultProps = {
  onClose: () => {},
};

export default AddProductDialog;
