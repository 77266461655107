import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

yup.addMethod(yup.string, 'email', function validateEmail(message) {
  return this.matches(/^[\w-.]+([\w-.+])+@([\w-]+\.)+[a-zA-Z]{2,4}$/g, {
    message,
    name: 'email',
    excludeEmptyString: true,
  });
});

const contactInfoValidationSchema = yup.object().shape({
  username: yup.string().email('error_invalidEmailFormat').required('required'),
});

const roleValidationSchema = yup.object().shape({
  roleRef: yup.string().trim().required('required'),
});

export const stepValidationSchemas = [contactInfoValidationSchema, roleValidationSchema];

export const validationSchema = stepValidationSchemas.reduce(
  (composedSchema, stepSchema) => composedSchema.concat(stepSchema),
  yup.object().shape({})
);

export const initialValues = { username: '', roleRef: '' };
