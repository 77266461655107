import { Formik } from 'formik';
import { Localization } from 'connex-cds';
import { Button } from '@mui/material';
import { array, bool, func, string, object } from 'prop-types';

import { DialogTitle, CustomIcon, DialogContent, DialogActions, DialogContainer } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { validationSchema } from './form-config';
import DriverProfileTab from '../driver-profile-tab';

const DriverFormView = ({ open, onClose, mode, initialValues, onSubmit, statusOptions, handleOnDelete }) => {
  const handleClose = () => {
    onClose();
  };

  const handleCloseDialog = (_e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const renderHeader = mode => {
    let icon;
    let stringId;

    switch (mode) {
      case DIALOG_TYPES.ADD:
        icon = 'addOutlinedIcon';
        stringId = 'drivers_addDriver_title';
        break;

      case DIALOG_TYPES.EDIT:
        icon = 'editOutlinedIcon';
        stringId = 'drivers_editDriver_title';
        break;

      default:
        icon = '';
        stringId = '';
        break;
    }

    return (
      <>
        <CustomIcon iconName={icon} size={'medium'} />
        <Localization.Translate stringId={stringId} />
      </>
    );
  };

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
      <DialogTitle onDelete={handleOnDelete} onClose={handleClose}>
        {renderHeader(mode)}
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ isSubmitting, dirty, isValid, handleSubmit, setFieldValue }) => {
          return (
            <>
              <DialogContent>
                <DriverProfileTab statusOptions={statusOptions} mode={mode} setFieldValue={setFieldValue} />
              </DialogContent>
              <DialogActions
                rightSide={
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !dirty || !isValid}
                    onClick={handleSubmit}
                  >
                    <Localization.Translate stringId="general_dialogAction_save" />
                  </Button>
                }
              />
            </>
          );
        }}
      </Formik>
    </DialogContainer>
  );
};

DriverFormView.propTypes = {
  handleOnDelete: func,
  open: bool.isRequired,
  onClose: func.isRequired,
  mode: string.isRequired,
  initialValues: object.isRequired,
  onSubmit: func.isRequired,
  statusOptions: array.isRequired,
};

export default DriverFormView;
