import { useState } from 'react';
import { Localization } from 'connex-cds';
import { Stack, Typography } from '@mui/material';

// COMMENTED UNTIL FEATURE IS REQUIRED
// import { CustomButton } from '../../commons';
import { CardSetting } from './components';
import BillingAdminStyled from './styles';
import { CARD_OPTIONS } from './config';

const Billing = () => {
  const translateMessage = Localization.useTranslateMessage();
  const [cards] = useState(CARD_OPTIONS);

  return (
    <BillingAdminStyled className="billing-admin-main">
      <header>
        <Stack className="text-content">
          <Typography variant="h3">{translateMessage('billing_home_title')}</Typography>
          {/* COMMENTED UNTIL COPY IS DEFINED */}
          {/* <Typography>{translateMessage('billing_home_description')}</Typography> */}
        </Stack>
        {/* COMMENTED UNTIL FEATURE IS REQUIRED */}
        {/* <CustomButton>{translateMessage('billing_home_button')}</CustomButton> */}
      </header>
      <main>
        <Stack className="config-options">
          {cards.map(card => (
            <CardSetting
              key={card.id}
              state={card.state}
              title={card.title}
              description={card.description}
              icon={card.icon}
            />
          ))}
        </Stack>
      </main>
    </BillingAdminStyled>
  );
};

export default Billing;
