import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, string, bool } from 'prop-types';
import { Grid } from '@mui/material';

import { CheckboxWithFormikField, TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { VehicleTypeProfileContainer } from './styles';
import { DIALOG_TYPES } from '../../../../constants';

const VehicleTypeProfileTab = ({ mode, statusOptions, uomOptions, isLoadingUomOptions }) => {
  return (
    <VehicleTypeProfileContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="vehicleTypes_profileTab_title" />
      </h3>
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicleTypes_profileTab_vehicleTypeId"
              component={TextInputWithFormikField}
              variant="outlined"
              name="vehicleTypeId"
              tooltipInfoStringId="vehicleTypes_profileTab_vehicleTypeId_tooltip"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item container xs={6} columnSpacing={3}>
            <Grid item xs={6}>
              <Field
                fullWidth
                labelStringId="vehicleTypes_profileTab_defaultLoadSize"
                component={TextInputWithFormikField}
                variant="outlined"
                name="defaultLoadSize"
                tooltipInfoStringId="vehicleTypes_profileTab_defaultLoadSize_tooltip"
                required
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                fullWidth
                labelStringId="vehicleTypes_profileTab_defaultLoadSizeUom"
                component={SelectWithFormikField}
                name="defaultLoadSizeUOM"
                options={uomOptions}
                disabled={isLoadingUomOptions}
                testId="select-default-load-size-uom"
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicleTypes_profileTab_vehicleName"
              component={TextInputWithFormikField}
              variant="outlined"
              name="vehicleTypeName"
              tooltipInfoStringId="vehicleTypes_profileTab_vehicleName_tooltip"
              required
            />
          </Grid>
          <Grid item container xs={6} columnSpacing={3}>
            <Grid item xs={6}>
              <Field
                fullWidth
                labelStringId="vehicleTypes_profileTab_maxLoadSize"
                component={TextInputWithFormikField}
                variant="outlined"
                name="maxLoadSize"
                tooltipInfoStringId="vehicleTypes_profileTab_maxLoadSize_tooltip"
                required
                type="number"
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                fullWidth
                labelStringId="vehicleTypes_profileTab_maxLoadSizeUom"
                component={SelectWithFormikField}
                name="maxLoadSizeUOM"
                options={uomOptions}
                disabled={isLoadingUomOptions}
                testId="select-max-load-size-uom"
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicleTypes_profileTab_vehicleTypeStatus"
              component={SelectWithFormikField}
              name="vehicleTypeStatus"
              options={statusOptions}
              testId="select-vehicle-type-status"
              infoTooltipStringId="vehicleTypes_profileTab_vehicleTypeStatus_tooltip"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicleTypes_profileTab_trackingColor"
              component={TextInputWithFormikField}
              variant="outlined"
              name="trackingColor"
              tooltipInfoStringId="vehicleTypes_profileTab_trackingColor_tooltip"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              fullWidth
              labelStringId="vehicleTypes_profileTab_defaultValue"
              component={CheckboxWithFormikField}
              name="defaultValue"
            />
          </Grid>
        </Grid>
      </Form>
    </VehicleTypeProfileContainer>
  );
};

VehicleTypeProfileTab.propTypes = {
  statusOptions: array.isRequired,
  mode: string.isRequired,
  uomOptions: array.isRequired,
  isLoadingUomOptions: bool.isRequired,
};

export default VehicleTypeProfileTab;
