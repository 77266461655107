import { Localization } from 'connex-cds';
import { ConfirmationDialog } from '../../../../commons';
import styled from '@emotion/styled';

import { trimWithDots } from '../../../../util/strings';
import styles from './styles';

const Styled = styled(ConfirmationDialog)`
  ${styles}
`;

const DeleteUserDialog = ({ open, onClose, onConfirm, userData }) => {
  return (
    <Styled open={open} onConfirm={onConfirm} onClose={onClose} confirmText="delete" color="delete">
      <h3 className="delete-user-title">
        <Localization.Translate stringId="deleteUserDialog_title" />
      </h3>
      <p className="delete-user-description">
        <Localization.Translate
          stringId="deleteUserDialog_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: `${userData?.firstName || ''} ${userData?.lastName || ''}`,
            email: userData?.email || '',
          }}
        />
      </p>
    </Styled>
  );
};

export default DeleteUserDialog;
