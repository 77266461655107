import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const HomeStyled = styled.main`
  ${css`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const MessageSectionStyled = styled.section`
  ${() => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    h3 {
      font-size: 22px;
      font-weight: 600;
    }

    p {
      width: 590px;
      font-size: 16px;
      font-weight: 400;
      margin: 0;
    }
  `}
`;

export const FilterSectionStyled = styled.section`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 30px;

    .tooltop__title {
      display: flex;
      flex-direction: row;
    }
  `}
`;

export const CardSectionStyled = styled.section`
  ${() => css`
    display: flex;
    flex: 1 1 auto;
    padding: 1px 1px 10px 1px;

    .card {
    }
  `}
`;

export const CustomTooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 375,
    backgroundColor: theme.colorsCA.blue[30],
    marginLeft: '30px !important',
    borderRadius: 12,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colorsCA.blue[30],
    width: '28px !important',
    height: '39px !important',
    marginLeft: '-28px !important',
  },
}));
