import { Form } from 'formik';
import { Localization } from 'connex-cds';

import { RoleRadioGroup } from '../../../../commons';

import { ContainerStyled } from './styles';

const RoleTab = ({ roleList }) => {
  return (
    <ContainerStyled className="role">
      <h3 className="role-title">
        <Localization.Translate stringId="addUserDialog_roleTab_title" />
      </h3>
      <p className="role-description">
        <Localization.Translate stringId="addUserDialog_roleTab_description" />
      </p>
      <Form className="role-form">
        <RoleRadioGroup name="roleRef" roleList={roleList} />
      </Form>
    </ContainerStyled>
  );
};

export default RoleTab;
