import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ContainerStyled = styled('div')`
  ${({ theme }) => css`
    .contact-info-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 25px;
    }

    .contact-info-description {
      line-height: 24px;
      font-size: 16px;
      margin-bottom: 40px;
    }

    .contact-info-form {
      display: flex;

      .MuiTextField-root {
        flex: 1;
      }

      .MuiInputBase-input {
        font-size: 16px;
      }
    }
  `}
`;
