import { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { Localization, useNotification } from 'connex-cds';

import { ConfirmationDialog } from '../../../../commons';
import { useVehicles } from '../../../../api/hooks';
import { DELETED_STATUS } from '../../../../constants/common';
import { trimWithDots } from '../../../../util/strings';

const DeleteVehicleDialog = ({ onClose, vehicleData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Update: useUpdateVehicle } = useVehicles();
  const { mutateAsync: updateVehicle } = useUpdateVehicle();

  const handleDelete = async () => {
    try {
      if (vehicleData?.crn) {
        await updateVehicle({ vehicleRef: vehicleData.crn, vehicle: { status: DELETED_STATUS } });
        notification.success(translateMessage('vehicles_notification_vehicleDeleted'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <ConfirmationDialog
      open={isOpen}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmText="delete"
      color="delete"
    >
      <h3 className="delete-title">
        <Localization.Translate stringId="vehicles_deleteDialog_title" />
      </h3>
      <p className="delete-description">
        <Localization.Translate
          stringId="vehicles_deleteDialog_description"
          values={{
            strong: chunks => <strong>{trimWithDots(chunks, 100)}</strong>,
            name: vehicleData?.name,
          }}
        />
      </p>
    </ConfirmationDialog>
  );
};

DeleteVehicleDialog.propTypes = {
  onClose: func.isRequired,
  vehicleData: shape({
    crn: string.isRequired,
    name: string.isRequired,
  }),
};

export default DeleteVehicleDialog;
