import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import { array, string } from 'prop-types';
import { Grid } from '@mui/material';

import { TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { DIALOG_TYPES } from '../../../../constants';
import { ContactReasonProfileContainer } from './styles';

const ContactReasonProfileTab = ({ statusOptions, mode }) => {
  return (
    <ContactReasonProfileContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="contactReasons_profileTab_title" />
      </h3>
      <Form>
        <Grid container spacing={5}>
          <Grid item md={6} xs={12} order={{ xs: 1, md: 1 }}>
            <Field
              fullWidth
              labelStringId="contactReasons_profileTab_contactReasonId"
              component={TextInputWithFormikField}
              variant="outlined"
              name="contactReasonId"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 2, md: 2 }}>
            <Field
              fullWidth
              labelStringId="contactReasons_profileTab_contactReasonName"
              component={TextInputWithFormikField}
              variant="outlined"
              name="contactReasonName"
              required
            />
          </Grid>
          <Grid item md={6} xs={12} order={{ xs: 3, md: 3 }}>
            <Field
              fullWidth
              labelStringId="contactReasons_profileTab_contactReasonStatus"
              component={SelectWithFormikField}
              name="contactReasonStatus"
              options={statusOptions}
              testId="select-driver-status"
            />
          </Grid>
        </Grid>
      </Form>
    </ContactReasonProfileContainer>
  );
};

ContactReasonProfileTab.propTypes = {
  statusOptions: array,
  mode: string.isRequired,
};

export default ContactReasonProfileTab;
