import { http } from 'connex-cds';

export default {
  listLocations: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/locations`, queryParams }),
  createLocation: ({ entityRef, data }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/locations`,
      data,
    }),
  updateLocation: ({ entityRef, data, locationRef }) =>
    http.patch({
      apiName: 'company-admin',
      path: `/${entityRef}/locations/${locationRef}`,
      data,
    }),
};
