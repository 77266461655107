import React from 'react';
import { Localization } from 'connex-cds';
import { Stack, Typography } from '@mui/material';

import { CustomIcon } from '../../../../commons';
import TaxSettings from '../../../../assets/tax-settings.svg';

import { TaxDisclaimerStyled } from './styles';

const TaxDisclaimer = () => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <TaxDisclaimerStyled>
      <img src={TaxSettings} alt="taxes" />
      <Stack>
        <Stack className="title-wrapper">
          <CustomIcon iconName="taxation" className="title-icon" />
          <Typography className="title">{translateMessage('billing_tax_settings')}</Typography>
        </Stack>

        {/* TODO: Update this to the company admin in next story
          <Typography className="subtitle">{translateMessage('billing_hq_construction')}</Typography>
        */}

        {/* TODO: Update and show this description when the copy is provided
          <Typography className="text">{translateMessage('billing_tax_description')}</Typography>
        */}
      </Stack>
    </TaxDisclaimerStyled>
  );
};

export default TaxDisclaimer;
