import { TranslatedCell, CustomIcon } from '../../commons';
import { KebabOption } from '../../commons/entity-table';

export const getLocationsListColumns = ({ setDeleteLocation, translateMessage }) => [
  {
    field: 'id',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_id'} />,
  },
  {
    field: 'name',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_name'} />,
  },
  {
    field: 'address',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_address'} />,
    valueGetter: ({ row }) => {
      const address = row?.address?.address1 || '';
      return address;
    },
  },
  {
    field: 'vehicleCount',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_vehicleCount'} />,
    valueGetter: ({ row }) => {
      const availableVehicles = row?.availableVehicles || 0;
      return parseInt(availableVehicles);
    },
  },
  {
    field: 'pricing',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_pricing'} />,
    valueGetter: ({ row }) => {
      const priceBook = row?.priceBook?.name || '-';
      return priceBook;
    },
  },
  {
    field: 'status',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'general_tableHeader_status'} />,
    valueGetter: ({ row }) => translateMessage(row.status.toLowerCase()),
  },
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    width: 80,
    cellClassName: 'table-actions',
    getActions: params => [
      <KebabOption
        icon={<CustomIcon iconName="deleteIcon" />}
        label={translateMessage('delete')}
        onClick={setDeleteLocation(params.row)}
        showInMenu
      />,
    ],
  },
];
