import React from 'react';
import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';
import Grid from '@mui/material/Grid';
import { string } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';

import { TrailerProfileFormContainer } from './styles';

const TrailerProfileTab = ({ mode }) => {
  return (
    <TrailerProfileFormContainer>
      <h3 className="tab-title">
        <Localization.Translate stringId="trailers_addTrailer_profile" />
      </h3>

      <Form>
        <Grid container spacing={5} alignItems="baseline">
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="trailers_trailerTab_fieldTrailerId_label"
              component={TextInputWithFormikField}
              name="id"
              tooltipInfoStringId="trailers_trailerTab_fieldTrailerId_tooltip"
              required
              disabled={mode === DIALOG_TYPES.EDIT}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              fullWidth
              labelStringId="trailers_trailerTab_fieldTargetGross_label"
              component={TextInputWithFormikField}
              name="targetGrossVehicleWeight"
              tooltipInfoStringId="trailers_trailerTab_fieldTargetGross_tooltip"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              labelStringId="trailers_trailerTab_fieldTrailerName_label"
              component={TextInputWithFormikField}
              name="name"
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Field
              fullWidth
              labelStringId="trailers_trailerTab_fieldTargetNet_label"
              component={TextInputWithFormikField}
              name="targetNetVehicleWeight"
              tooltipInfoStringId="trailers_trailerTab_fieldTargetNet_tooltip"
            />
          </Grid>
          <Grid item container md={6} xs={12}>
            <Grid item container spacing={5}>
              <Grid item md={12}>
                <Field
                  fullWidth
                  labelStringId="trailers_trailerTab_fieldMaxGross_label"
                  component={TextInputWithFormikField}
                  name="maximumGrossVehicleWeight"
                  tooltipInfoStringId="trailers_trailerTab_fieldMaxGross_tooltip"
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  fullWidth
                  labelStringId="trailers_trailerTab_fieldMaxNet_label"
                  component={TextInputWithFormikField}
                  name="maximumNetVehicleWeight"
                  tooltipInfoStringId="trailers_trailerTab_fieldMaxNet_tooltip"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={5} md={6} xs={12}>
            <Grid item md={12}>
              <Field
                fullWidth
                labelStringId="trailers_trailerTab_fieldTrailerStatus_label"
                component={SelectWithFormikField}
                name="status"
                options={STATUS_OPTIONS}
                infoTooltipStringId="trailers_trailerTab_status_tooltip"
                required
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </TrailerProfileFormContainer>
  );
};

TrailerProfileTab.propTypes = {
  mode: string.isRequired,
};

export default TrailerProfileTab;
