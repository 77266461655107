import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ContainerStyled = styled('div')`
  ${({ theme }) => css`
    max-width: 551px;
    width: 100%;

    .role-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 25px;
    }
  `}
`;
