import { css } from '@emotion/react';
import styled from '@emotion/styled';
import MuiRadioGroup from '@mui/material/RadioGroup';

export const MuiRadioGroupStyled = styled(MuiRadioGroup)`
  ${({ theme }) => css`
    width: fit-content;

    .radio-option-label {
      gap: 8px;
      align-items: center;

      .MuiRadio-root {
        padding: 8px;

        .MuiSvgIcon-root {
          font-size: 24px;
        }
      }

      .MuiFormControlLabel-label {
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 16px;
        line-height: 24px;

        .info-icon {
          color: currentColor;
          font-size: 24px;
        }
      }
    }
  `}
`;
