import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

export const TaxationStyled = styled(Stack)(
  () => css`
    height: auto;
    margin: auto;
    width: 80%;

    @media screen and (min-width: 1280px) {
      width: 64%;
    }

    @media screen and (min-width: 1900px) {
      width: 40%;
    }
  `
);
