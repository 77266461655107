import Icon from '../../commons/custom-icon';
import { TranslatedCell } from '../../commons';
import { IconCell, KebabOption } from '../../commons/entity-table';
import ProductsLocationsCell from './components/locations-available-cell/LocationsAvailableCell';

export const DIALOGS = {
  DELETE: 'delete',
  EDIT: 'edit',
  ADD: 'add',
  NONE: null,
};

export const getProductListColumns = ({ handleSetProductRow = null, translateMessage }) => [
  {
    field: 'name',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'productsTable_header_productName'} />,
    renderCell: ({ row }) => <IconCell value={row?.name} icon="categoryOutlined" />,
    valueGetter: ({ row }) => row?.name,
  },
  {
    field: 'id',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'productsTable_header_productId'} />,
  },
  {
    field: 'type',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'productsTable_header_productType'} />,
  },
  {
    field: 'uomCode',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'productsTable_header_uom'} />,
  },
  {
    field: 'locations',
    sortable: false,
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'productsTable_header_location'} />,
    renderCell: ({ row }) => <ProductsLocationsCell product={row} />,
  },
  {
    field: 'taxable',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'productsTable_header_taxable'} />,
    renderCell: ({ row }) => {
      const taxable = row?.taxable ? translateMessage('yes') : translateMessage('no');
      return <span title={taxable}>{taxable}</span>;
    },
  },
  {
    field: 'status',
    flex: 1,
    renderHeader: () => <TranslatedCell stringId={'productsTable_header_productStatus'} />,
    valueGetter: ({ row }) => translateMessage(row.status.toLowerCase()),
  },
  {
    field: 'actions',
    type: 'actions',
    sortable: false,
    width: 80,
    cellClassName: 'table-actions',
    getActions: params => [
      <KebabOption
        icon={<Icon iconName="deleteIcon" />}
        label={translateMessage('delete')}
        onClick={handleSetProductRow(params.row)}
        showInMenu
      />,
    ],
  },
];
