import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, object } from 'prop-types';

import { useConcreteClasses, useUom } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { parseUomOptions } from '../../../../api/adapters';
import { CONCRETE_CLASS_TYPE_OPTIONS, CONCRETE_CLASS_SUBTYPE_OPTIONS } from '../concrete-class-form-view/form-config';
import ConcreteClassFormView from '../concrete-class-form-view';

const EditConcreteClassDialog = ({ onClose, concreteClassData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useUpdateConcreteClass } = useConcreteClasses();
  const { mutateAsync: updateConcreteClass } = useUpdateConcreteClass();
  const { List: useListUom } = useUom();
  const { data: uomOptions, isLoading: isLoadingUom } = useListUom({ adapter: parseUomOptions });
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const {
      concreteClassName,
      concreteClassStatus,
      concreteClassType,
      concreteClassSubtype,
      concreteClassesDefaultSettings,
      concreteClassNominalValue,
      concreteClassMinimumValue,
      concreteClassMaximumValue,
      concreteClassUOM,
    } = values;

    formikBag.setSubmitting(true);

    try {
      const concreteClass = {
        name: concreteClassName,
        status: concreteClassStatus.value,
        classType: concreteClassType.value,
        isDefault: concreteClassesDefaultSettings,
        classSubType: '',
        nominalValue: '',
        minimumValue: '',
        maximumValue: '',
      };

      switch (concreteClassType.value) {
        case 'air':
          concreteClass.nominalValue = concreteClassNominalValue || '';
          concreteClass.minimumValue = concreteClassMinimumValue || '';
          concreteClass.maximumValue = concreteClassMaximumValue || '';
          break;
        case 'consistence':
          concreteClass.classSubType = concreteClassSubtype?.value;
          concreteClass.uomCode = concreteClassUOM?.value;
          concreteClass.nominalValue = concreteClassNominalValue || '';
          concreteClass.minimumValue = concreteClassMinimumValue || '';
          concreteClass.maximumValue = concreteClassMaximumValue || '';
          break;
        case 'slumpLossRate':
          concreteClass.uomCode = concreteClassUOM?.value;
          concreteClass.nominalValue = concreteClassNominalValue || '';
          break;
        case 'temperature':
          concreteClass.uomCode = concreteClassUOM?.value;
          concreteClass.minimumValue = concreteClassMinimumValue || '';
          concreteClass.maximumValue = concreteClassMaximumValue || '';
          break;
        default:
          break;
      }

      await updateConcreteClass({ data: concreteClass, concreteClassRef: concreteClassData?.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!concreteClassData) return null;

    const { id, name, classType, classSubType, isDefault, nominalValue, minimumValue, maximumValue, uomCode } =
      concreteClassData;

    const editValues = {
      concreteClassId: id,
      concreteClassName: name,
      concreteClassStatus: STATUS_OPTIONS.find(status => status.value === concreteClassData.status),
      concreteClassType: CONCRETE_CLASS_TYPE_OPTIONS.find(option => option.value === classType) || '',
      concreteClassSubtype: CONCRETE_CLASS_SUBTYPE_OPTIONS.find(option => option.value === classSubType) || '',
      concreteClassesDefaultSettings: isDefault,
      concreteClassNominalValue: nominalValue || '',
      concreteClassMinimumValue: minimumValue || '',
      concreteClassMaximumValue: maximumValue || '',
      concreteClassUOM: uomOptions.find(uomItem => uomItem.value === uomCode) || '',
    };

    return editValues;
  }, [concreteClassData, uomOptions]);

  return (
    <ConcreteClassFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      concreteClassesStatusOptions={STATUS_OPTIONS}
      uomOptions={uomOptions}
      isLoadingUom={isLoadingUom}
      concreteClassesTypeOptions={CONCRETE_CLASS_TYPE_OPTIONS}
      concreteClassesSubtypeOptions={CONCRETE_CLASS_SUBTYPE_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

EditConcreteClassDialog.propTypes = {
  concreteClassData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
};

EditConcreteClassDialog.defaultProps = {
  onClose: () => {},
};

export default EditConcreteClassDialog;
