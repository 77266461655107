import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import { useVehicles, useVehicleTypes, useLocations, useCarriers } from '../../../../api/hooks';
import { parseVehicleTypesOptions, parseLocations, parseCarriersOptions } from '../../../../api/adapters';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { initialValues } from '../vehicle-form-view/form-config';
import VehicleFormView from '../vehicle-form-view';

const AddVehicleDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { Create: useCreateVehicle } = useVehicles();
  const { mutateAsync: createVehicle } = useCreateVehicle();
  const { List: useListVehicleTypes } = useVehicleTypes();
  const { data: vehicleTypesOptions, isLoading: isLoadingVehicleTypesOptions } = useListVehicleTypes({
    adapter: parseVehicleTypesOptions,
  });
  const { List: useListLocations } = useLocations();
  const { data: locationsOptions, isLoading: isLoadingLocationsOptions } = useListLocations({
    adapter: parseLocations,
  });
  const { List: useListCarriers } = useCarriers();
  const { data: carriersOptions, isLoading: isLoadingCarriersOptions } = useListCarriers({
    adapter: parseCarriersOptions,
  });

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { vehicleId, vehicleName, vehicleStatus, carrier, vehicleType, vehicleHomeLocation } = values;

    formikBag.setSubmitting(true);

    try {
      const vehicle = {
        id: vehicleId,
        name: vehicleName,
        status: vehicleStatus.value,
        homeLocation: { locationRef: vehicleHomeLocation.value },
        carrierParty: { carrierRef: carrier.value },
        vehicleType: { vehicleTypeRef: vehicleType.value },
      };

      await createVehicle({ vehicle });
      notification.success(translateMessage('vehicles_notification_vehicleAdded'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <VehicleFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      vehicleTypesOptions={vehicleTypesOptions}
      isLoadingVehicleTypesOptions={isLoadingVehicleTypesOptions}
      locationsOptions={locationsOptions}
      isLoadingLocationsOptions={isLoadingLocationsOptions}
      carriersOptions={carriersOptions}
      isLoadingCarriersOptions={isLoadingCarriersOptions}
    />
  );
};

AddVehicleDialog.propTypes = {
  onClose: func.isRequired,
};

AddVehicleDialog.defaultProps = {
  onClose: () => {},
};

export default AddVehicleDialog;
