import React, { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';

import CustomerFormView from '../customer-form-view';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { initialValues } from '../customer-form-view/form-config';
import { useCustomers } from '../../../../api/hooks';

const AddCustomerDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { Create: useCreateCustomers } = useCustomers();
  const { mutateAsync: createCustomer } = useCreateCustomers();

  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { id, name, status, streetAddress, complement, city, state, postalCode } = values;

    formikBag.setSubmitting(true);

    try {
      const data = {
        id,
        name,
        status: status.value,
        address: {
          address1: streetAddress,
          address2: complement,
          city,
          countrySubDivision: state,
          postalCode,
        },
      };

      await createCustomer({ data });
      notification.success(translateMessage('customers_notification_customerAdded'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CustomerFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      customerStatusOptions={STATUS_OPTIONS}
    />
  );
};

export default AddCustomerDialog;
