import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, object } from 'prop-types';

import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { useTrailers } from '../../../../api/hooks';
import TrailerFormView from '../trailer-form-view';

const EditTrailerDialog = ({ onClose, trailerData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Update: useUpdateTrailer } = useTrailers();
  const { mutateAsync: updateTrailer } = useUpdateTrailer();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);

    const {
      name,
      maximumGrossVehicleWeight,
      maximumNetVehicleWeight,
      targetGrossVehicleWeight,
      targetNetVehicleWeight,
      status,
    } = values;

    const trailer = {
      name,
      maximumGrossVehicleWeight,
      maximumNetVehicleWeight,
      targetGrossVehicleWeight,
      targetNetVehicleWeight,
      status: status.value,
    };

    try {
      await updateTrailer({ trailer, trailerRef: trailerData?.crn });
      notification.success(translateMessage('notification_changesSaved'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!trailerData) return null;
    const {
      id,
      name,
      maximumGrossVehicleWeight,
      maximumNetVehicleWeight,
      targetGrossVehicleWeight,
      targetNetVehicleWeight,
    } = trailerData;

    const editValues = {
      id,
      name,
      maximumGrossVehicleWeight: maximumGrossVehicleWeight || '',
      maximumNetVehicleWeight: maximumNetVehicleWeight || '',
      targetGrossVehicleWeight: targetGrossVehicleWeight || '',
      targetNetVehicleWeight: targetNetVehicleWeight || '',
      status: STATUS_OPTIONS.find(status => status.value === trailerData.status),
    };

    return editValues;
  }, [trailerData]);

  return (
    <TrailerFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      statusOptions={STATUS_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

EditTrailerDialog.propTypes = {
  trailerData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
};

EditTrailerDialog.defaultProps = {
  onClose: () => {},
};

export default EditTrailerDialog;
