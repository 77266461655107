import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { users } from '../queries';

export const useUsers = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['users', entityRef];

  const List = ({ queryParams = {}, adapter = null } = {}) => {
    const queryResult = useQuery({
      queryKey: ['users', entityRef, queryString.stringify(queryParams)],
      queryFn: () => users.listUsers({ entityRef, queryParams }),
      staleTime: Infinity,
    });

    if (!adapter) {
      return queryResult;
    }

    const { data } = queryResult;
    return { ...queryResult, data: adapter(data) };
  };

  const Update = () =>
    useMutation({
      mutationFn: profile => users.updateUser({ entityRef, profile }),
      onMutate: async newItem => {
        await queryClient.cancelQueries(queryKey);
        const previousItem = queryClient.getQueryData(queryKey);
        const newData = previousItem?.map?.(item => (item.connexionRef === newItem.connexionRef ? newItem : item));
        queryClient.setQueryData(queryKey, newData);
        return { previousItem, newItem };
      },
      onError: (err, newItem, context) => {
        queryClient.setQueryData(queryKey, context.previousItem);
      },
      onSettled: data => {
        queryClient.invalidateQueries({ queryKey });
        queryClient.invalidateQueries({ queryKey: ['profile', data.profileRef] });
      },
    });

  const Invite = () =>
    useMutation({
      mutationFn: user =>
        users.inviteUser({
          entityRef,
          user,
        }),
      onMutate: async newItem => {
        await queryClient.cancelQueries(queryKey);
        const previousItem = queryClient.getQueryData(queryKey);
        return { previousItem, newItem };
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    });

  return { List, Update, Invite };
};
