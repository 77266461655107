import { Formik, Form, Field } from 'formik';
import { Localization, useNotification } from 'connex-cds';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { useUsers, useEntitySetups } from '../../../../api/hooks';
import { parseUsersFilteredByAdmin } from '../../../../api/adapters';
import { TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import { maskPhoneNumber, sanitizePhoneNumber } from '../../../../util/forms';
import useCompanyForm from './useCompanyForm';
import { validationSchema } from './form-config';
import { ContainerStyled } from './styles';

export const CompanyForm = ({ entitySetupData, isLoadingEntitySetup }) => {
  const { List: useUsersList } = useUsers();
  const { data: users, isLoading: isLoadingUsers } = useUsersList({ adapter: parseUsersFilteredByAdmin });
  const { Update: useUpdateEntitySetup } = useEntitySetups();
  const { mutateAsync: updateEntitySetup } = useUpdateEntitySetup();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { initialValues, handleOnSubmit } = useCompanyForm(
    entitySetupData,
    users,
    updateEntitySetup,
    notification,
    translateMessage
  );

  return (
    <ContainerStyled>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({ isSubmitting, dirty, isValid, handleSubmit, setFieldValue }) => {
          const handlePhoneChange = setFieldValue => (name, value) => {
            setFieldValue(name, maskPhoneNumber(sanitizePhoneNumber(value)));
          };

          return (
            <>
              <Form>
                <Grid container spacing={2.5}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      labelStringId="company_form_companyName"
                      component={TextInputWithFormikField}
                      variant="outlined"
                      name="companyName"
                      required
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      labelStringId="company_form_displayName"
                      component={TextInputWithFormikField}
                      variant="outlined"
                      name="companyDisplayName"
                      disabled={isLoadingEntitySetup}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      labelStringId="general_formLabel_address"
                      component={TextInputWithFormikField}
                      variant="outlined"
                      name="companyAddress"
                      disabled={isLoadingEntitySetup}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      labelStringId="company_form_primaryContact"
                      component={SelectWithFormikField}
                      name="companyPrimaryContact"
                      options={users}
                      disabled={isLoadingUsers}
                      testId="select-primary-contact"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      labelStringId="company_form_addressOptional"
                      component={TextInputWithFormikField}
                      variant="outlined"
                      name="companyAddress2"
                      disabled={isLoadingEntitySetup}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      labelStringId="company_form_phoneNumber"
                      component={TextInputWithFormikField}
                      variant="outlined"
                      name="companyPhone"
                      customOnChange={handlePhoneChange(setFieldValue)}
                      disabled={isLoadingEntitySetup}
                    />
                  </Grid>
                  <Grid item container xs={6} spacing={2.5}>
                    <Grid item xs={4}>
                      <Field
                        fullWidth
                        labelStringId="general_formLabel_city"
                        component={TextInputWithFormikField}
                        variant="outlined"
                        name="companyCity"
                        disabled={isLoadingEntitySetup}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        fullWidth
                        labelStringId="general_formLabel_state"
                        component={TextInputWithFormikField}
                        variant="outlined"
                        name="companyState"
                        disabled={isLoadingEntitySetup}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        fullWidth
                        labelStringId="company_form_postalCode"
                        component={TextInputWithFormikField}
                        variant="outlined"
                        name="companyPostalCode"
                        disabled={isLoadingEntitySetup}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} justifyContent={'flex-end'}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting || !dirty || !isValid}
                      onClick={handleSubmit}
                    >
                      <Localization.Translate stringId="general_dialogAction_save" />
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          );
        }}
      </Formik>
    </ContainerStyled>
  );
};

CompanyForm.defaultProps = {
  entitySetupData: {},
  isLoadingEntitySetup: true,
};

export default CompanyForm;
