import React from 'react';
import { Field, FieldArray, Form } from 'formik';
import { Localization } from 'connex-cds';
import { InputAdornment } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { array, bool, func } from 'prop-types';

import { CheckboxWithFormikField, SelectWithFormikField } from '../../../../commons';
import FormTooltip from '../../../../commons/form-tooltip';
import TranslatedCell from '../../../../commons/translated-cell/TranslatedCell';
import { isDevEnvironment, isLocalEnvironment } from '../../../../util/environment';
import { ReactComponent as NoDataImage } from '../../../../assets/no-data-image.svg';
import ProductLocationItem from './ProductLocationItem';
import {
  ProductInventoryContainer,
  HeaderRow,
  HeaderCell,
  LocationsContainer,
  NoDataContainer,
  NoDataHeader,
  NoDataText,
} from './styles';

const ProductInventoryTab = ({
  inventorySourceOptions,
  keepInInventory,
  inventoryLocations,
  isProductLocationsDataLoading,
  partnerOptions,
  isLoadingPartners,
  setFieldValue,
}) => {
  const translateMessage = Localization.useTranslateMessage();

  const renderColumnHeader = (labelStringId, descriptionStringId) => (
    <>
      <TranslatedCell stringId={labelStringId} />
      <FormTooltip arrow title={translateMessage(descriptionStringId)}>
        <InputAdornment position="end">
          <InfoOutlined />
        </InputAdornment>
      </FormTooltip>
    </>
  );

  return (
    <ProductInventoryContainer>
      <h3 className="product-inventory-title">
        <Localization.Translate stringId="products_addProduct_inventory" />
      </h3>
      <Form className="product-inventory-form">
        <div className="product-inventory-form-row">
          <Field
            fullWidth
            labelStringId="products_addProduct_keepInInventory"
            component={CheckboxWithFormikField}
            name="keepInInventory"
          />
          {keepInInventory && (
            <Field
              testId="select-inventory-source"
              labelStringId="products_addProduct_inventorySource"
              component={SelectWithFormikField}
              name="inventorySource"
              options={inventorySourceOptions}
              required
            />
          )}
        </div>

        {keepInInventory && (isDevEnvironment || isLocalEnvironment) && (
          <FieldArray
            name="inventoryLocations"
            render={() => (
              <>
                <HeaderRow>
                  <HeaderCell>
                    <TranslatedCell stringId={'products_addProduct_location'} />
                  </HeaderCell>
                  <HeaderCell>
                    {renderColumnHeader(
                      'products_addProduct_safetyLevel',
                      'products_addProduct_safetyLevelDescription'
                    )}
                  </HeaderCell>
                  <HeaderCell>
                    {renderColumnHeader(
                      'products_addProduct_reorderLevel',
                      'products_addProduct_reorderLevelDescription'
                    )}
                  </HeaderCell>
                  <HeaderCell>
                    {renderColumnHeader('products_addProduct_maxLevel', 'products_addProduct_maxLevelDescription')}
                  </HeaderCell>
                </HeaderRow>
                <LocationsContainer>
                  {inventoryLocations && inventoryLocations.length > 0 ? (
                    inventoryLocations.map((inventoryLocation, index) => (
                      <ProductLocationItem
                        inventoryLocation={inventoryLocation}
                        inventoryLocationIndex={index}
                        isProductLocationsDataLoading={isProductLocationsDataLoading}
                        isLoadingPartners={isLoadingPartners}
                        partnerOptions={partnerOptions}
                        key={index}
                        setFieldValue={setFieldValue}
                      />
                    ))
                  ) : (
                    <NoDataContainer>
                      <NoDataImage />
                      <NoDataHeader>
                        <Localization.Translate stringId="products_addProduct_noData" />
                      </NoDataHeader>
                      <NoDataText>
                        <Localization.Translate stringId="products_addProduct_noDataText" />
                      </NoDataText>
                    </NoDataContainer>
                  )}
                </LocationsContainer>
              </>
            )}
          />
        )}
      </Form>
    </ProductInventoryContainer>
  );
};

ProductInventoryTab.propTypes = {
  inventorySourceOptions: array.isRequired,
  keepInInventory: bool,
  inventoryLocations: array,
  isProductLocationsDataLoading: bool,
  partnerOptions: array.isRequired,
  isLoadingPartners: bool,
  setFieldValue: func.isRequired,
};

export default ProductInventoryTab;
