import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, object } from 'prop-types';

import { usePriceBooks } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import PriceBookFormView from '../price-book-form-view';

const EditPriceBookDialog = ({ onClose, priceBookData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { Update: useUpdatePriceBook } = usePriceBooks();
  const { mutateAsync: updatePriceBook } = useUpdatePriceBook();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { priceBookName, priceBookStatus } = values;

    formikBag.setSubmitting(true);

    try {
      const priceBook = {
        name: priceBookName,
        status: priceBookStatus.value,
      };

      await updatePriceBook({ priceBook, priceBookRef: priceBookData?.crn });
      notification.success(translateMessage('notification_changesSaved'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!priceBookData) return null;
    const { id, name } = priceBookData;

    const editValues = {
      priceBookId: id,
      priceBookName: name,
      priceBookStatus: STATUS_OPTIONS.find(status => status.value === priceBookData.status),
    };

    return editValues;
  }, [priceBookData]);

  return (
    <PriceBookFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      priceBookStatusOptions={STATUS_OPTIONS}
      handleOnDelete={handleOnDelete}
    />
  );
};

EditPriceBookDialog.propTypes = {
  priceBookData: object,
  onClose: func.isRequired,
  handleOnDelete: func,
};

EditPriceBookDialog.defaultProps = {
  onClose: () => {},
};

export default EditPriceBookDialog;
