import { http } from 'connex-cds';

export default {
  listCustomers: ({ entityRef, queryParams }) =>
    http.get({ apiName: 'company-admin', path: `/${entityRef}/customers`, queryParams }),
  createCustomer: ({ entityRef, data }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/customers`,
      data,
    }),
  updateCustomer: ({ entityRef, customerRef, data }) =>
    http.patch({
      apiName: 'company-admin',
      path: `/${entityRef}/customers/${customerRef}`,
      data,
    }),
};
