import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ContainerStyled = styled('div')`
  ${({ theme }) => css`
    .role-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 25px;
    }

    .role-description {
      line-height: 24px;
      font-size: 16px;
      margin-bottom: 20px;
    }
  `}
`;
