import { useState, useMemo } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func, shape, string, bool } from 'prop-types';

import CarrierFormView from '../carrier-form-view';
import { useCarriers } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';

const EditCarrierDialog = ({ onClose, carrierData, handleOnDelete }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Update: useUpdateCarrier } = useCarriers();
  const { mutateAsync: updateCarrier } = useUpdateCarrier();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { carrierName, carrierStatus, carrierCompanyFleet } = values;
    formikBag.setSubmitting(true);

    try {
      const carrier = {
        name: carrierName,
        status: carrierStatus.value,
        companyFleet: carrierCompanyFleet,
      };
      await updateCarrier({ data: carrier, carrierRef: carrierData.crn });
      notification.success(translateMessage('notification_changesSaved'));
      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const getInitialValues = useMemo(() => {
    if (!carrierData) return null;

    const { id, name, companyFleet } = carrierData;

    const values = {
      carrierId: id,
      carrierName: name,
      carrierStatus: STATUS_OPTIONS.find(status => status.value === carrierData.status),
      carrierCompanyFleet: companyFleet,
    };

    return values;
  }, [carrierData]);

  return (
    <CarrierFormView
      handleOnDelete={handleOnDelete}
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.EDIT}
      initialValues={getInitialValues}
      onSubmit={handleOnSubmit}
      carrierStatusOptions={STATUS_OPTIONS}
    />
  );
};

EditCarrierDialog.propTypes = {
  handleOnDelete: func,
  onClose: func.isRequired,
  carrierData: shape({
    crn: string.isRequired,
    id: string.isRequired,
    name: string.isRequired,
    status: string.isRequired,
    companyFleet: bool,
  }),
};

EditCarrierDialog.defaultProps = {
  onClose: () => {},
  carrierData: {},
};

export default EditCarrierDialog;
