import * as yup from 'yup';
import { STATUS_OPTIONS } from '../../../../constants';

export const TAB_IDS = {
  AddCroncreteClassProfile: 0,
};

export const validationSchema = yup.object().shape({
  concreteClassId: yup.string().required('required'),
  concreteClassName: yup.string().required('required'),
  concreteClassStatus: yup.object().required('required'),
  concreteClassType: yup.object().required('required'),
});

export const initialValues = {
  concreteClassId: '',
  concreteClassName: '',
  concreteClassStatus: STATUS_OPTIONS[0],
  concreteClassUOM: '',
  concreteClassType: {},
  concreteClassSubtype: '',
  concreteClassesDefaultSettings: false,
  concreteClassNominalValue: '',
  concreteClassMinimumValue: '',
  concreteClassMaximumValue: '',
};

export const CONCRETE_CLASS_TYPE_OPTIONS = [
  { value: 'air', label: 'Air' },
  { value: 'consistence', label: 'Consistence' },
  { value: 'slumpLossRate', label: 'Slump Loss Rate' },
  { value: 'temperature', label: 'Temperature' },
];

export const CONCRETE_CLASS_SUBTYPE_OPTIONS = [
  { value: 'flow', label: 'Flow' },
  { value: 'slump', label: 'Slump' },
  { value: 'spread', label: 'Spread' },
];
