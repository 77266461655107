import React from 'react';
import { useParams } from 'react-router-dom';
import { users } from '../../api/queries';

export const useRegister = () => {
  const [busy, setBusy] = React.useState(false);
  const { entityRef } = useParams();

  const register = React.useCallback(
    async payload => {
      setBusy(true);
      const response = await users.register({ entityRef, payload });
      setBusy(false);
      return response;
    },
    [entityRef]
  );

  return { busy, register };
};
