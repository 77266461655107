import { Localization } from 'connex-cds';
import { Field, Form } from 'formik';

import { TextInputWithFormikField } from '../../../../commons';
import { maskPhoneNumber, sanitizePhoneNumber } from '../../../../util/forms';

import { ContainerStyled } from './styles';

const EditContactInfoTab = ({ setFieldValue }) => {
  const handlePhoneChange = setFieldValue => (name, value) => {
    setFieldValue(name, maskPhoneNumber(sanitizePhoneNumber(value)));
  };

  return (
    <ContainerStyled className="tab-content">
      <h3 className="contact-info-title">
        <Localization.Translate stringId="editUserDialog_tabs_contactInfo_title" />
      </h3>
      <Form className="contact-info-form">
        <Field
          testId="editUser-firstName"
          labelStringId="editUserDialog_tabs_contactInfo_firstName"
          component={TextInputWithFormikField}
          type="text"
          variant="outlined"
          name="firstName"
          required
        />
        <Field
          testId="editUser-lastName"
          labelStringId="editUserDialog_tabs_contactInfo_lastName"
          component={TextInputWithFormikField}
          type="text"
          variant="outlined"
          name="lastName"
          required
        />
        <Field
          testId="editUser-email"
          labelStringId="editUserDialog_tabs_contactInfo_email"
          component={TextInputWithFormikField}
          type="email"
          variant="outlined"
          name="email"
          required
        />
        <Field
          testId="editUser-phone"
          labelStringId="editUserDialog_tabs_contactInfo_phone"
          component={TextInputWithFormikField}
          type="text"
          variant="outlined"
          name="phone"
          customOnChange={handlePhoneChange(setFieldValue)}
        />
      </Form>
    </ContainerStyled>
  );
};

export default EditContactInfoTab;
