import * as yup from 'yup';

import { isDevEnvironment, isLocalEnvironment } from '../../../../util/environment';

export const TAB_IDS = {
  AddProductProfile: 0,
  AddProductInventory: 1,
};

export const INVENTORY_SOURCE_OPTIONS = [
  { value: 'CLOUD', label: 'Command Alkon Cloud' },
  { value: 'CB', label: 'Command Batch' },
];

export const PRODUCT_LOCATION_STATUSES = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  Deleted: 'DELETED',
};

export const validationSchema = yup.object().shape({
  productId: yup.string().required('required'),
  productName: yup.string().required('required'),
  productType: yup.object().required('required'),
  uom: yup.object().required('required'),
  locations: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .min(1, 'required'),
  productStatus: yup.object().required('required'),
  keepInInventory: yup.bool(),
  inventorySource: yup.object().when('keepInInventory', {
    is: true,
    then: yup.object().required('required'),
    otherwise: yup.object(),
  }),
  inventoryLocations:
    isDevEnvironment || isLocalEnvironment
      ? yup.array().when('keepInInventory', {
          is: true,
          then: yup.array().of(
            yup.object().shape({
              safetyStock: yup.number().min(0, 'general_minimumZero').required('required'),
              reorderStock: yup
                .number()
                .min(0, 'general_minimumZero')
                .required('required')
                .when('safetyStock', (stock, schema) => {
                  return stock >= 0 ? schema.moreThan(yup.ref('safetyStock'), 'general_tooSmallValue') : schema;
                }),
              maxStock: yup
                .number()
                .min(0)
                .when('reorderStock', (stock, schema) => {
                  return stock >= 0 ? schema.moreThan(yup.ref('reorderStock'), 'general_tooSmallValue') : schema;
                }),
              productMappingList: yup.array().of(
                yup.object().shape(
                  {
                    partner: yup.object().required('required'),
                    projects: yup
                      .array()
                      .of(
                        yup.object().shape({
                          value: yup.string(),
                          label: yup.string(),
                          id: yup.string(),
                        })
                      )
                      .min(1, 'required'),
                    products: yup
                      .array()
                      .of(
                        yup.object().shape({
                          value: yup.string(),
                          label: yup.string(),
                          id: yup.string(),
                        })
                      )
                      .when('purchaseOrders', (purchaseOrders, schema) => {
                        return purchaseOrders?.length > 0 ? schema : schema.min(1, 'required');
                      }),
                    purchaseOrders: yup
                      .array()
                      .of(
                        yup.object().shape({
                          value: yup.string(),
                          label: yup.string(),
                        })
                      )
                      .when('products', (products, schema) => {
                        return products?.length > 0 ? schema : schema.min(1, 'required');
                      }),
                  },
                  [['products', 'purchaseOrders']]
                )
              ),
            })
          ),
          otherwise: yup.array(),
        })
      : yup.array(),
});
