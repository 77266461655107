import { Localization } from 'connex-cds';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import { CustomIcon } from '../../../../commons';
import CompanyProfile from '../../../../assets/company-profile.svg';

import { GridContainerStyled, Header3Styled, SpanStyled, ParagraphStyled, ContainerStyled } from './styles';

const CompanySummary = ({ companyName, companyDescription, isLoadingEntitySetup }) => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <ContainerStyled>
      <GridContainerStyled container spacing={5}>
        <Grid item>
          <img src={CompanyProfile} alt="Company Profile" />
        </Grid>
        <Grid item container direction={'column'}>
          <Grid item container spacing={1}>
            <Grid item>
              <CustomIcon iconName={'businessOutlinedIcon'} />
            </Grid>
            <Grid item>
              <Header3Styled>{translateMessage('company_summary_title')}</Header3Styled>
            </Grid>
          </Grid>
          <Grid item>
            {isLoadingEntitySetup ? <Skeleton variant="text" width={200} /> : <SpanStyled>{companyName}</SpanStyled>}
          </Grid>
          <Grid item>
            {isLoadingEntitySetup ? (
              <Skeleton variant="text" width={300} />
            ) : (
              <ParagraphStyled>{companyDescription}</ParagraphStyled>
            )}
          </Grid>
        </Grid>
      </GridContainerStyled>
    </ContainerStyled>
  );
};

export default CompanySummary;
