import { useState } from 'react';
import { Localization, useNotification } from 'connex-cds';
import { func } from 'prop-types';

import PriceBookFormView from '../price-book-form-view';
import { usePriceBooks } from '../../../../api/hooks';
import { DIALOG_TYPES, STATUS_OPTIONS } from '../../../../constants';
import { initialValues } from '../price-book-form-view/form-config';

const AddPriceBookDialog = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(true);
  const notification = useNotification();
  const { Create: useCreatePriceBook } = usePriceBooks();
  const { mutateAsync: createPriceBook } = useCreatePriceBook();
  const translateMessage = Localization.useTranslateMessage();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOnSubmit = async (values, formikBag) => {
    const { priceBookId, priceBookName, priceBookStatus } = values;

    formikBag.setSubmitting(true);

    try {
      const priceBook = {
        id: priceBookId,
        name: priceBookName,
        status: priceBookStatus.value,
      };

      await createPriceBook({ priceBook });
      notification.success(translateMessage('priceBooks_notification_priceBookAdded'));

      formikBag.setSubmitting(false);
      formikBag.resetForm();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PriceBookFormView
      open={isOpen}
      onClose={handleClose}
      mode={DIALOG_TYPES.ADD}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      priceBookStatusOptions={STATUS_OPTIONS}
    />
  );
};

AddPriceBookDialog.propTypes = {
  onClose: func.isRequired,
};

AddPriceBookDialog.defaultProps = {
  onClose: () => {},
};

export default AddPriceBookDialog;
