import { css } from '@emotion/react';

export default ({ theme }) => css`
  .delete-user-title {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .delete-user-description {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    max-width: 42ch;
    text-align: center;
    margin-bottom: 0;

    strong {
      word-break: break-word;
    }
  }
`;
