import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tabs } from '@mui/material';

export const TabsControlStyled = styled(Tabs)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.12)', dark: '#938F99' })};
    flex-shrink: 0;

    .MuiTab-root.MuiTab-labelIcon {
      font-size: 14px;

      .MuiTab-iconWrapper {
        font-size: 24px;
      }
    }
  `};
`;
