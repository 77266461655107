import mixin from '../mixins';
import colors from '../colors';
import { shadow, background, elevation } from '../elevation';
import darkOverrides from './darkOverrides';
import lightOverrides from './lightOverrides';

// TODO: check theme extension possibility to avoid repetition for components overrides (like spacing, effects)
const getDesignConfig = mode => {
  const shadowCA = mixin.shadowTheme(mode);
  const backgroundCA = mixin.backgroundTheme(mode);
  const filterCA = mixin.filterTheme(mode);
  const surfaceCA = mixin.surfaceTheme(mode);
  const validateMode = mixin.validateMode(mode);
  const elevationCA = elevation(mode);
  const backgroundImageMode = mixin.backgroundImageTheme(mode);
  const customOverrides = mode => (mode === 'light' ? lightOverrides : darkOverrides);

  return {
    // Colors to use in component styles, values might change between theme mode (dark, light)
    colorsCA: colors,
    shadowCA,
    backgroundCA,
    filterCA,
    surfaceCA,
    validateMode,
    elevationCA,
    backgroundImageMode,

    palette: {
      mode: mode,
      text: {
        primary: validateMode({ light: colors.neutral[40], dark: colors.neutral[95] }),
      },
      primary: {
        main: validateMode({ light: colors.darkBlue[10], dark: colors.blue[90] }),
        contrastText: validateMode({ light: colors.neutral[100], dark: colors.blue[10] }),
      },
      secondary: {
        main: validateMode({ light: colors.blue[10], dark: colors.blue[95] }),
        contrastText: validateMode({ light: colors.neutral[100], dark: colors.blue[10] }),
      },
      error: {
        main: validateMode({ light: colors.red[80], dark: colors.red[80] }),
        contrastText: validateMode({ light: colors.neutral[20], dark: colors.neutral[20] }),
      },
    },
    typography: {
      fontFamily: 'Work Sans',
      fontSize: 16,
      pxToRemMixin: mixin.pxToRem,
    },
    components: {
      MuiIconButton: {
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              border: `1px solid ${validateMode({ light: colors.neutral[40], dark: colors.blue[95] })}`,
            },
          },
        ],
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: validateMode({ light: colors.neutral[10], dark: colors.darkBlue[90] }),
            '&.Mui-checked': {
              color: validateMode({ light: colors.darkBlue[50], dark: colors.darkBlue[90] }),
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            zIndex: 0,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            background: validateMode({ light: colors.neutral[100], dark: background.dark[5] }),
            boxShadow: shadow[mode][5],
            borderRadius: '20px',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: validateMode({ light: 'rgba(120, 117, 121, 0.5)', dark: 'rgba(0, 0, 0, 0.5)' }),
          },
          invisible: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          defaultProps: {
            sx: {
              fontSize: 16,
            },
          },
          select: {
            display: 'flex',
            alignItems: 'center',
            height: 40,
          },
        },
      },
      ...customOverrides(mode),
    },
  };
};

export default getDesignConfig;
