import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';

export const DialogStyled = styled(Dialog)`
  ${({ theme }) => css`
    .MuiDialog-paper {
      max-height: 817px;
      height: 80%;
    }
  `}
`;
