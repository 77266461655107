import { Form } from 'formik';
import { Localization } from 'connex-cds';

import { useRoles } from '../../../../api/hooks';
import { RoleRadioGroup } from '../../../../commons';

import { ContainerStyled } from './styles';

const EditRoleTab = () => {
  const { List: useRolesList } = useRoles();
  const { data: roleList } = useRolesList();

  return (
    <ContainerStyled className="role">
      <h3 className="role-title">
        <Localization.Translate stringId="general_roles" />
      </h3>
      <Form className="role-form">
        <RoleRadioGroup name="role.roleRef" roleList={roleList} />
      </Form>
    </ContainerStyled>
  );
};

export default EditRoleTab;
