import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Stack from '@mui/material/Stack';

export const StackStyled = styled(Stack)`
  ${css`
    row-gap: 45px;
    margin: auto;
    padding: 16px;
    max-width: 912px;
  `}
`;
