import { useEffect, useState } from 'react';
import Stepper from '../stepper/Stepper';
import { stepValidationSchemas } from './form-config';

const AddUserStepper = ({ formValues, activeStep = 0, onStepClick }) => {
  const [steps, setSteps] = useState([
    { stringId: 'addUserStepper_contactInfo', stepProps: {}, labelProps: {} },
    { stringId: 'addUserStepper_role', stepProps: {}, labelProps: {} },
  ]);

  const handleStepClick = index => {
    onStepClick(null, index);
  };

  useEffect(() => {
    const ref = setTimeout(() => {
      setSteps(prevSteps => {
        return prevSteps.map((step, index) => {
          const isValid = stepValidationSchemas[index]?.isValidSync(formValues);
          // Validate previous steps to check skipped steps
          if (index < activeStep) {
            step.labelProps = { error: !isValid };
            step.stepProps = { completed: isValid };
          }
          if (index === activeStep) {
            // complete active step when it is valid
            step.labelProps = {};
            step.stepProps = { completed: isValid };
          }
          return step;
        });
      });
    }, 200);

    return () => {
      clearTimeout(ref);
    };
  }, [formValues, activeStep]);
  return <Stepper steps={steps} activeStep={activeStep} onStepClick={handleStepClick} />;
};

export default AddUserStepper;
