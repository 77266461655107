import React from 'react';

import { DataGridStyled } from './styles';
import { EntityTableSkeleton } from './EntityTableSkeleton';

const getRowSpacing = params => ({
  bottom: params.isLastVisible ? 2 : 20,
});

const EntityTable = ({ isLoading = true, skeletonNumRows = 7, rows = [], columns = [], ...dataGridProps }) => {
  if (isLoading) {
    return <EntityTableSkeleton numRows={skeletonNumRows} />;
  }

  if (!rows || !rows.length) {
    return null;
  }

  return (
    <DataGridStyled
      className="entity-table"
      checkboxSelection
      disableRowSelectionOnClick
      disableColumnMenu
      rowHeight={80}
      getRowSpacing={getRowSpacing}
      rows={rows}
      columnBuffer={8}
      columns={columns}
      hideFooter
      {...dataGridProps}
    />
  );
};

export default EntityTable;
