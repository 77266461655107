import { uniqueId } from 'lodash';

export const CARD_OPTIONS = [
  {
    id: uniqueId('billing_'),
    title: 'billing_card_taxation_title',
    description: 'billing_card_taxation_description',
    icon: 'taxation',
    state: 'notStarted',
  },
];
