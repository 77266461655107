import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ContainerStyled = styled.div`
  ${({ theme }) => css`
    padding: 20px;
    border-radius: 20px;
    border: 1px solid
      ${theme.validateMode({
        light: theme.colorsCA.neutral[100],
        dark: '#938F99',
      })};
    background: ${theme.validateMode({
      light: theme.colorsCA.neutral[100],
      dark: theme.backgroundCA(5),
    })};
    box-shadow: ${theme.shadowCA(6)};
    backdrop-filter: blur(24px);
  `}
`;
