import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ProductProfileContainer = styled.div`
  ${css`
    .product-porfile-title {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-bottom: 25px;
    }

    .product-porfile-description {
      line-height: 24px;
      font-size: 16px;
      margin-bottom: 40px;
    }

    .product-porfile-form {
      display: flex;
      flex-direction: row;

      .MuiTextField-root {
        flex: 1;
        width: 100%;
      }

      .MuiOutlinedInput-root {
        height: 48px;
        width: 100%;
      }
      .MuiAutocomplete-root {
        .MuiOutlinedInput-root {
          min-height: 48px;
          height: auto;
          width: 100%;
          padding: 0 9px;
        }

        .MuiAutocomplete-input {
          padding: 0;
        }
      }

      .MuiFormControl-root {
        margin-bottom: 40px;
      }

      .MuiFormControl-root:last-child {
        margin-bottom: 0;
      }

      .MuiBackdrop-root {
        background-color: transparent !important;
      }

      .MuiModal-backdrop {
        background-color: transparent !important;
      }

      .MuiInputLabel-shrink {
        top: 0;
      }

      .first-column {
        width: 50%;
        padding-right: 20px;
      }

      .second-column {
        width: 50%;
        padding-left: 20px;
      }
    }
  `}
`;
